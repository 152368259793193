/**

*/
import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Typography, Box, Button, IconButton } from "@mui/material";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import PageLayout from "examples/LayoutContainers/PageLayout";
import images from "config/imageConfig";
import { useNavigate } from "react-router-dom";
import LandingDialog from "examples/LandingDialog";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coldarkDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import CopyToClipboard from "react-copy-to-clipboard";

function LandingPage() {
  const navigate = useNavigate();
  const handleTry = () => {
    navigate("/dashboard");
  };

  const [infoDialog, setInfoDialog] = useState(false);
  const handleOpenInfoDialog = () => {
    setInfoDialog(true);
  };
  const handleCloseInfoDialog = () => {
    setInfoDialog(false);
  };

  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedButton, setSelectedButton] = useState(0);

  const tabs = ["python"];

  const logoImages = [images.landing_m3logo1, images.landing_m3logo2, images.landing_m3logo3, images.landing_m3logo4];

  const code = `
from openai import OpenAI
client = OpenAI(base_url="https://api.xmaas.cn/v1", api_key="{YOUR_API_KEY}")

completion = client.chat.completions.create(
    model="{YOUR_MODEL}",
    messages=[
        {"role": "system", "content": "You are a helpful assistant."},
        {
            "role": "user",
            "content": "Write a haiku about recursion in programming."
        }
    ]
)

print(completion.choices[0].message)
  `;

  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          // background: "linear-gradient(to bottom, #EDEAFA, #FFFFFF)",
          background: "#FFFFFF",
        }}
      >
        <Box height="8%" bgcolor="#ffffff" borderBottom={1} borderColor="#C8C1F1">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            height="100%"
            px={3}
          >
            <Box display="flex" alignItems="center">
              <Box component="img" src={images.authpage_logo} alt="l" width="auto" height="70px" />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "auto", pr: 4 }}>
              <Button
                onClick={handleOpenInfoDialog}
                variant="contained"
                sx={{
                  color: "#737373",
                  backgroundColor: "#F5F5F5",
                  "&:hover": { backgroundColor: "#F1EFFB" },
                }}
              >
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Contact Us" : "联系我们"}
              </Button>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="auto"
            width="85%"
            mx="auto"
            flexWrap="wrap"
            mb={5}
          >
            <Box flex="1" textAlign="left">
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "690px",
                  height: "auto",
                }}
              >
                <Typography variant="caption" sx={{ fontSize: 60, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#000000" : "#7F6CE0" }}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Support your AI applications " : "快速、全面"}
                </Typography>
                <Typography variant="caption" component="span" sx={{ fontSize: 60, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#7F6CE0" : "#000000" }}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "quickly, comprehensively, up-to-date." : "、与时俱进地支持您的AI应用"}
                </Typography>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "542px",
                    height: "auto",
                    mt: 5,
                  }}
                >
                  <Typography sx={{ fontSize: 20, fontWeight: 550, color: "#999999" }}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "XMaaS leverages 80+ AI models to provide API and monitoring services for your AI applications, enabling fast implementation and seamless delivery." 
                    : "星觉利用80+种人工智能为您的AI应用提供API和监控服务，帮助您快速实现和交付AI应用"}
                  </Typography>
                </Box>
              </Box>
              <Box
                component="img"
                src={images.landing_trynow}
                alt="Try Now Button"
                sx={{
                  mt: 7,
                  ml: 1,
                  display: "flex",
                  cursor: "pointer",
                  width: "100%",
                  maxWidth: "200px",
                  height: "auto",
                }}
                onClick={handleTry}
              />
            </Box>
            <Box flex="1" textAlign="center">
              <Box
                component="img"
                src={images.landing_top_right}
                alt="Right"
                sx={{
                  width: "100%",
                  maxWidth: "906px",
                  height: "auto",
                }}
              />
            </Box>
          </Box>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height="auto"
              width="85%"
              mx="auto"
              flexWrap="wrap"
            >
              <Box
                display="flex"
                flexDirection="column"
                gap={4}
                flex="1"
                width="100%"
                maxWidth="542px"
                textAlign="left"
              >
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "542px",
                    height: "auto",
                  }}
                >
                  <Typography variant="caption" sx={{ fontSize: 48, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#000000" : "#000000" }}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "An " : ""}
                  </Typography>
                  <Typography variant="caption" component="span" sx={{ fontSize: 48, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#7F6CE0" : "#7F6CE0" }}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "all-inclusive " : "超全"}
                  </Typography>
                  <Typography variant="caption" component="span" sx={{ fontSize: 48, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#000000" : "#000000" }}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "model hub" : "模型广场"}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "542px",
                      height: "auto",
                      mt: 4,
                      mb: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: 20, fontWeight: 550, color: "#999999" }}>
                      {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Covering 80+ models, helping you browse popular options, discover hidden gems, and find the perfect model." 
                      : "涵盖80+种模型，帮您浏览热门模型、探索宝藏之选、找到最合适的模型"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  component="img"
                  src={images.landing_m1_l2}
                  alt="Bottom Left"
                  sx={{
                    width: "100%",
                    maxWidth: "313px",
                    height: "auto",
                  }}
                />
              </Box>
              <Box flex="1" textAlign="right">
                <Box
                  component="img"
                  src={images.landing_m1_r}
                  alt="Right"
                  sx={{
                    width: "100%",
                    maxWidth: "860px",
                    height: "auto",
                  }}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height="auto"
              width="85%"
              mx="auto"
              flexWrap="wrap"
            >
              <Box
                flex="1"
                width="100%"
                maxWidth="860px"
                height="auto"
                textAlign="center"
                position="relative"
                sx={{
                  paddingTop: "50%",
                }}
              >
                <Box
                  component="img"
                  src={images.landing_m2_l1}
                  alt="Stacked Background"
                  sx={{
                    position: "absolute",
                    top: "20%",
                    left: 0,
                    width: "100%",
                    height: "auto",
                    objectFit: "cover",
                    zIndex: 1,
                  }}
                />
                <Box
                  component="img"
                  src={images.landing_m2_l2}
                  alt="Stacked Foreground"
                  sx={{
                    position: "absolute",
                    top: "30%",
                    left: "10%",
                    width: "100%",
                    height: "auto",
                    zIndex: 2,
                  }}
                />
              </Box>
              <Box
                flex="1"
                textAlign="left"
                sx={{
                  zIndex: 3,
                }}
              >
                <Box
                  sx={{
                    ml: 25,
                    width: "100%",
                    maxWidth: "447px",
                    height: "auto",
                  }}
                >
                  <Typography variant="caption" sx={{ fontSize: 48, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#7F6CE0" : "#7F6CE0" }}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Quick " : "快捷"}
                  </Typography>
                  <Typography variant="caption" component="span" sx={{ fontSize: 48, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#000000" : "#000000" }}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Comparison" : "比对效果"}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "447px",
                      height: "auto",
                      mt: 4,
                    }}
                  >
                    <Typography sx={{ fontSize: 20, fontWeight: 550, color: "#999999" }}>
                      {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Directly compare multiple models’ performance in the same conversation to swiftly select the best solution based on effectiveness and cost." 
                      : "直接对比 多个模型在同一对话中的表现，快速选择 效果、成本综合表现最佳方案"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" height="100%" mb={2}>
              <Box display="flex" flexDirection="column" alignItems="center" width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  height="auto"
                  width="85%"
                  mx="auto"
                  flexWrap="wrap"
                  gap={1}
                >
                  <Box flex="1" textAlign="left">
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "704px",
                        height: "auto",
                      }}
                    >
                      <Typography variant="caption" sx={{ fontSize: 48, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#7F6CE0" : "#7F6CE0" }}>
                        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Instant " : "极速"}
                      </Typography>
                      <Typography variant="caption" component="span" sx={{ fontSize: 48, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#000000" : "#000000" }}>
                        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Embedding" : "嵌入应用"}
                      </Typography>
                      <Box
                        sx={{
                          width: "100%",
                          maxWidth: "850px",
                          height: "auto",
                          mt: 4,
                        }}
                      >
                        <Typography sx={{ fontSize: 20, fontWeight: 550, color: "#999999" }}>
                          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Access over 80 models to explore popular choices, find the most suitable model." 
                          : "涵盖80+种模型，帮您浏览热门模型、探索宝藏之选、找到最合适的模型"}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box flex="1" textAlign="right" display="flex" justifyContent="flex-end">
                    <Box display="flex" gap={3} flexWrap="wrap" justifyContent="flex-end">
                      {logoImages.map((img, index) => (
                        <Box
                          key={index}
                          component="img"
                          src={img}
                          alt={`Logo ${index + 1}`}
                          sx={{
                            width: "100%",
                            maxWidth: "100px",
                            height: "auto",
                            borderRadius: "25%",
                            border: selectedButton === index ? "10px solid #DBD6F5" : "none",
                            cursor: "pointer",
                          }}
                          onClick={() => setSelectedButton(index)}
                        />
                      ))}
                    </Box>
                  </Box>
                </Box>
                <Box
                  width="100%"
                  maxWidth="85%"
                  mt={4}
                  bgcolor="#051538"
                  borderRadius={2}
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                  }}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" gap={2}>
                      {["python"].map((item, index) => (
                        <Box
                          key={index}
                          sx={{
                            cursor: "pointer",
                            bgcolor:
                              selectedTab === index ? "rgba(255, 255, 255, 0.3)" : "transparent",
                            color: "#EDEAFA",
                            borderRadius: 2,
                            mx: 2,
                            px: 2,
                            my: 1,
                            py: 1,
                          }}
                          onClick={() => setSelectedTab(index)}
                        >
                          {item}
                        </Box>
                      ))}
                    </Box>
                    <CopyToClipboard text={code}>
                      <IconButton
                        sx={{
                          color: "#ffffff",
                          "&:hover": {
                            color: "#c7c7c7",
                          },
                          mr: 2,
                        }}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </Box>
                  <SyntaxHighlighter
                    language={tabs[selectedTab]}
                    style={coldarkDark}
                    showLineNumbers
                  >
                    {code}
                  </SyntaxHighlighter>
                </Box>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height="auto"
              width="85%"
              mx="auto"
              flexWrap="wrap"
              gap={1}
              mt={20}
            >
              <Box flex="1" textAlign="left">
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "572px",
                    height: "auto",
                  }}
                >
                  <Typography variant="caption" sx={{ fontSize: 48, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#7F6CE0" : "#7F6CE0" }}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Effortless " : "轻松"}
                  </Typography>
                  <Typography variant="caption" component="span" sx={{ fontSize: 48, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#000000" : "#000000" }}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Traffic Monitoring" : "监控流量"}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "644px",
                      height: "auto",
                      mt: 4,
                    }}
                  >
                    <Typography sx={{ fontSize: 20, fontWeight: 550, color: "#999999" }}>
                      {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Conveniently link your application to APIs or API keys for real-time token flow monitoring." 
                      : "便捷地将应用和API / API key绑定，实时监控token流量。"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box flex="1" textAlign="right">
                <Box
                  component="img"
                  src={images.landing_m4_r}
                  alt="Right"
                  sx={{
                    width: "100%",
                    maxWidth: "860px",
                    height: "auto",
                  }}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
              <Box textAlign="center" width="90%">
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "797px",
                    height: "auto",
                    margin: "0 auto",
                    mb: 6,
                  }}
                >
                  <Box textAlign="center">
                    <Typography variant="caption" sx={{ fontSize: 60, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#000000" : "#000000" }}>
                      {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Get Started with " : "现在开始用"}
                    </Typography>
                    <Typography variant="caption" component="span" sx={{ fontSize: 60, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#7F6CE0" : "#7F6CE0" }}>
                      {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "XMaaS " : "星觉大模型平台"}
                    </Typography>
                    <Typography variant="caption" component="span" sx={{ fontSize: 60, fontWeight: 600, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#000000" : "#000000" }}>
                      {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "to Support Your AI Solutions." : "支持你的AI应用"}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    mt: 2,
                  }}
                >
                  <Box
                    component="img"
                    src={images.landing_trynow}
                    alt="Try Now Button"
                    sx={{
                      mt: 2,
                      cursor: "pointer",
                      width: "200px",
                      height: "auto",
                    }}
                    onClick={handleTry}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          width="100%"
          bottom={0}
          minHeight="80px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Container>
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="ul"
                sx={({ breakpoints }) => ({
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "center",
                  listStyle: "none",
                })}
              >
                <Box component="li" pr={6} lineHeight={1}>
                  <Link href="https://xinglin-group.com/" target="_blank">
                    <Typography variant="button" fontWeight="regular" color="#999999" fontSize={14}>
                      {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "About Us" : "关于我们"}
                    </Typography>
                  </Link>
                </Box>
                <Box component="li" px={6} lineHeight={1}>
                  <Link href="https://xinglin-group.com/" target="_blank">
                    <Typography variant="button" fontWeight="regular" color="#999999" fontSize={14}>
                      {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Terms of Use & Privacy Policy" : "法律声明及隐私政策"}
                    </Typography>
                  </Link>
                </Box>
                <Box component="li" px={6} lineHeight={1}>
                  <Typography variant="button" fontWeight="regular" color="#999999" fontSize={14} onClick={handleOpenInfoDialog} sx={{ cursor: "pointer" }}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Contact Us" : "联系我们"}
                  </Typography>
                </Box>
                <Box component="li" pl={6} lineHeight={1}>
                  <Link href="https://xinglin-group.com/" target="_blank">
                    <Typography variant="button" fontWeight="regular" color="#999999" fontSize={14}>
                      {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Join Us" : "加入星临科技"}
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
      <LandingDialog open={infoDialog} handleClose={handleCloseInfoDialog} />
    </PageLayout>
  );
}

export default LandingPage;
