/**

*/

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Button, Box, Typography } from "@mui/material";
import KeyIcon from "@mui/icons-material/Key";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useMaterialUIController } from "context";
import ApiKeyDialog from "examples/APIDialog";
import images from "config/imageConfig";
import CreateAppDialog from "examples/AppDialog";
import ConfirmDialog from "examples/ConfirmDialog";
import MessageModal from "examples/MessageModal";
import EditAppDialog from "examples/AppDialog/EditAppDialog";
import axios from "axios";
import ErrorModal from "examples/ErrorModal";

function AppsOwn() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid } = controller;

  const [loading, setLoading] = useState(false);

  const [apiDialogOpen, setApiDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setApiDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setApiDialogOpen(false);
  };

  const [selectedApp, setSelectedApp] = useState(0);

  const [create, setCreate] = useState(false);
  const createApp = (name, info) => {
    getApps();
    setCreate(false);
  };
  const handleOpenCreate = () => {
    setCreate(true);
  };
  const handleCloseCreate = () => {
    setCreate(false);
  };

  const [edit, setEdit] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const handleOpenEditModal = () => {
    setEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setEditModalOpen(false);
  };
  const editApp = async (app) => {
    setLoading(true);
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.put(
        `${apiUrl}/app/${apps[selectedApp].id}`,
        {
          name: app.name,
          description: app.description,
          userid: app.userid,
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
          validateStatus: (status) => status < 500,
        }
      );
      if (response.status === 200) {
        getApps();
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenEditModal();
      }
    } catch (error) {
      console.log(error);
      handleOpenEditModal();
    } finally {
      setLoading(false);
      setEdit(false);
    }
  };
  const handleOpenEdit = (event, index) => {
    event.stopPropagation();
    setSelectedApp(index);
    setEdit(true);
  };
  const handleCloseEdit = () => {
    setEdit(false);
  };

  const [confirm, setConfirm] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDelete = () => {
    setDeleteModalOpen(true);
  };
  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };
  const deleteApp = async () => {
    setLoading(true);
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.delete(`${apiUrl}/app/${apps[selectedApp].id}`, {
        headers: {
          Authorization: `${authData.token}`,
          validateStatus: (status) => status < 500,
        },
      });
      if (response.status === 200) {
        handleOpenModal();
        getApps();
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenDelete();
      }
    } catch (error) {
      console.log(error);
      handleOpenDelete();
    } finally {
      setLoading(false);
      setConfirm(false);
    }
  };
  const handleOpenConfirm = (event, index) => {
    event.stopPropagation();
    setSelectedApp(index);
    setConfirm(true);
  };
  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleOpenErrorModal = () => {
    setErrorModalOpen(true);
  };
  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };
  const [apps, setApps] = useState([]);
  const getApps = async () => {
    setLoading(true);
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/app/findByUser`;
      let response = await axios.post(
        url,
        {
          userid: user.id,
          page: 1,
          limit: 9999999,
          sort: "created_time",
          order: "asc",
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
          validateStatus: (status) => status < 500,
        }
      );
      if (response.status === 200) {
        let apps = response.data.result;
        setApps(apps);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        setErrorModalOpen(true);
      }
    } catch (error) {
      console.log(error);
      setErrorModalOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleAppDetail = (app) => {
    navigate("/apps_own/app_detail", { state: [app] });
  };

  useEffect(() => {
    if (user) {
      getApps();
    }
  }, [user]);

  return (
    <DashboardLayout>
      <Box height="96vh" p={1}>
        <Box sx={{ bgcolor: "#ffffff", borderRadius: "8px", overflow: "hidden", height: "130px" }}>
          <Box display="flex" alignItems="center" pt={4}>
            <Typography px={4} component="h6" variant="h4" fontWeight="regular" color={"#7f6ce0"}>
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "My Apps" : "我的应用"}
            </Typography>
            <KeyIcon fontSize="small" pt={4}>
              KeyIcon
            </KeyIcon>
            <Typography
              px={1}
              mt={0.5}
              fontSize={14}
              fontWeight="regular"
              color={"#7f6ce0"}
              sx={{ cursor: "pointer" }}
              onClick={handleOpenDialog}
            >
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View my API KEY" : "查看我的API KEY"}
            </Typography>
            <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center" pr={4}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#7f6ce0",
                  color: "#ffffff",
                  height: "32px",
                }}
                onClick={handleOpenCreate}
              >
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Create New App" : "创建应用"}
              </Button>
            </Box>
          </Box>
          <Typography
            pt={1}
            px={4}
            pb={4}
            fontSize={14}
            fontWeight="regular"
            color={"#a0a0a0"}
          >
            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "User Application Management Center for centralized control of all applications." : "用户的应用管理中心，用于集中管理所有应用"}
          </Typography>
        </Box>
        <Box
          flex={1}
          height="90%"
          overflow="auto"
          display="grid"
          gridTemplateColumns="repeat(3, 1fr)"
          gap={2}
          alignContent="start"
          mt={2}
        >
          {loading && <CircularProgress sx={{ mt: 2 }} />}
          {apps.map((object, index) => (
            <Box
              key={index}
              maxWidth="100%"
              height="auto"
              position="relative"
              sx={{
                borderRadius: "8px",
                bgcolor: "#ffffff",
                padding: 2,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                "&:hover": {
                  bgcolor: "#ececec",
                },
              }}
              onClick={() => handleAppDetail(object)}
            >
              <Box display="flex" alignItems="center">
                <Box
                  component="img"
                  sx={{
                    height: 40,
                    width: 40,
                    mr: 2,
                  }}
                  src={images.model_icon}
                />
                <Typography component="h6" variant="h5" fontWeight="normal" color={"#000000"}>
                  {object.name}
                </Typography>
              </Box>
              <Box mt={1} mb={2} ml={7}>
                <Typography
                  component="h6"
                  variant="subtitle2"
                  fontWeight="regular"
                  color={"#838383"}
                  sx={{
                    wordWrap: "break-word",
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {object.description}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Typography
                  fontWeight="regular"
                  color={"#808080"}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#7f6ce0",
                    },
                    mr: 2,
                  }}
                  fontSize={12}
                  onClick={(event) => handleOpenConfirm(event, index)}
                >
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Delete" : "删除"}
                </Typography>
                <Typography
                  fontWeight="regular"
                  color={"#808080"}
                  mr={2}
                  fontSize={12}
                >
                  |
                </Typography>
                <Typography
                  fontWeight="regular"
                  color={"#808080"}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#7f6ce0",
                    },
                    mr: 2,
                  }}
                  fontSize={12}
                  onClick={(event) => handleOpenEdit(event, index)}
                >
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Edit" : "编辑"}
                </Typography>
                <Typography
                  component="h6"
                  variant="subtitle2"
                  fontWeight="regular"
                  color={"#808080"}
                  mr={2}
                  fontSize={12}
                >
                  |
                </Typography>
                <Typography
                  component="h6"
                  variant="subtitle2"
                  fontWeight="regular"
                  color={"#808080"}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      color: "#7f6ce0",
                    },
                    mr: 2,
                  }}
                  fontSize={12}
                  // onClick={() => handleAppDetail(object)}
                >
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View Api" : "查看API"}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <ApiKeyDialog open={apiDialogOpen} onClose={handleCloseDialog} />
      <CreateAppDialog open={create} onClose={handleCloseCreate} onSubmit={createApp} />
      <EditAppDialog
        open={edit}
        onClose={handleCloseEdit}
        onSubmit={editApp}
        app={apps[selectedApp]}
      />
      <ConfirmDialog
        open={confirm}
        onClose={handleCloseConfirm}
        title={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Delete App" : "删除App"}
        info={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Are you sure to delete the currently selected App? App cannot be restored after being deleted, and all Api-Keys associated with the App will be deleted." : "是否确认删除当前选择的App? App被删除后无法恢复，且会导致与该App关联的Api-Key全部被删除"}
        confirm={deleteApp}
      />
      <MessageModal
        open={modalOpen}
        onClose={handleCloseModal}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "The selected app has been successfully deleted" : "已经成功删除选择的App"}
        time={1000}
      />
      <ErrorModal
        open={errorModalOpen}
        onClose={handleCloseErrorModal}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "There was an error loading my app, please try again" : "我的应用加载出错，请重试"}
        time={1000}
      />
      <ErrorModal
        open={deleteModalOpen}
        onClose={handleCloseDelete}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Failed to delete the application, please try again" : "编辑应用失败，请重试"}
        time={1000}
      />
      <ErrorModal
        open={editModalOpen}
        onClose={handleCloseEditModal}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Failed to delete the application, please try again" : "编辑应用失败，请重试"}
        time={1000}
      />
    </DashboardLayout>
  );
}

export default AppsOwn;
