import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "examples/ConfirmDialog";
import CreateApiKeyDialog from "./CreateAPIDialog";
import axios from "axios";
import ErrorModal from "examples/ErrorModal";
import { useMaterialUIController } from "context";
import { setApiKeyChange } from "context";
import MessageModal from "examples/MessageModal";
import { useNavigate } from "react-router-dom";

const ApiKeyDialog = ({ open, onClose }) => {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid, apiKeyChange } = controller;

  const [loading, setLoading] = useState(false);

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleOpenErrorModal = () => {
    setErrorModalOpen(true);
  };
  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };
  const [data, setData] = useState([]);
  const loadData = async () => {
    setLoading(true);
    let apps = {};
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/app/findAll?page=1&limit=9999999&sort=created_time&order=asc`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        let appsGot = response.data.result;
        appsGot.forEach((app) => {
          if (app.userid === user.id) {
            apps[app.id] = app.name;
          }
        });
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenErrorModal();
        return;
      }
    } catch (error) {
      console.log(error);
      handleOpenErrorModal();
      return;
    }
    try {
      let appid = Object.keys(apps);
      let intid = appid.map(Number);
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/apiKey/findByUser`;
      let response = await axios.post(
        url,
        {
          appids: intid,
          page: 1,
          limit: 9999999,
          sort: "created_time",
          order: "asc",
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
          validateStatus: (status) => status < 500,
        }
      );
      if (response.status === 200) {
        let keys = response.data.result;
        let data = [];
        keys.forEach((key) => {
          let d = new Date(key.updated_time);
          let timeString = d.toLocaleString();
          data.push({
            id: key.id,
            api: key.api_key,
            showApi: false,
            app_id: key.app_id,
            app_name: apps[key.app_id],
            info: key.description,
            time: timeString,
            name: key.name,
            status: key.status,
          });
        });
        setData(data);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenErrorModal();
      }
    } catch (error) {
      console.log(error);
      handleOpenErrorModal();
    } finally {
      setLoading(false);
    }
  };

  const showApi = (index) => {
    setData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        showApi: !newData[index].showApi,
      };
      return newData;
    });
  };

  const encryptString = (s) => {
    const start = s.slice(0, 4);
    const end = s.slice(-4);
    const maskedString = start + "*".repeat(s.length - 8) + end;
    return maskedString;
  };

  const [create, setCreate] = useState(false);
  const createApi = (apiName, apiInfo) => {
    loadData();
    setCreate(false);
    setApiKeyChange(dispatch, true);
  };
  const handleOpenCreate = () => {
    setCreate(true);
  };
  const handleCloseCreate = () => {
    setCreate(false);
  };

  const [selectedApi, setSelectedApi] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpenDelete = () => {
    setDeleteModalOpen(true);
  };
  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };
  const deleteApi = async () => {
    setLoading(true);
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.delete(`${apiUrl}/apiKey/${data[selectedApi].id}`, {
        headers: {
          Authorization: `${authData.token}`,
        },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        handleOpenModal();
        loadData();
        setApiKeyChange(dispatch, true);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenDelete();
      }
    } catch (error) {
      console.log(error);
      handleOpenDelete();
    } finally {
      setLoading(false);
      setConfirm(false);
    }
  };
  const handleOpenConfirm = (index) => {
    setSelectedApi(index);
    setConfirm(true);
  };
  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (user && open) {
      loadData();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xl">
      <DialogTitle>
        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "MY API-KEYS" : "我的API-KEY"}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#7f6ce0",
            color: "#ffffff",
            float: "right",
            mb: 2,
          }}
          onClick={handleOpenCreate}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "CREATE API KEY" : "创建API KEY"}
        </Button>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ display: "table-header-group" }}>
              <TableRow>
                <TableCell>{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Name" : "名称"}</TableCell>
                <TableCell>{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "API-KEY" : "API-KEY"}</TableCell>
                <TableCell>{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Related App" : "归属应用"}</TableCell>
                <TableCell>{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Description" : "描述"}</TableCell>
                <TableCell>{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Status" : "状态"}</TableCell>
                <TableCell>{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Time of Create" : "创建时间"}</TableCell>
                <TableCell>{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Operactions" : "操作"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.showApi ? item.api : encryptString(item.api)}</TableCell>
                  <TableCell>{item.app_name}</TableCell>
                  <TableCell>{item.info}</TableCell>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? (
                    <TableCell>
                      {item.status === "AVAILABLE" ? "Available" : "Unavailable"}
                    </TableCell>
                  ) : (
                    <TableCell>
                      {item.status === "AVAILABLE" ? "正常" : "停用"}
                    </TableCell>
                  )}
                  <TableCell>{item.time}</TableCell>
                  <TableCell>
                    <Typography
                      component="span"
                      sx={{
                        color: "#7f6ce0",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#808080",
                        },
                        fontSize: 16,
                      }}
                      onClick={() => showApi(index)}
                    >
                      {item.showApi ? `${process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Hide" : "隐藏"}` : `${process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View" : "查看"}`}
                    </Typography>
                    {" | "}
                    <Typography
                      component="span"
                      sx={{
                        color: "#7f6ce0",
                        cursor: "pointer",
                        "&:hover": {
                          color: "#808080",
                        },
                        fontSize: 16,
                      }}
                      onClick={() => handleOpenConfirm(index)}
                    >
                      {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Delete" : "删除"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <ConfirmDialog
        open={confirm}
        onClose={handleCloseConfirm}
        title={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Delete API-Key" : "删除API-Key"}
        info={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Are you sure to delete the currently selected API-Key? The API-Key cannot be restored after being deleted." : "是否确认删除当前选择的API-Key? API-Key被删除后无法恢复"}
        confirm={deleteApi}
      />
      <CreateApiKeyDialog
        open={create}
        onClose={handleCloseCreate}
        onSubmit={createApi}
        appChange={false}
      />
      <MessageModal
        open={modalOpen}
        onClose={handleCloseModal}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "The selected Api-Key has been successfully deleted" : "已经成功删除选择的Api-Key"}
        time={1000}
      />
      <ErrorModal
        open={errorModalOpen}
        onClose={handleCloseErrorModal}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "My API Keys loading failed. Please try again." : "我的Api-Keys加载出错，请重试"}
        time={1000}
      />
      <ErrorModal
        open={deleteModalOpen}
        onClose={handleCloseDelete}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Failed to delete Api-Key, please try again" : "删除Api-Key失败，请重试"}
        time={1000}
      />
    </Dialog>
  );
};

ApiKeyDialog.defaultProps = {
  open: false,
  onClose: () => {},
};

ApiKeyDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ApiKeyDialog;
