import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import MessageModal from "examples/MessageModal";
import { useMaterialUIController } from "context";
import ErrorModal from "examples/ErrorModal";
import { useNavigate } from "react-router-dom";

const CreateAppDialog = ({ open, onClose, onSubmit }) => {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { user } = controller;

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    if (event.target.value.length <= 100) {
      setDescription(event.target.value);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleSubmit = async () => {
    if (name) {
      setLoading(true);
      let descr = description;
      if (!descr) {
        descr = " ";
      }
      try {
        if (!localStorage.getItem("maasAuthData")) {
          navigate("/authentication/sign-in");
          return;
        }
        const authData = JSON.parse(localStorage.getItem("maasAuthData"));
        const apiUrl = process.env.REACT_APP_API_URL;
        let response = await axios.post(
          `${apiUrl}/app`,
          {
            name: name,
            description: descr,
            userid: user.id,
          },
          {
            headers: {
          Authorization: `${authData.token}`,
        },
          validateStatus: (status) => status < 500,
          }
        );
        if (response.status === 200) {
          onSubmit(name, descr);
          setName("");
          setDescription("");
        } else if (response.status === 401) {
          localStorage.clear();
          navigate("/authentication/sign-in");
          return;
        } else {
          setModalOpen(true);
        }
      } catch (error) {
        console.log(error);
        setModalOpen(true);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Create New App" : "创建新的App"}</DialogTitle>
      <DialogContent>
        <TextField
          label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "App Name" : "应用名称"}
          fullWidth
          margin="normal"
          value={name}
          onChange={handleNameChange}
          placeholder={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Please enter app name" : "请输入名称"}
          sx={{ flexGrow: 1 }}
          size="small"
          autoComplete="off"
        />
        <TextField
          label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Description" : "描述"}
          multiline
          fullWidth
          margin="normal"
          value={description}
          onChange={handleDescriptionChange}
          helperText={description ? `${description.length}/100` : "0/100"}
          placeholder={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Please enter description" : "请输入描述"}
          sx={{ flexGrow: 1 }}
          size="small"
          autoComplete="off"
        />
        {loading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000",
            float: "right",
            borderColor: "#6e6e6e",
          }}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Cancel" : "取消"}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#7f6ce0",
            color: "#ffffff",
            float: "right",
          }}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Confirm" : "确定"}
        </Button>
      </DialogActions>
      <ErrorModal
        open={modalOpen}
        onClose={handleCloseModal}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Failed to create App. Please try again." : "创建App失败，请重试"}
        time={1000}
      />
    </Dialog>
  );
};

CreateAppDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
};

CreateAppDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default CreateAppDialog;
