import React, { useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { okaidia } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MessageModal from "examples/MessageModal";

const CodeBlock = ({ language, code }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <div style={{ position: "relative" }}>
      <CopyToClipboard text={code} onCopy={handleOpenModal}>
        <Button
          variant="text"
          sx={{
            position: "absolute",
            right: 0,
            top: -35,
            color: "#ffffff",
            "&:hover": {
              color: "#c7c7c7",
            },
            fontSize: 14,
            fontWeight: "regular",
          }}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Copy Code" : "复制代码"}
          <ContentCopyIcon />
        </Button>
      </CopyToClipboard>
      <SyntaxHighlighter language={language} style={okaidia} showLineNumbers>
        {code}
      </SyntaxHighlighter>
      <MessageModal
        open={modalOpen}
        onClose={handleCloseModal}
        message={`${process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Copy Successful" : "已经成功复制代码"}`}
        time={1000}
      />
    </div>
  );
};

CodeBlock.defaultProps = {
  language: "",
  code: "",
};

CodeBlock.propTypes = {
  language: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

export default CodeBlock;
