import React from "react";
import { Typography, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";

const PrivacyPolicy = ({ open, onClose }) => {
  const content = `
星觉大模型平台产品隐私政策

版本生效日期：2024年8月22日
版本更新日期：2024年8月22日
欢仰您体验星觉大模型平台产品！
我们深知个人信息对您的重要性，您的信赖对我们非常重要，我们将严格遵守法律法规要求采取相应的安全保护措施、保护您的个人信息安全性。基于此，我们制定本《星觉大模型平台产品隐私政策》（简称“本政策”)，帮助您充分了解在您参与使用本产品的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。
在正式开启本产品前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以粗体/粗体下划线标识的条款，确保您充分理解和同意之后再开始使用。除本政策外，在特定场景下，我们还会通过即时告知（含弹窗、页面提示、站内信等）、功能更新说明等方式，向您说明对应的信息收集目的、范围及使用方式，这些即时告知及功能更新说明等构成本政策的一部分，并与本政策具有同等效力。此外，您知悉并理解星觉大模型平台产品的功能可能是不断变化的，我们也会不时地对本政策进行更新，您将持续关注本政策内容并仔细阅读本政策及前述更新内容，如您不同意本政策或对任何更新内容的，您可通过本政策披露的联系方式与我们取得联系，在与我们达成一致前不得使用或继续使用本产品或相应产品功能，如您以任何方式使用本产品或相应产品功能的，则视为您接受本政策及前述更新内容。
本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。如对本政策内容有任何疑问、意见或建议，您可通过本政策文末的联系方式与我们联系。
本政策将帮助您了解以下内容：
一、本政策的适用范围
二、我们如何收集和使用您的个人信息
三、我们如何使用Cookie和同类技术
四、我们如何共享、转让、公开披露您的个人信息
五、您如何管理您的个人信息
六、我们如何保护和保存您的个人信息
七、未成年人用户信息的特别约定
八、本政策的更新
九、如何联系我们
附录：相关定义

一、本政策的适用范围
1.1.本政策适用于星觉大模型平台官网、App、小程序以及随技术发展出现的新形态（统称“星觉大模型平台产品”或简称“本产品”）向您提供的各项产品和服务。具体而言，本产品包括但不限于星觉大模型平台官网、星觉大模型平台App、星觉大模型平台小程序等，但以API、SDK、开源软件包等形式对外的服务形态除外 。但若我们及关联公司向您提供的产品或服务内设有单独隐私政策或类似法律文件，该等单独的文件优先适用。
1.2.本政策不适用于其他第三方向您提供的服务，您的信息处理及保护事宜应当适用该等第三方的隐私权政策或类似文件，我们对任何第三方不当使用或披露由您提供的信息不承担任何法律责任。

二、我们如何收集和使用您的个人信息
在您使用本产品期间，我们可能通过以下两种方式获取您的信息：
(1)您在使用本产品期间主动向我们提供的信息，如您在注册账号过程中填写的相应信息；
(2)在您使用本产品期间，我们主动收集的相关信息，如在您使用星觉大模型平台应用的操作记录或其他日志类数据等。
在您使用本产品期间，我们需要/可能需要收集和使用的您的个人信息包括如下两种：
(1)为实现向您提供星觉大模型平台产品的基本功能或服务，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您可能无法正常使用本产品或某个特定的基本功能。
(2)为实现向您提供星觉大模型平台产品的附加功能或服务，您可选择单独同意或不同意我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但不会影响您正常使用本产品的基本功能。
(一)为向您提供星觉大模型平台产品的基本功能或服务
2.1.帮助您完成账号注册及登录
21.1.为访问本产品，您应当按照页面引导输入您的手机号码并完成验证码验证，以完成本产品账号注册及登录。您提供的手机号码将作为我们与您取得联系的方式之一，包括但不限于接收本产品相关通知（如新品上线、服务变更等）。
2.1.2.当您通过小程序端、App端使用本产品时，基于我们与通信运营商的合作，你可选择使用”一键登绿”快速注册及登录。当您选择此方式时，我们会在授权的前提下将你设备中的手机号码作为你的注册、登录账号并免于密码或动态验证码核验。
2.1.3.当您使用您在第三方平台（例如微信等，具体以账号注册页面展示的第三方平台为准）上已注册的账号注册本产品账号时，我们会在获得您授权同意的前提下，从该第三方平台处获得您的信息以便您完成注册，我们所收集的信息类型取决于该等第三方平台的设置，具体涉及您在该等第三方平台账号下所保存的用户名、头像、该第三方平台的用户标识、手机号码等。如果以上信息有部分缺失，我们可能会按照上述直接注册的情形向您补充收集信息。
2.2.为您提供音视频及文档处理服务
2.2.1.在您使用音视频及文档处理服务时，我们将收集您的音频、视频信息内容以在云端服务器完成信息处理。同时，我们会收集处理后的文本信息，判断您的需求并做出实时反馈给您，以便于为您提供会议纪要和效果分析等服务。
2.2.2.为了帮助您更好的区分会议或者语音中的发言人，转写功能支持自动区分发言人，您可以根据场景选择2人对话或多人会议。我们将仅通过节奏、时间等客观特征对发言人进行区分识别，不会额外对个人声音特征信息进行处理。
2.3.为您提供Al对话服务
2.3.1.本产品的八对话服务依赖于您输入的内容，即我们需要收集并记录您与星觉大模型平台产品对话时所输入的文本信息、语音转文本信息、语音信息、图片信息、文件或网址信息，并为您提供对话服务。同时，为便于您随时能查看及管理对话历史记录，我们会记录你与星觉大模型平台产品的对话记录，以及基于上述信息形成的对话主题。
2.3.2.星觉大模型平台产品致力于为您提供更为优质的服务，在对输入内容进行去标识化处理且确保无法重新识别特定个人的前提下，我们会使用单个对话内的上下文信息对星觉大模型平台产品进行改善及优化，如提高对话质量和响应速度、以及对您输入内容的理解能力。
2.4.为保证本产品稳定开展
2.4.1.为保证本产品正常开展，我们可能会收集您在使用本产品过程中产生的日志数据（包括您的IP地址、浏览器信息和类型、您提出请求的日期和时间，以及您如何与我们的网站互动）、使用信息（您的位置、例如客户端日期和访问时间、服务端日期和访问时间、计算机或移动设备的类型、网络信息、运行中应用列表、剪切板信息）、设备信息（包括安卓系统：运行中应用列表、AndroidID、BSSID、SSID、运营商信息、GAID、传感器信息、IMEI、MEID、OAID;IOS系统：运行中应用列表、MAC、OAID、蓝牙Mac、IDFV、IDFA)、Cookie等来帮助我们进行服务情况分析，并进一步帮助我们能有效地增强您的使用体验。特别地，我们承诺，不会将Cookie用于本政策所述目的之外的任何其他用途。我们使用Cookie主要为了保障服务体验的安全、高效运转，可以使我们确认您账号的安全状态，排查崩溃、延迟的相关异常情况，帮助您省去重复填写表单、输入搜索内容的步骤和流程。
2.4.2.为提高本产品安全性，保护您或淇他用户或公众的人身安全、财产安全、账号安全，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入、恶意程序等安全风险，更准确地识别违反法律法规或相关协议、规则的情况，我们及我们的关联公司会收集您的设备信息、日志信息，并可能使用或整合您的设备信息、日志信息，来综合判断您账号及使用风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。
(二)为向您提供星觉大模型平台产品的附加功能或服务
2.5.为帮助您完成账号个性化设置
2.5.1.当您完成账号注册后，您可以向我们提供您的账号呢称、头像图片等信息，以便于我们帮助您完成账号个性化设置。
2.6.为您提供体功能
2.6.1.您可选择使用我们提供的体功能，为此，您需要向我们提供拟创建的体头像图片、名称、简介、设定等信息，以及用于训练体的文件材料。
2.7.为您提供实时天气信息查询功能
2.7.1.为在星觉大模型平台App端内展示您所在城市的天气情况，您需要授权我们通过采集您的IP地址信息，以确定您所在的城市。
2.8.为您提供常用词管理功能
2.8.1.为了向您提供更好的会议语音转写服务，我们将收集并利用您配置的常用词文档，优化用户模型，此模型将仅用于您所使用的转写服务的效果优化，不涉及基础模型的优化。如您不需要此功能，可在常用词管理中，随时删除相关文档及常用词，该操作不会影响您使用星觉大模型平台产品基本功能。
2.9.为向您提供专业支持或帮助
2.9.1.当您在使用星觉大模型平台产品过程中遇到问题，可与我们联系请求专业支持或帮助，为了保障您的账户及系统安全，我们可能会在响应您的请求前，要求您提供必要的个人信息以核验您的用户身份。为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的沟通、通信/通话记录及相关内容（包括用户信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息)。
2.10.为改进及提升您的产品使用体验
2.10.1.我们会不时地开展客户体验调研或服务沟通工作，我们可能通过您在账号注册环节、问卷填写环节、业务服务环节等过程中向我们提供联系信息与您取得联系。如您不希望参加调研或沟通，可以在接到来电后表示不希望参加调查，或者发送退订邮件或根据信息中提供的退订方式予以退订，拒绝填写在线问卷等。
2.10.2.您可随时根据实际情况对星觉大模型平台产品的生成信息或产品体验做出评价，包括通过点赞、点踩、我要反馈等方式。在经过去标识化且无法重新识别特定个人的前提下，我们将收集您的评价及反馈，以改善星觉大模型平台产品的服务质量，优化用户体验。
2.10.3.我们可能会在征得您单独同意的情况下获取您的设备标识符（如IDFA)，通过对您的使用行为进行分析，以改善您的产品使用体验。
2.11.确保您正常接收平台通知
2.11.1.当您使用星觉大模型平台App时，我们会以一定频率唤醒您的手机设备App自启动和关联启动其他App的功能，通过客户端“消息”，向您推送平台通知（如重要功能/活动上线、协议更新等需要用户知晓的内容消息)。由于上述功能存在于手机设置中，您可以在您的设备“设置”中选择开启/关闭星觉大模型平台App的通知权限，以拒绝接收相关通知信息。
2.12.基于系统权限为您提供的附加功能
为向您提供更便捷的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加功能中可能会通过开启系统权限以收集和使用您的个人信息。如果您不同意开启相关权限，不会影响您使用本产品的基本功能，但您可能无法获得这些附加功能给您带来的额外用户体验。
权限名称	权限功能说明及使用场景	是否可关闭及影响	适用系统
麦克风 1、语音转文字 2、语音对话 3、实时语音处理 可关闭；但用户可能无法使用对应功能	Android/IOS/PC
相册 1、照片或者视频上传 2、照片或视频下载保存 可关闭；但用户可能无法使用对应功能	IOS/PC
相机 1、拍照上传照片或者视频 可关闭；但用户可能无法使用对应功能	IOS/Android/PC
存储 1、离线音视频及文档处理 2、生成内容或处理后的内容下载保存 可关闭；但用户可能无法使用对应功能	Android/PC
您可在您设备的“设置”页面中或我们客户端“个人中心-权限管理”中逐项查看上述权限的状态，可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。
(三)无需获得您授权同意的个人信息收集、使用例外情形
2.13.您充分知晓，依据可适用的法律，在以下情形中，我们收集、使用您的个人信息无需征得您的授权同意：
2.13.1.涉及国家安全与利益、社会公共利益的；
2.13.2.为履行我们的法定职责或者法定义务或者响应政府部门指示所必需的；
2.13.3.与您签订和履行您作为协议或其他书面文件一方所必需的；
2.13.4.为应对突发公共卫生事件，或者紧急情况下为保护您和他人的生命健康和财产安全所必需；
2.13.5.在合理的范围内处理您自行向社会公众公开的个人信息；
2.13.6.在合理的范围内处理从合法公开披露的信息中收集的您的个人信息（如合法的新闻报道、政府信息公开等渠道）；
2.13.7.法律法规规定的其他情形。
(四)其他约定
2.14.如您在使用本产品过程中上传的内容（包括但不限于对话信息、语音/文本信息、评价、反馈）包含或涉及第三方的信息（包括但不限于个人信息），在输入这些信息之前，您需确保您的行为已经
取得合法的授权，避免泄露他人个人信息，侵犯他人权利。
2.15.请您理解，我们向您提供的功能和服务是不断更新和发展的，如果某一功能或服务未被包含在上述说明中且收集了您的信息，我们会通过页面提示、交互流程、网站公告、另行签署协议等方式另
行向您说明信息收集的内容、范围和目的，以征得您的同意。

三、我们如何使用Cookie和同类技术
3.1.为确保正常运转、为您获得更轻松的访问体验、我们会在您的移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。我们只能读取由我们提供的Cookie。
3.2.您可根据自己的偏好管理Cookie,您也可以清除计算机上保存的所有Cookie。大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的网站时更改用户设置。如需详细了解如何更改浏览器设置，请访问您使用的浏览器的相关设置页面。
3.3.除Cookie外，我们还会使用网站信标和像素标签等其他同类技术。网站信标通常是一种嵌入到网页或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。

四、我们如何共享、转让、公开披露您的个人信息
我们产品或/及服务中的某些模块和功能将由服务提供商提供，为此，我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们在与该等合作伙伴进行合作时，将会遵守以下原测：
(1)	合法正当与最小必要原则：数据处理应当具有合法性基础，具有正当的目的，并以实现处理目的最小范围为限；
(2)	用户知情权与决定权最大化原测：数据处理过程中充分尊重用户对其个人信息处理享有的知情权与决定权；
(3)	安全保障能力最强化原则：我们将采取必要措施保障所处理个人信息的安全，审慎评估合作方使用数据的目的，对这些合作方的安全保障能力进行综合评估，并要求其遵循合作协议的有关要求。
4.1.委托处理
我们可能委托授权合作伙伴（如我们的关联公司、技术服务方、广告或分析服务类的授权合作伙伴等）处理您的个人信息，以便授权合作伙伴代表我们为您提供某些服务或履行职能。我们仅会在采用行业通用的安全技术前提下（如不会将您的个人身份信息以明文的方式交付授权合作伙伴），出于本政策声明的合法、正当、必要、特定、明确的目的委托其处理您的信息，授权合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何超出委托范围的目的。如果授权合作伙伴将您的信息用于我们未委托的用途，其将单独征得您的同意。
4.2.第三方共享
原则上，我们不会与其他组织和个人共享您的用户信息，但以下情况除外：
4.2.1.在获取您明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的用户信息。
4.2.2.为履行法定义务所必需的共享：我们可能会根据法律法规规定、诉讼、仲裁解决需要，或按行政、司法机关依法提出的要求，对外共享您的用户信息。
4.2.3.为订立、履行您作为一方当事人的合同所必需的情况下的共享。
4.3.SDK的使用
4.3.1.为保障星觉大模型平台App的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序，该等SDK将可能会采集您的信息，如硬件型号、硬件序列号、操作系统版本号、SD卡数据、MAC地址、唯一设备识别码、软件版本号、网络接入方式及类型、操作日志信息等。关于我们接入的授权合作伙伴SDK详情及其采集的具体信息请见《第三方SDK收集使用信息说明》。
4.3.2.我们会对授权合作伙伴获取有关信息的应用程序接口(API)、软件工具开发包(SDK)进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。
4.3.3.对我们与之共享用户信息的公司、组织和个人，我们会与其签署严格的保密协议以及信息保护约定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理用户信息。
4.4.转让
原则上，我们不会与其他组织和个人共享您的用户信息，但在我们与其他法律主体者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到用户信息转让，我们会要求新的持有您用户信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。如涉及破产且无承接方的情形，我们将依法对数据进行删除处理。
4.5.公开披露
我们仅会在以下情况下公开披露您的用户信息：
4.5.1.获得您充分同意或基于您的主动选择，我们可能会公开披露您的用户信息；
4.5.2.为保护本服务及其关联公司用户或公众的人身财产安全免遭侵害，我们可能依据适用的法律或平台相关协议、规则披露关于您的用户信息。例如，若您作为云市场服务商销售假货或盗版商品，我们可能会公开披露您的店铺主体信息与处罚情况。
4.6.对外提供用户信息时事先征得授权同意的例外
以下情形中，对外提供用户信息无需事先征得您的授权同意：
4.6.1.与国家安全、国防安全有关的：
4.6.2.与公共安全、公共卫生、重大公共利益有关的：
4.6.3.与刑事侦查、起诉、审判和判决执行等有关的：
4.6.4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的：
4.6.5.您自行向社会公众公开的个人信息；
4.6.6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道：
4.6.7.为履行我们的法定职责或者法定义务或者响应政府部门指示所必需的。

五、我们如何保护和保存您的个人信息
5.1.我们非常重视您的信息安全。我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的用户信息。防止用户信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们会使用加密技术提高用户信息的安全性；我们会使用受信赖的保护机制防止用户信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问用户信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。
个人信息进行删除或匿名化处理。
5.2.尽管我们已经按照相关法律法规要求采取了合理有效的安全措施，但由于技术限制和可能存在的恶意手段，我们不能保证百分之百的信息安全。请您了解并理解，我们所提供的系统和通信网络可能会受到我们无法控制的因素影响而出现问题。因此，我们强烈建议您采取积极措施来保护个人信息的安全，例如使用复杂密码、定期更改密码、不将账号密码和相关个人信息透露给他人等。
5.3.在不幸发生用户信息安全事件（泄露、丢失等）后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通等等方式告知您，难以逐一告知用户信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报用户信息安全事件的处置情况。
5.4.如出现产品和服务停止运营的情形，我们会采取合理措施保护用户信息安全，包括及时停止继续收集用户信息的活动；停止运营的通知将以逐一送达或公告的形式通知用户；并对所持有的个人信息进行删除或匿名化处理等。
5.5.原则上，我们在中华人民共和国境内开展业务过程中产生或收集的个人信息，将存储在中华人民共和国境内；除非为向您提供某项服务所必需，我们会在获得您单独同意，并采取其他法律法规要求的措施下，方会向境外传输您的个人信息。

六、未成年人用户信息的特别约定
6.1.我们主要面向成年人提供本产品及其他相关产品。但我们非常重视对未成年人个人信息的保护，如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本政策的所有内容，并在征得您的父母或其他监护人同意的前提下使用我们的产品或服务以及向我们提供信息。
6.2.受制于现有技术和商业模式，我们很难在账号注册环节主动识别未成年人的个人信息，如果您是未成年人的监护人，且您发现我们存在未经其授权获取未成年人个人信息的情况，您可通过本政策中公示的联系方式联系我们，我们在收到通知后会及时予以核查，并在核查属实后及时删除或匿名化处理相关信息。如果我们主动审查发现存在前述情形的，我们也会主动对相关个人信息予以删除或进行匿名化处理。

七、本政策的更新
7.1.为给您带来更好的服务体验，我们持续努力改进我们的技术。我们可能会更新我们的隐私政策，并通过短信或其他适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。但未经您明确同意，我们不会限制您按照本政策所享有的权利。
7.2.对于重大变更，我们会提供更为显著的通知（包括我们会通过短信、邮件进行通知或向您提供弹窗提示）再次向您告知，并根据法律法规的相关要求征得您的同意。

八、如何联系我们
8.1.您对本政策内容有任何疑问和意见，或者您上述实践以及操作上有任何疑问和意见，您可以通过下述方式与我们联系，我们将在15天内回复您的请求。如您对我们的解决方式不满，您有权向中国互联网举报中心投诉。
8.1.1.「星觉大模型平台官网」、「星觉大模型平台App」、「星觉大模型平台小程序」的相关问题，可发送邮件至 shqs_business@xinglin-group.com或在产品页面展示的"投诉与反馈”入口进行反馈。
8.1.2.「官网」的相关问题，可发送邮件至shqs_business@xinglin-group.com或在产品页面展示的"投诉与反馈”入口进行反馈。
 
附录：相关定义
我们：上海擎算科技有限公司。
个人信息：以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。
敏感个人信息：敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。
设备信息：包括硬件型号、硬件序列号、软件列表、应用安装列表、软件安装列表、唯一设备识别码IMEI、设备MAC地址、SIM卡IMSI信息、Android ID、MEID、OpenUDID、GUID、IDFA、IDFV、BSSID、GAID、蓝牙信息、广播组件通讯信息、ICCID、BSSID、SSID、运营商信息、传感器（陀螺仪、重力传感器、加速度传感器、环境传感器、光学心率传感器）、剪切板。以实际采集情况为准。
日志信息：包括时间、用户D、对话引D、对话内容，以及P地址、浏览器类型、电信运营商、使用语言、访问日期和时间。
去标识化：指个人信息经过处理，使其在不借助额外信息的情况下无法识别特定自然人的过程。
匿名化：指个人信息经过处理无法识别特定自然人且不能复原的过程。
Cookie:Cookie和同类设备信息标识技术是互联网中普遍使用的技术。当您体验本产品时，我们可能会使用相关技术向您的设备发送一个或多个Cookie或匿名标识符，以收集、标识和存储您访问、使用本产品时的信息
中国互联网举报中心网址：https://www.12377.cnl。

  `;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Privacy Policy" : "隐私协议"}
        <IconButton
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
          {content}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

PrivacyPolicy.defaultProps = {
  open: false,
  onClose: () => {},
};

PrivacyPolicy.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PrivacyPolicy;
