import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Button,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CodeBlock from "./CodeBlock";
import PropTypes from "prop-types";

const CodeSample = ({ open, onClose, model, onAPI, onDetail }) => {
  if (!open || !model) {
    return null;
  }
  const [language, setLanguage] = useState("python");
  let template = `
from openai import OpenAI
client = OpenAI(base_url="https://api.xmaas.cn/v1", api_key="{YOUR_API_KEY}")

completion = client.chat.completions.create(
    model="{YOUR_MODEL}",
    messages=[
        {"role": "system", "content": "You are a helpful assistant."},
        {
            "role": "user",
            "content": "Write a haiku about recursion in programming."
        }
    ]
)

print(completion.choices[0].message)
  `;
  const [codeContent, setCodeContent] = useState(template);

  const handleOpenDetail = () => {
    onClose();
    onDetail(model);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle bgcolor="#f3f3f3">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" ml={2}>
            {model.name}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Button
            variant="outlined"
            sx={{
              color: "#7f6ce0",
              borderColor: "#7f6ce0",
              bgcolor: "#d0c5da",
              "&:hover": {
                color: "#808080",
              },
              fontSize: 15,
              fontWeight: "regular",
              ml: 2,
            }}
          >
            {language}
          </Button>
          <Box>
            <Button
              variant="text"
              sx={{
                color: "#808080",
                "&:hover": {
                  color: "#474747",
                },
                fontSize: 15,
                fontWeight: "regular",
              }}
              onClick={handleOpenDetail}
            >
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Model Info" : "模型详情"}
            </Button>
            <Button
              variant="text"
              sx={{
                color: "#808080",
                "&:hover": {
                  color: "#474747",
                },
                fontSize: 15,
                fontWeight: "regular",
              }}
              onClick={onAPI}
            >
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "My API KEYs" : "我的APIKEY"}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#2d2d2d",
            color: "#ffffff",
            borderRadius: "8px",
            paddingTop: "30px",
            fontFamily: "monospace",
            fontSize: "0.875rem",
          }}
        >
          <CodeBlock language={language} code={codeContent} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

CodeSample.defaultProps = {
  open: false,
  onClose: () => {},
  model: {},
  onAPI: () => {},
  onDetail: () => {},
};

CodeSample.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  model: PropTypes.object,
  onAPI: PropTypes.func.isRequired,
  onDetail: PropTypes.func.isRequired,
};

export default CodeSample;
