import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import dayjs from "dayjs";
import PropTypes from "prop-types";

const TokenUsageChart = ({ token, data, onClose }) => {
  const today = dayjs();
  const chartData = data.map((value, index) => ({
    date: today.subtract(29 - index, "day").format("MM/DD"),
    tokens: value,
  }));

  return (
    <Box mt={3}>
      <Box display="flex" alignItems="center">
        <Typography component="h6" variant="h5">
          {token.model.name}
        </Typography>
        <Typography
          component="h6"
          variant="subtitle2"
          fontWeight="regular"
          color={"#7f6ce0"}
          sx={{
            cursor: "pointer",
            marginLeft: 2,
            "&:hover": {
              color: "#808080",
            },
          }}
          onClick={onClose}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "fold" : "收起"}
        </Typography>
      </Box>
      <Typography component="h6" variant="body1" color={"#838383"} mt={1} mb={2}>
        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Usage in 30 days" : "近30天数据"}
      </Typography>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={chartData} margin={{ left: 60, right: 20 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis domain={[0, Math.max(...data)]} />
          <Tooltip />
          <Line type="monotone" dataKey="tokens" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

TokenUsageChart.defaultProps = {
  token: {},
  data: [],
  onClose: () => {},
};

TokenUsageChart.propTypes = {
  token: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TokenUsageChart;
