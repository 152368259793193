/**

*/

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, IconButton, Select, MenuItem, Button, Divider } from "@mui/material";
import axios from "axios";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMaterialUIController } from "context";
import { useNavigate, useLocation } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import ConfirmDialog from "examples/ConfirmDialog";
import MessageModal from "examples/MessageModal";
import ErrorModal from "examples/ErrorModal";
import { use } from "react";

function MyOder() {
  const navigate = useNavigate();
  const location = useLocation();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid, apiKeyChange } = controller;

  const [orders, setOrders] = useState([]);
  const orderStates = ["全部订单", "待付款", "已取消"];
  const orderStatesEng = ["All Orders", "Awaiting Payments", "Cancelled Orders"];
  const [stateSelected, setStateSelected] = useState(0);
  const changeOrderState = (index) => {
    setScreen(1);
    setStateSelected(index);
    loadData(index);
  };
  const loadData = async (index) => {
    if (index === 0) {
      try {
        if (!localStorage.getItem("maasAuthData")) {
          navigate("/authentication/sign-in");
          return;
        }
        const authData = JSON.parse(localStorage.getItem("maasAuthData"));
        const apiUrl = process.env.REACT_APP_API_URL;
        let url = `${apiUrl}/recharge/findByUser?userid=${user.id}&page=1&limit=9999999&sort=updated_time&order=desc`;
        let response = await axios.get(url, {
          headers: { Authorization: `${authData.token}` },
          validateStatus: (status) => status < 500,
        });
        if (response.status === 200) {
          let newOders = response.data.result;
          newOders.forEach((item) => {
            let d = new Date(item.updated_time);
            let timeString = d.toLocaleString();
            item.updated_time = timeString;
          });
          setOrders(newOders);
        } else if (response.status === 401) {
          localStorage.clear();
          navigate("/authentication/sign-in");
          return;
        } else {
          handleOpenLoadFailed();
        }
      } catch (error) {
        console.log(error);
        handleOpenLoadFailed();
      }
    } else if (index === 1) {
      setOrders([]);
    } else {
      setOrders([]);
    }
  };

  const [screen, setScreen] = useState(1);
  const [targetOrder, setTargetOrder] = useState(null);
  const handleSelectTargetOrder = (order) => {
    setTargetOrder(order);
    setScreen(2);
  };

  const [deletingOrder, setDeletingOrder] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [loadFailed, setLoadFailed] = useState(false);
  const handleOpenLoadFailed = () => {
    setLoadFailed(true);
  };
  const handleCloseLoadFailed = () => {
    setLoadFailed(false);
  };
  const handleOpenDelete = () => {
    setDeleteModalOpen(true);
  };
  const handleCloseDelete = () => {
    setDeleteModalOpen(false);
  };
  const handleOpenDeleteSuccess = () => {
    setDeleteSuccess(true);
  };
  const handleCloseDeleteSuccess = () => {
    setDeleteSuccess(false);
  };
  const deleteOrder = async () => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.delete(`${apiUrl}/recharge/${orders[deletingOrder].id}`, {
        headers: {
          Authorization: `${authData.token}`,
          validateStatus: (status) => status < 500,
        },
      });
      if (response.status === 200) {
        handleOpenDeleteSuccess();
        loadData(stateSelected);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenDelete();
      }
    } catch (error) {
      console.log(error);
      handleOpenDelete();
    } finally {
      handleCloseConfirm();
    }
  };
  const handleOpenConfirm = (index) => {
    setDeletingOrder(index);
    setConfirm(true);
  };
  const handleCloseConfirm = () => {
    setConfirm(false);
  };

  useEffect(() => {
    if (user) {
      loadData(stateSelected);
    }
    if (location.state && location.state[0]) {
      let order = location.state[0];
      handleSelectTargetOrder(order);
    }
  }, [user]);

  return (
    <DashboardLayout>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100vh",
          bgcolor: "#F9FAFB",
        }}
      >
        <Box
          sx={{
            width: "15%",
            height: "100%",
            bgcolor: "#FFFFFF",
            borderRight: "1px solid #E0E0E0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 4,
          }}
        >
          <Typography fontSize={20} color="#7f6ce0" mb={2}>
            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "My Orders" : "我的订单"}
          </Typography>
          <Divider sx={{ width: "80%", mb: 2 }} />
          {orderStates.map((item, index) => (
            <Button
              key={index}
              onClick={() => changeOrderState(index)}
              sx={{
                width: "80%",
                bgcolor: index === stateSelected ? "#F0F0F0" : "transparent",
                color: "#000",
                textAlign: "center",
                borderRadius: 2,
                ":hover": {
                  bgcolor: "#E0E0E0",
                },
                mb: 1,
                fontSize: 14,
              }}
            >
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? orderStatesEng[index] : orderStates[index]}
            </Button>
          ))}
        </Box>
        {screen === 1 && (
          <Box
            sx={{
              flex: 1,
              p: 6,
              display: "flex",
              flexDirection: "column",
              gap: 3,
              overflowY: "auto",
              ml: 5,
            }}
          >
            {orders.map((item, index) => (
              <Box
                key={index}
                sx={{
                  borderRadius: 2,
                  border: "1px solid #E0E0E0",
                  p: 3,
                  display: "flex",
                  alignItems: "center",
                  gap: 3,
                  bgcolor: "#FFFFFF",
                  width: "80%",
                  position: "relative",
                }}
              >
                <Box sx={{ textAlign: "left", flex: 1 }}>
                  <Typography color="#000000" fontSize={18} mb={2}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Add Fund" : "充值我的钱包"}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography color="#000000" fontSize={14}>
                      {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Order No.：" : "订单号："}{item.id}
                    </Typography>
                    <CopyToClipboard text={item.id}>
                      <ContentCopyIcon
                        sx={{
                          fontSize: "14px",
                          color: "#777777",
                          cursor: "pointer",
                        }}
                      />
                    </CopyToClipboard>
                  </Box>
                  <Typography color="#000000" fontSize={14}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Time of pay：" : "支付时间："}{item.updated_time}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    textAlign: "right",
                    flex: 1,
                    pr: 3,
                  }}
                >
                  <Typography color="#777777" fontSize={14} mb={2}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Completed" : "已完成"}
                  </Typography>
                  <Typography color="#000000" fontSize={18} mt={1}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "CNY ￥" : "CNY ￥"}{item.amount}
                  </Typography>
                  <Typography color="#777777" fontSize={14}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Quantity: 1" : "数量：1"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    bottom: 0,
                    left: "calc(66.67%)",
                    width: "1px",
                    bgcolor: "#E0E0E0",
                  }}
                />
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: "#7F6CE0",
                      color: "#000000",
                      ":hover": {
                        borderColor: "#5A4DB2",
                        color: "#5A4DB2",
                      },
                      fontSize: 14,
                    }}
                    onClick={() => handleSelectTargetOrder(item)}
                  >
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View" : "查看"}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: "#7F6CE0",
                      color: "#000000",
                      ":hover": {
                        borderColor: "#5A4DB2",
                        color: "#5A4DB2",
                      },
                      fontSize: 14,
                    }}
                    onClick={() => handleOpenConfirm(index)}
                  >
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Delete" : "删除"}
                  </Button>
                </Box>
              </Box>
            ))}
          </Box>
        )}
        {screen === 2 && (
          <Box p={15}>
            <Box
              sx={{
                borderRadius: 3,
                border: "1px solid #E0E0E0",
                p: 15,
                textAlign: "left",
                alignContent: "center",
                bgcolor: "#FFFFFF",
              }}
            >
              <Typography color="#000000" fontSize={16}>
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Add Fund" : "充值我的钱包"}
              </Typography>
              <Typography color="#000000" fontSize={30} mb={5}>
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "CNY ￥" : "CNY ￥"}{targetOrder ? targetOrder.amount : 0}
              </Typography>
              <Box display="flex" justifyContent="space-between" gap={30}>
                <Typography color="#000000" fontSize={14}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Order: Add Fund" : "商品：充值我的钱包"}
                </Typography>
                <Typography color="#000000" fontSize={14}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "CNY ￥" : "CNY ￥"}{targetOrder ? targetOrder.amount : 0}
                </Typography>
              </Box>
              <Typography color="#000000" fontSize={14}>
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Quantity: 1" : "数量：1"}
              </Typography>
              <Box height="1px" bgcolor="#000000" my={1}></Box>
              <Box display="flex" justifyContent="space-between" gap={30}>
                <Typography color="#000000" fontSize={14}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Total" : "小计"}
                </Typography>
                <Typography color="#000000" fontSize={14}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "CNY ￥" : "CNY ￥"}{targetOrder ? targetOrder.amount : 0}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" gap={30}>
                <Typography color="#000000" fontSize={14}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Discount" : "优惠"}
                </Typography>
                <Typography color="#7f6ce0" fontSize={14}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "-CNY ￥0.00" : "-CNY ￥0.00"}
                </Typography>
              </Box>
              <Box height="1px" bgcolor="#000000" my={1}></Box>
              <Box display="flex" justifyContent="space-between" gap={30} mb={5}>
                <Typography color="#000000" fontSize={14}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Total pay" : "总计支付"}
                </Typography>
                <Typography color="#000000" fontSize={14}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "CNY ￥" : "CNY ￥"}{targetOrder ? targetOrder.amount : 0}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <Typography color="#000000" fontSize={14}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Order No.：" : "订单号："}{targetOrder ? targetOrder.id : 0}
                </Typography>
                <CopyToClipboard text={targetOrder ? targetOrder.id : 0}>
                  <ContentCopyIcon
                    sx={{
                      fontSize: "14px",
                      color: "#777777",
                      cursor: "pointer",
                    }}
                  />
                </CopyToClipboard>
              </Box>
              <Typography color="#000000" fontSize={14}>
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Payee: Shanghai Xinghao Technology Co., Ltd." : "收款方：上海惺昊科技有限公司"}
              </Typography>
              <Typography color="#000000" fontSize={14}>
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Payment Method:" : "支付方式："}{targetOrder ? targetOrder.description : ""}
              </Typography>
              <Typography color="#000000" fontSize={14}>
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Payment Time:" : "支付时间："}{targetOrder ? targetOrder.updated_time : ""}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <ConfirmDialog
        open={confirm}
        onClose={handleCloseConfirm}
        title={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Delete recharge record" : "删除充值记录"}
        info={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Are you sure to delete the currently selected recharge record? Recharge records cannot be restored after being deleted." : "是否确认删除当前选择的充值记录? 充值记录被删除后无法恢复"}
        confirm={deleteOrder}
      />
      <MessageModal
        open={deleteSuccess}
        onClose={handleCloseDeleteSuccess}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "The selected recharge record has been successfully deleted" : "已经成功删除选择的充值记录"}
        time={1000}
      />
      <ErrorModal
        open={deleteModalOpen}
        onClose={handleCloseDelete}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Failed to delete recharge record, please try again" : "删除充值记录失败，请重试"}
        time={1000}
      />
      <ErrorModal
        open={loadFailed}
        onClose={handleCloseLoadFailed}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Failed to load history, please try again" : "载入历史记录失败，请重试"}
        time={1000}
      />
    </DashboardLayout>
  );
}

export default MyOder;
