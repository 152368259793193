import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from "@mui/material";

const ConfirmDialog = ({ open, onClose, title, info, confirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{info}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          sx={{
            color: "#000000",
            fontSize: 14,
            fontWeight: "regular",
            borderColor: "#7e7e7e",
          }}
          onClick={onClose}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Cancel" : "取消"}
        </Button>
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#7f6ce0",
            color: "#ffffff",
            float: "right",
            fontSize: 14,
            fontWeight: "regular",
            mr: 2,
          }}
          onClick={confirm}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Confirm" : "确认"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  open: false,
  onClose: () => {},
  title: "default title",
  info: "default info",
  confirm: () => {},
};

ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  info: PropTypes.string,
  confirm: PropTypes.func,
};

export default ConfirmDialog;
