import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormGroup,
  InputLabel,
  OutlinedInput,
  FormControlLabel,
  Switch,
} from "@mui/material";
import axios from "axios";
import ErrorModal from "examples/ErrorModal";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";

const CreateApiKeyDialog = ({ open, onClose, onSubmit, appChange }) => {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid } = controller;

  const [name, setName] = useState("");
  const [selectedApp, setSelectedApp] = useState({});
  const [description, setDescription] = useState("");
  const [checked, setChecked] = useState(true);

  const handleNameChange = (event) => {
    if (event.target.value.length <= 15) {
      setName(event.target.value);
    }
  };

  const handleAppChange = (event) => {
    const appId = event.target.value;
    const app = apps.find((app) => app.id === appId);
    setSelectedApp(app);
  };

  const handleDescriptionChange = (event) => {
    if (event.target.value.length <= 30) {
      setDescription(event.target.value);
    }
  };

  const handleCheckChange = (event) => {
    setChecked(!checked);
  };

  const [newApiFail, setNewApiFail] = useState(false);
  const handleOpenNewApiFail = () => {
    setNewApiFail(true);
  };
  const handleCloseNewApiFail = () => {
    setNewApiFail(false);
  };
  const handleSubmit = async () => {
    if (selectedApp && description && name) {
      try {
        if (!localStorage.getItem("maasAuthData")) {
          navigate("/authentication/sign-in");
          return;
        }
        const authData = JSON.parse(localStorage.getItem("maasAuthData"));
        const apiUrl = process.env.REACT_APP_API_URL;
        let response = await axios.post(
          `${apiUrl}/apiKey`,
          {
            app_id: selectedApp.id,
            description: description,
            name: name,
            status: checked ? "AVAILABLE" : "UNAVAILABLE",
          },
          {
            headers: {
          Authorization: `${authData.token}`,
        },
          validateStatus: (status) => status < 500,
          }
        );
        if (response.status === 200) {
          onSubmit(selectedApp.name, description);
          setSelectedApp({});
          setDescription("");
          setName("");
          setChecked(true);
        } else if (response.status === 401) {
          localStorage.clear();
          navigate("/authentication/sign-in");
          return;
        } else {
          handleOpenNewApiFail();
        }
      } catch (error) {
        console.log(error);
        handleOpenNewApiFail();
      }
    }
  };

  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const handleOpenErrorModal = () => {
    setErrorModalOpen(true);
  };
  const handleCloseErrorModal = () => {
    setErrorModalOpen(false);
  };
  const [apps, setApps] = useState([]);
  const getApps = async () => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/app/findAll?page=1&limit=9999999&sort=created_time&order=asc`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        let apps = response.data.result;
        apps = apps.filter((app, index) => app.userid === user.id);
        setApps(apps);
        if (apps.length > 0) {
          setSelectedApp(apps[0]);
        }
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenErrorModal();
      }
    } catch (error) {
      console.log(error);
      handleOpenErrorModal();
    }
  };

  useEffect(() => {
    if (open) {
      getApps();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Creating a new API-KEY" : "创建新的API-KEY"}</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel id="app-select-label">{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Affiliated to" : "归属应用"}</InputLabel>
          <Select
            labelId="app-select-label"
            value={selectedApp.id || " "}
            onChange={handleAppChange}
            sx={{ flexGrow: 1, minHeight: 40 }}
            input={<OutlinedInput label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Affiliated to" : "归属应用"} />}
            disabled={appChange}
            size="small"
          >
            {apps.length > 0 ? (
              apps.map((app) => (
                <MenuItem key={app.id} value={app.id}>
                  {app.name}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled value="">
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "No App" : "暂无应用"}
              </MenuItem>
            )}
          </Select>
        </FormControl>
        <TextField
          label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "KEY Name：" : "名称"}
          fullWidth
          margin="normal"
          value={name}
          onChange={handleNameChange}
          helperText={name ? `${name.length}/15` : "0/15"}
          placeholder={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Please enter the name (maximum 15 characters)" : "请输入名称(最长15个字)"}
          sx={{ flexGrow: 1 }}
          size="small"
          autoComplete="off"
        />
        <TextField
          label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Description" : "描述"}
          fullWidth
          margin="normal"
          value={description}
          onChange={handleDescriptionChange}
          helperText={description ? `${description.length}/30` : "0/30"}
          placeholder={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Please enter the description (maximum 30 characters)" : "请输入描述(最长30个字)"}
          sx={{ flexGrow: 1 }}
          size="small"
          autoComplete="off"
        />
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={checked} onChange={handleCheckChange} />}
            label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Status" : "启用状态"}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000",
            float: "right",
            borderColor: "#6e6e6e",
          }}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Cancel" : "取消"}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#7f6ce0",
            color: "#ffffff",
            float: "right",
          }}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Confirm" : "确定"}
        </Button>
      </DialogActions>
      <ErrorModal
        open={errorModalOpen}
        onClose={handleCloseErrorModal}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Error loading the attribute application, please try again" : "归属应用加载出错，请重试"}
        time={1000}
      />
      <ErrorModal
        open={newApiFail}
        onClose={handleCloseNewApiFail}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Failed to create a new Api-Key, please try again" : "创建新的Api-Key失败，请重试"}
        time={1000}
      />
    </Dialog>
  );
};

CreateApiKeyDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
  appChange: false,
};

CreateApiKeyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  appChange: PropTypes.bool.isRequired,
};

export default CreateApiKeyDialog;
