import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  List,
  ListItem,
  ListItemText,
  Radio,
  Box,
  Button,
  IconButton,
  Typography,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";

const SelectModelDialog = ({ open, onClose, onConfirm }) => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("modelFunctions");
  const [searchQuery, setSearchQuery] = useState("");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const handleSearch = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      let result = [];
      models.forEach((model) => {
        let name = model.name;
        if (name.includes(searchQuery)) {
          result.push(model);
        }
      });
      setShowModels(result);
    }
  };
  const modelSet = [];

  let modelFunction = [
    {
      value: "ALL",
      text: "全部模型",
      textEng: "All Models",
    },
    {
      value: "TEXT_TO_TEXT",
      text: "文字生成文字",
      textEng: "Text to Text",
    },
    {
      value: "TEXT_TO_SPEECH",
      text: "文字生成语音",
      textEng: "Text to Speech",
    },
    {
      value: "SPEECH_TO_TEXT",
      text: "语音生成文字",
      textEng: "Speech to Text",
    },
    {
      value: "TEXT_TO_IMAGE",
      text: "文字生成图片",
      textEng: "Text to Image",
    },
    {
      value: "IMAGE_TO_IMAGE",
      text: "图片生成图片",
      textEng: "Image to Image",
    },
  ];

  const [models, setModels] = useState([]);
  const [showModels, setShowModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [infoLabel, setInfoLabel] = useState("");
  const [infoText, setInfoText] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => setError(false);

  const [filter, setFilter] = useState("ALL");

  if (parseInt(process.env.REACT_APP_DEVELOP) === 0) {
    modelFunction = [
      {
        value: "TEMP_USED",
        text: "文字生成文字",
      },
    ];
  }

  const handleFilterChange = (f) => {
    setFilter(f);
    getModels(f);
  };

  const getModels = async (f) => {
    setLoading(true);
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url;
      if (!f || f === "ALL") {
        url = `${apiUrl}/model/findByShowStatus?show_status=LAUNCH&page=1&limit=9999999&sort=score&order=desc`;
      } else if (f === "TEMP_USED") {
        url = `${apiUrl}/model/findConformanceByType?model_type=TEXT_TO_TEXT&page=1&limit=9999999&sort=score&order=desc`;
      } else {
        url = `${apiUrl}/model/findByTypeAndShowStatus?model_type=${f}&show_status=LAUNCH&page=1&limit=9999999&sort=score&order=desc`;
      }
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        setModels(response.data.result);
        setShowModels(response.data.result);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Model Loading");
          setInfoText("Error getting model list");
        } else {
          setInfoLabel("模型加载");
          setInfoText("获取模型列表出错");
        }
        setError(true);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Model Loading");
        setInfoText("Error getting model list");
      } else {
        setInfoLabel("模型加载");
        setInfoText("获取模型列表出错");
      }
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (parseInt(process.env.REACT_APP_DEVELOP) === 1) {
      getModels(filter);
    } else {
      getModels("TEMP_USED");
    }
  }, []);

  const [selectedModel, setSelectedModel] = useState(null);
  const handleSelectModel = (model) => {
    setSelectedModel(model);
  };

  const handleConfirm = () => {
    if (selectedModel.model_type !== "TEXT_TO_TEXT") {
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Model Selection");
        setInfoText("Currently only supports models that generate text types of models, please reselect");
      } else {
        setInfoLabel("模型选择");
        setInfoText("当前只支持选择文字生产文字类型的模型，请重新选择");
      }
      setError(true);
      return;
    }
    if (selectedModel.status !== "AVAILABLE") {
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Model Selection");
        setInfoText("The current model is in an unavailable state, please try other available models");
      } else {
        setInfoLabel("模型选择");
        setInfoText("当前模型处于不可用状态，请尝试其他可用的模型");
      }
      setError(true);
      return;
    }
    onConfirm(selectedModel);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Choose Model" : "选择模型"}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ display: "flex", height: "50vh", padding: 0 }}>
        <Box
          sx={{
            flex: 1,
            borderRight: "1px solid #ddd",
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
          }}
        >
          <Box sx={{ position: "relative", marginBottom: "1rem" }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Search from all models" : "从全部模型里搜索"}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={handleSearch}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleSearch}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              borderBottom: "1px solid #e0e0e0",
            }}
          >
            <Button
              onClick={() => handleTabChange("modelSeries")}
              sx={{
                color: activeTab === "modelSeries" ? "#000000" : "#636363",
                flex: 1,
                borderRadius: 0,
                borderBottom: activeTab === "modelSeries" ? "2px solid #000" : "none",
                fontSize: 14,
                fontWeight: "regular",
              }}
            >
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Model Series" : "模型系列"}
            </Button>
            <Button
              onClick={() => handleTabChange("modelFunctions")}
              sx={{
                color: activeTab === "modelFunctions" ? "#000000" : "#636363",
                flex: 1,
                borderRadius: 0,
                borderBottom: activeTab === "modelFunctions" ? "2px solid #000" : "none",
                fontSize: 14,
                fontWeight: "regular",
              }}
            >
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Model Function" : "模型功能"}
            </Button>
          </Box>
          <Box sx={{ overflowY: "auto", flexGrow: 1, p: 2 }}>
            <List>
              {activeTab === "modelSeries"
                ? modelSet.map((item, index) => (
                    <ListItem key={index}>
                      <Typography>{item.text}</Typography>
                    </ListItem>
                  ))
                : modelFunction.map((item, index) => (
                    <ListItem key={index}>
                      <Button
                        onClick={() => handleFilterChange(item.value)}
                        sx={{
                          color: filter === item.value ? "#7f6ce0" : "#000000",
                          fontSize: 12,
                          fontWeight: "regular",
                        }}
                      >
                        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? item.textEng : item.text}
                      </Button>
                    </ListItem>
                  ))}
            </List>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 2,
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
          }}
        >
          {loading && <CircularProgress />}
          <List>
            {showModels.map((object, index) => (
              <ListItem
                key={object.id}
                onClick={() => handleSelectModel(object)}
                sx={{
                  cursor: "pointer",
                  borderRadius: 2,
                  border: "2px solid",
                  borderColor:
                    selectedModel && selectedModel.id === object.id ? "#7f6ce0" : "#f0f0f0",
                  bgcolor: "#ffffff",
                  "&:hover": {
                    bgcolor: "#ececec",
                  },
                  mb: "1rem",
                }}
              >
                <Box
                  component="img"
                  src={object.logo}
                  sx={{ width: "24px", height: "24px", mr: "1rem", ml: "1rem" }}
                />
                <ListItemText primary={object.name} />
                <Radio
                  checked={selectedModel?.id === object.id}
                  sx={{
                    color: "#a8a8a8",
                    "&.Mui-checked": {
                      color: "#7f6ce0",
                    },
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box sx={{ flex: 1 }}>
          <Typography>{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Selected：" : "已选："}{selectedModel ? selectedModel.name : `${process.env.REACT_APP_LANGUAGE.trim() == "en" ? "No selected" : "未选择模型"}`}</Typography>
        </Box>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            bgcolor: "#ffffff",
            color: "#000000",
            fontSize: 14,
            fontWeight: "light",
            borderColor: "#797979",
          }}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Cancel" : "取消"}
        </Button>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disabled={!selectedModel}
          sx={{
            bgcolor: "#7f6ce0",
            color: "#ffffff",
            fontSize: 14,
            fontWeight: "light",
          }}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Confirm" : "确认"}
        </Button>
      </DialogActions>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={error}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={error}>
          <Box
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 480,
              bgcolor: "#fff",
              borderRadius: "12px",
              boxShadow: 24,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "16px", color: "#333" }}
              >
                {infoLabel}
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#ccc",
                  cursor: "pointer",
                }}
              >
                ✕
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "16px",
                flex: 1,
                overflowY: "auto",
                fontSize: "14px",
                color: "#666",
              }}
            >
              {infoText}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "12px 16px",
                borderTop: "1px solid #e0e0e0",
                bgcolor: "#f5f5f5",
              }}
            >
              <Box
                sx={{
                  height: "32px",
                  lineHeight: "32px",
                  padding: "0 16px",
                  backgroundColor: "#f0f0f0",
                  border: "1px solid #d0d0d0",
                  borderRadius: "4px",
                  color: "#666",
                  fontSize: "14px",
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                确认
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </Dialog>
  );
};

SelectModelDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onConfirm: () => {},
};

SelectModelDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default SelectModelDialog;
