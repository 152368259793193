/**

*/

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, IconButton, Select, MenuItem, Button } from "@mui/material";
import axios from "axios";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CurrencyYenIcon from "@mui/icons-material/CurrencyYen";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMaterialUIController } from "context";
import { useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import ErrorModal from "examples/ErrorModal";
import LandingDialog from "examples/LandingDialog";

function MyWallet() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid, apiKeyChange } = controller;

  const [userBalance, setUserBalance] = useState(0);
  const [userFreeBalance, setUserFreeBalance] = useState(0);
  const [userTotalBalance, setUserTotalBalance] = useState(0);

  const [months, setMonths] = useState([]);
  const [outcomeMonths, setOutcomeMonths] = useState([]);
  const [incomeMonths, setIncomeMonths] = useState([]);
  const updateMonths = (newArray) => {
    console.log(newArray);
    const mergedArray = [...months, ...newArray];
    const uniqueArray = Array.from(new Map(mergedArray.map((item) => [item.key, item])).values());
    setIncomeMonths(newArray);
    setMonths(uniqueArray);
    if (selectedIndex >= uniqueArray.length) {
      setSelectedIndex(0);
    }
  };
  const handleChangeSelectedIndex = (event) => {
    setSelectedIndex(event.target.value);
    if (outcomeMonths && outcomeMonths[event.target.value]) {
      setOutcomes(outcomeMonths[event.target.value].value);
    } else {
      setOutcomes(0);
    }
    if (incomeMonths && incomeMonths[event.target.value]) {
      setIncomes(incomeMonths[event.target.value].value);
    } else {
      setIncomes(0);
    }
  };
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [outcomes, setOutcomes] = useState(0);
  const [incomes, setIncomes] = useState(0);
  const [orders, setOrders] = useState([]);

  const formatDateToYearMonth = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    return `${year}.${month}`;
  };

  const [loadFailed, setLoadFailed] = useState(false);
  const handleOpenLoadFailed = () => {
    setLoadFailed(true);
  };
  const handleCloseLoadFailed = () => {
    setLoadFailed(false);
  };

  const loadData = async () => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/fee/findByUser?userid=${user.id}&page=1&limit=9999999&sort=updated_time&order=desc`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        setOutcomeMonths(response.data.month);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenLoadFailed();
      }
    } catch (error) {
      console.log(error);
      handleOpenLoadFailed();
    }
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/recharge/findByUser?userid=${user.id}&page=1&limit=9999999&sort=updated_time&order=desc`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        updateMonths(response.data.month);
        let newOders = response.data.result;
        newOders.forEach((item) => {
          let d = new Date(item.updated_time);
          let timeString = d.toLocaleString();
          item.updated_time = timeString;
        });
        setOrders(newOders);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenLoadFailed();
      }
    } catch (error) {
      console.log(error);
      handleOpenLoadFailed();
    }
  };

  const handleGoToOrders = () => {
    navigate("/my_order");
  };

  const handleViewOrder = (order) => {
    navigate("/my_order", { state: [order] });
  };

  useEffect(() => {
    if (user) {
      setUserBalance(user.balance);
      setUserFreeBalance(user.free_balance);
      setUserTotalBalance(user.balance + user.free_balance);
      loadData();
    }
  }, [user]);

  useEffect(() => {
    if (outcomeMonths && outcomeMonths[selectedIndex]) {
      setOutcomes(outcomeMonths[selectedIndex].value);
    } else {
      setOutcomes(0);
    }
    if (incomeMonths && incomeMonths[selectedIndex]) {
      setIncomes(incomeMonths[selectedIndex].value);
    } else {
      setIncomes(0);
    }
  }, [months]);

  const [infoDialog, setInfoDialog] = useState(false);
  const handleOpenInfoDialog = () => {
    setInfoDialog(true);
  };
  const handleCloseInfoDialog = () => {
    setInfoDialog(false);
  };

  return (
    <DashboardLayout>
      <Box
        sx={{
          height: "100vh",
          backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "row",
          p: 10,
          gap: 4,
        }}
      >
        <Box
          sx={{
            flex: "1 1 45%",
            display: "flex",
            flexDirection: "column",
            gap: 3,
            height: "100%",
          }}
        >
          <Typography sx={{ fontWeight: "regular", fontSize: 16, color: "#000000" }}>
            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Account Overview" : "账户总览"}
          </Typography>
          <Box
            sx={{
              borderRadius: 5,
              backgroundColor: "#F1EFFB",
              p: 4,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              border: "1px solid #ddd",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, fontSize: 28 }}>
              <CurrencyYenIcon
                sx={{
                  color: "#7F6CE0",
                  borderRadius: "50%",
                  border: "2px solid #ddd",
                  borderColor: "#7f6ce0",
                }}
              />
              <Typography fontSize={14} color="#737373" mx={1}>
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Balance" : "可用余额"}
              </Typography>
            </Box>
            <Typography sx={{ fontWeight: "regular", color: "#000000", fontSize: 24 }}>
              ￥{userTotalBalance}
            </Typography>
            <Typography color="#737373" fontSize={14}>
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "（Top up balance" : "（充值余额："}
              <Typography component="span" color="#000000" fontSize={14}>
                ￥{userBalance}
              </Typography>
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? " + Gift balance: " : " + 赠送金："}
              <Typography component="span" color="#000000" fontSize={14}>
                ￥{userFreeBalance}
              </Typography>
              ）
            </Typography>
          </Box>
          <Typography sx={{ fontWeight: "regular", fontSize: 16, mt: 2, color: "#000000" }}>
            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Add Funds" : "充值"}
          </Typography>
          <Box
            sx={{
              borderRadius: 5,
              border: "1px solid #E0E0E0",
              p: 3,
              textAlign: "center",
              alignContent: "center",
              height: "100%",
            }}
          >
            <Typography color="#737373" fontSize={14} mb={1} fontWeight="regular">
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Kindly reach out to an XMaaS specialist for a balance top-up through the backend system." : "请联系XMaaS专员，在后台进行充值。"}
            </Typography>
            <Button
              onClick={handleOpenInfoDialog}
              variant="contained"
              sx={{
                fontWeight: "regular",
                fontSize: 14,
                color: "#FFFFFF",
                backgroundColor: "#7F6CE0",
                "&:hover": { backgroundColor: "#745ee2" },
              }}
            >
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Reach Out" : "联系专员"}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            flex: "1 1 40%",
            display: "flex",
            flexDirection: "column",
            gap: 3,
            height: "100%",
          }}
        >
          <Typography sx={{ fontWeight: "regular", fontSize: 16, color: "#000000" }}>
            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Statements" : "账单"}
          </Typography>
          <Box
            sx={{
              borderRadius: 5,
              border: "1px solid #E0E0E0",
              display: "flex",
              flexDirection: "column",
              gap: 2,
              p: 3,
              height: "100%",
              overflowY: "auto", 
            }}
          >
            <Box
              sx={{
                borderRadius: 2,
                border: "1px solid #E0E0E0",
                backgroundColor: "#F1EFFB",
                p: 3,
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 1, fontSize: 28 }}>
                <CalendarMonthIcon sx={{ color: "#7F6CE0" }} />
                <Typography color="#737373" fontSize={14} mx={2}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Billing Cycle" : "账单周期"}
                </Typography>
                <Select
                  value={selectedIndex}
                  onChange={handleChangeSelectedIndex}
                  sx={{ ml: 2, bgcolor: "#ffffff" }}
                >
                  {months.map((item, index) => (
                    <MenuItem key={item.key} value={index}>
                      {item.key}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  my: 2,
                  mx: 2,
                }}
              >
                <Box sx={{ textAlign: "center" }}>
                  <Typography color="#737373" fontSize={16}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Expenses" : "支出"}
                  </Typography>
                  <Typography color="#000000" fontSize={22}>
                    ￥{outcomes}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                  <Typography color="#737373" fontSize={16}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Incomes" : "收入"}
                  </Typography>
                  <Typography color="#000000" fontSize={22}>
                    ￥{incomes}
                  </Typography>
                </Box>
                <Button
                  variant="outlined"
                  sx={{
                    borderColor: "#C8C1F1",
                    color: "#000000",
                    bgcolor: "#ffffff",
                    borderRadius: 2,
                    fontSize: 14,
                  }}
                  onClick={handleGoToOrders}
                >
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View all" : "查看所有订单"}
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
                mt: 8,
                height: "100%",
                overflowY: "auto",
              }}
            >
              {months &&
                months[selectedIndex] &&
                orders
                  .filter(
                    (item) => formatDateToYearMonth(item.updated_time) === months[selectedIndex].key
                  )
                  .map((item) => (
                    <Box
                      key={item.id}
                      sx={{
                        borderRadius: 2,
                        border: "1px solid #E0E0E0",
                        textAlign: "center",
                        p: 3,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {/* 左部分 */}
                      <Box sx={{ textAlign: "left", flex: 1 }}>
                        <Typography color="#000000" fontSize={18} mb={2}>
                          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Add Fund" : "充值服务"}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, fontSize: 14 }}>
                          <Typography color="#000000" fontSize={14}>
                            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Order No.：" : "订单号："}{item.id}
                          </Typography>
                          <CopyToClipboard text={item.id}>
                            <ContentCopyIcon
                              sx={{ fontSize: "14px", color: "#777777", cursor: "pointer" }}
                            />
                          </CopyToClipboard>
                        </Box>
                        <Typography color="#000000" fontSize={14}>
                          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Time of pay:" : "支付时间："}{item.updated_time}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          textAlign: "right",
                          flex: 1,
                        }}
                      >
                        <Typography color="#777777" fontSize={14} mb={2}>
                          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Completed" : "已完成"}
                        </Typography>
                        <Typography color="#000000" fontSize={18} mt={1}>
                          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "CNY ￥" : "CNY ￥"}{item.amount}
                        </Typography>
                        <Typography color="#777777" fontSize={14}>
                          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Quantity: 1" : "数量：1"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          flex: 1,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          variant="outlined"
                          sx={{
                            fontSize: 14,
                            borderColor: "#7F6CE0",
                            color: "#000000",
                            ":hover": {
                              borderColor: "#5A4DB2",
                              color: "#5A4DB2",
                            },
                          }}
                          onClick={() => handleViewOrder(item)}
                        >
                          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View" : "查看"}
                        </Button>
                      </Box>
                    </Box>
                  ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <ErrorModal
        open={loadFailed}
        onClose={handleCloseLoadFailed}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Failed to load history, please try again" : "载入历史记录失败，请重试"}
        time={1000}
      />
      <LandingDialog open={infoDialog} handleClose={handleCloseInfoDialog} />
    </DashboardLayout>
  );
}

export default MyWallet;
