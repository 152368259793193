const LANGUAGE = process.env.REACT_APP_LANGUAGE.trim() || "0";

const images = {
  authpage_logo: require(`../assets/images/authpage_logo${LANGUAGE == "en" ? "_eng" : ""}.png`),
  auth_background: require(`../assets/images/auth_background.png`),
  authpage_logoset: require(`../assets/images/authpage_logoset.png`),
  dashboard_gomodeltest: require(`../assets/images/dashboard_gomodeltest${LANGUAGE == "en" ? "_eng" : ""}.png`),
  dashboard_gomodelview: require(`../assets/images/dashboard_gomodelview${LANGUAGE == "en" ? "_eng" : ""}.png`),
  dashboard_gomyapps: require(`../assets/images/dashboard_gomyapps${LANGUAGE == "en" ? "_eng" : ""}.png`),
  dashboard_myapps: require(`../assets/images/dashboard_myapps${LANGUAGE == "en" ? "_eng" : ""}.png`),
  landing_bottom: require(`../assets/images/landing_bottom${LANGUAGE == "en" ? "_eng" : ""}.png`),
  landing_m1_l1: require(`../assets/images/landing_m1_l1${LANGUAGE == "en" ? "_eng" : ""}.png`),
  landing_m1_l2: require(`../assets/images/landing_m1_l2.png`),
  landing_m1_r: require(`../assets/images/landing_m1_r${LANGUAGE == "en" ? "_eng" : ""}.png`),
  landing_m2_l1: require(`../assets/images/landing_m2_l1${LANGUAGE == "en" ? "_eng" : ""}.png`),
  landing_m2_l2: require(`../assets/images/landing_m2_l2${LANGUAGE == "en" ? "_eng" : ""}.png`),
  landing_m2_r: require(`../assets/images/landing_m2_r${LANGUAGE == "en" ? "_eng" : ""}.png`),
  landing_m3: require(`../assets/images/landing_m3${LANGUAGE == "en" ? "_eng" : ""}.png`),
  landing_m3logo1: require(`../assets/images/landing_m3logo1.png`),
  landing_m3logo2: require(`../assets/images/landing_m3logo2.png`),
  landing_m3logo3: require(`../assets/images/landing_m3logo3.png`),
  landing_m3logo4: require(`../assets/images/landing_m3logo4.png`),
  landing_m4_l: require(`../assets/images/landing_m4_l${LANGUAGE == "en" ? "_eng" : ""}.png`),
  landing_m4_r: require(`../assets/images/landing_m4_r${LANGUAGE == "en" ? "_eng" : ""}.png`),
  landing_top_left: require(`../assets/images/landing_top_left${LANGUAGE == "en" ? "_eng" : ""}.png`),
  landing_top_right: require(`../assets/images/landing_top_right.png`),
  landing_trynow: require(`../assets/images/landing_trynow${LANGUAGE == "en" ? "_eng" : ""}.png`),
  logo_mobile: require(`../assets/images/logo_mobile${LANGUAGE == "en" ? "_eng" : ""}.png`),
  logo1: require(`../assets/images/logo1.png`),
  mobile_disabled: require(`../assets/images/mobile_disabled.png`),
  model_icon: require(`../assets/images/model_icon.png`),
  ranking_cu: require(`../assets/images/ranking_cu.png`),
  ranking_gold: require(`../assets/images/ranking_gold.png`),
  ranking_silver: require(`../assets/images/ranking_silver.png`),
  user_logo: require(`../assets/images/user_logo.png`),
  wechat_pay: require(`../assets/images/wechat_pay${LANGUAGE == "en" ? "_eng" : ""}.png`),
};

export default images;
