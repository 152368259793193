import React, { useEffect } from "react";
import { Box, Typography, Modal } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import PropTypes from "prop-types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const ErrorModal = ({ open, onClose, message, time }) => {
  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        onClose();
      }, time);

      return () => clearTimeout(timer);
    }
  }, [open, onClose]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="success-modal-title"
      aria-describedby="success-modal-description"
    >
      <Box sx={style}>
        <ErrorIcon sx={{ color: "red", fontSize: 40 }} />
        <Typography ml={1}>{message}</Typography>
      </Box>
    </Modal>
  );
};

ErrorModal.defaultProps = {
  open: false,
  onClose: () => {},
  message: "",
  time: 0,
};

ErrorModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  time: PropTypes.number.isRequired,
};

export default ErrorModal;
