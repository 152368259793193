/**

*/

import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useMaterialUIController, setLayout, setUser, setSideBarMode } from "context";
import axios from "axios";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid, sideBarMode } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const personal = ["/my_wallet", "/my_order", "/acc_center"];

  const autoLogin = async () => {
    if (!localStorage.getItem("maasAuthData")) {
      navigate("/authentication/sign-in");
      return;
    }
    const authData = JSON.parse(localStorage.getItem("maasAuthData"));
    try {
      const url = process.env.REACT_APP_PASS_URL;
      let response = await axios.get(`${url}/auth/api/auto-login`, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        getUserData(authData.phone);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        console.log("自动登录未成功");
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      }
    } catch (error) {
      console.log(error);
      localStorage.clear();
      navigate("/authentication/sign-in");
      return;
    }
  };

  const getUserData = async (userid) => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/user/findByUid?userid=${encodeURIComponent(userid)}`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        setUser(dispatch, response.data);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        console.log("自动登录未成功");
        localStorage.clear();
        navigate("/authentication/sign-in");
      }
    } catch (error) {
      console.log(error);
      localStorage.clear();
      navigate("/authentication/sign-in");
    }
  };

  useEffect(() => {
    setLayout(dispatch, "dashboard");
    if (!user) {
      autoLogin();
    }
    if (personal.includes(pathname)) {
      setSideBarMode(dispatch, "personal");
    } else {
      setSideBarMode(dispatch, "console");
    }
  }, [pathname]);

  return (
    <Box
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        position: "relative",
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(50) : pxToRem(250),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      {children}
    </Box>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
