/**

*/
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Button, Box, Typography } from "@mui/material";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import { isValidPhoneNumber } from "libphonenumber-js";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import { useMaterialUIController, setUser, setRegis, setTypeAllow } from "context";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import UserAgreement from "../components/UserAgreement";
import PrivacyPolicy from "../components/PrivacyPolicy";
import ReportIssue from "../components/Report";

function Basic() {
  const url = process.env.REACT_APP_PASS_URL;

  const [controller, dispatch] = useMaterialUIController();
  const { user, appid, regis, typeAllow } = controller;

  const [userAgreementOpen, setUserAgreementOpen] = useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const handleUserAgreementOpen = () => {
    setUserAgreementOpen(true);
  };
  const handleUserAgreementClose = () => {
    setUserAgreementOpen(false);
  };
  const handlePrivacyPolicyOpen = () => {
    setPrivacyPolicyOpen(true);
  };
  const handlePrivacyPolicyClose = () => {
    setPrivacyPolicyOpen(false);
  };
  const handleReportOpen = () => {
    setReportOpen(true);
  };
  const handleReportClose = () => {
    setReportOpen(false);
  };

  const labelName = {
    0: "请输入手机号",
    1: "手机号有效",
    2: "手机号无效",
  };

  const labelNameEng = {
    0: "Please enter your phone number",
    1: "Phone number valid",
    2: "Phone number invalid",
  };

  const emailLabel = {
    0: "请输入邮箱",
    1: "邮箱有效",
    2: "邮箱无效",
  };

  const emailLabelEng = {
    0: "Please enter your email",
    1: "Email valid",
    2: "Email invalid",
  };

  const phoneAreas = [
    { countrycode: "CN", value: "+86", text: "China (+86)" },
    { countrycode: "US", value: "+1", text: "USA (+1)" },
    { countrycode: "JP", value: "+81", text: "Japan (+81)" },
  ];

  const [type, setType] = useState("phone");
  const [selectedArea, setSelectedArea] = useState(0);
  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState(0);
  const [sms, setSms] = useState("");
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(0);
  const [emailCode, setEmailCode] = useState("");

  const handleAreaChange = (event) => {
    setSelectedArea(parseInt(event.target.value));
    let area = phoneAreas[parseInt(event.target.value)].countrycode;
    let phoneNum = phone;
    validatePhone(area, phoneNum);
  };

  const handlePhoneChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    setPhone(numericValue);
    let phoneNum = numericValue;
    let area = phoneAreas[selectedArea].countrycode;
    validatePhone(area, phoneNum);
  };

  const handleSmsChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    setSms(numericValue);
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    validateEmail(value);
  };

  const handleEmailCodeChange = (event) => {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    setEmailCode(numericValue);
  };

  const validatePhone = (area, phoneNum) => {
    let isValid = 0;
    if (area === "CN") {
      const cnsRegex = /^1[0-9]{10}$/;
      if (phoneNum.match(cnsRegex)) {
        isValid = 1;
      } else {
        isValid = 2;
      }
    } else {
      if (isValidPhoneNumber(phoneNum, area)) {
        isValid = 1;
      } else {
        isValid = 2;
      }
    }
    if (phoneNum.length === 0) {
      isValid = 0;
    }
    setPhoneValid(isValid);
  };

  const validateEmail = (email) => {
    let isValid = 0;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      isValid = 1;
    } else {
      isValid = 2;
    }
    if (email.length === 0) {
      isValid = 0;
    }
    setEmailValid(isValid);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [infoLabel, setInfoLabel] = useState("");
  const [infoText, setInfoText] = useState("");
  const [loginVId, setLoginVId] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [smsSended, setSmsSended] = useState(false);
  const [smsCooldown, setCoolDown] = useState(0);
  const [emailSended, setEmailSended] = useState(false);
  const [emailCooldown, setEmailCoolDown] = useState(0);

  const handleClose = () => setError(false);

  const getSmsCode = async () => {
    if (
      localStorage.getItem(phoneAreas[selectedArea].value + phone + "Login") &&
      Date.parse(localStorage.getItem(phoneAreas[selectedArea].value + phone + "Login")) >
        new Date()
    ) {
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("The phone number is still in 60 seconds cooldown");
      } else {
        setInfoLabel("错误");
        setInfoText("当前手机号还在60秒冷却内");
      }
      setError(true);
      return;
    } else {
      localStorage.clear();
    }
    setLoading(true);
    try {
      let response = await axios.post(
        `${url}/auth/api/check-phone`,
        {
          phone: phoneAreas[selectedArea].value + phone,
          appid: appid,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.data.registered) {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Error");
          setInfoText("Your phone number is not registered, please go to register");
        } else {
          setInfoLabel("获取短信结果");
          setInfoText("您的手机号还未注册，请前往注册");
        }
        setError(true);
      } else {
        response = await axios.post(
          `${url}/auth/api/send-sms`,
          {
            phone: phoneAreas[selectedArea].value + phone,
            appid: appid,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        setLoginVId(response.data.verification_id);
        setSmsSended(true);
        setCoolDown(60);
        let currentTime = new Date();
        let cooldownTime = new Date(currentTime.getTime() + 60000);
        localStorage.setItem(phoneAreas[selectedArea].value + phone + "Login", cooldownTime);
        let timer = setInterval(() => {
          setCoolDown((prevCooldown) => {
            if (prevCooldown === 1) {
              clearInterval(timer);
              setSmsSended(false);
              return 0;
            } else {
              return prevCooldown - 1;
            }
          });
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Phone number verification error");
      } else {
        setInfoLabel("获取短信结果");
        setInfoText("手机号校验出错");
      }
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const getEmailCode = async () => {
    if (
      localStorage.getItem(email + "Login") &&
      Date.parse(localStorage.getItem(email + "Login")) > new Date()
    ) {
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("The email is still in 60 seconds cooldown");
      } else {
        setInfoLabel("错误");
        setInfoText("当前邮箱还在60秒冷却内");
      }
      setError(true);
      return;
    } else {
      localStorage.clear();
    }
    setLoading(true);
    try {
      let response = await axios.post(
        `${url}/auth/api/check-phone`,
        {
          email: email,
          appid: appid,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      if (!response.data.registered) {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Error");
          setInfoText("Your email is not registered, please go to register");
        } else {
          setInfoLabel("获取邮件结果");
          setInfoText("您的邮箱还未注册，请前往注册");
        }
        setError(true);
      } else {
        response = await axios.post(
          `${url}/auth/api/send-sms`,
          {
            email: email,
            appid: appid,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        setLoginVId(response.data.verification_id);
        setEmailSended(true);
        setEmailCoolDown(60);
        let currentTime = new Date();
        let cooldownTime = new Date(currentTime.getTime() + 60000);
        localStorage.setItem(email + "Login", cooldownTime);
        let timer = setInterval(() => {
          setEmailCoolDown((prevCooldown) => {
            if (prevCooldown === 1) {
              clearInterval(timer);
              setEmailSended(false);
              return 0;
            } else {
              return prevCooldown - 1;
            }
          });
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Email verification error");
      } else {
        setInfoLabel("获取邮件结果");
        setInfoText("邮箱校验出错");
      }
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  let getSmsText = "";
  let getEmailText = "";
  if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
    if (smsSended) {
      getSmsText = `${smsCooldown} seconds left`;
    } else {
      getSmsText = "Get code";
    }
    if (emailSended) {
      getEmailText = `${emailCooldown} seconds left`;
    } else {
      getEmailText = "Get code";
    }
  } else {
    if (smsSended) {
      getSmsText = `${smsCooldown}秒后重新获取`;
    } else {
      getSmsText = "获取验证码";
    }
    if (emailSended) {
      getEmailText = `${emailCooldown}秒后重新获取`;
    } else {
      getEmailText = "获取验证码";
    }
  }

  const navigate = useNavigate();

  const login = async () => {
    if (type === "phone") {
      loginSms();
    } else {
      loginEmail();
    }
  };

  const loginSms = async () => {
    if (!phoneValid) {
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Please enter a valid phone number");
      } else {
        setInfoLabel("登录结果");
        setInfoText("请填写有效的手机号");
      }
      setError(true);
      return;
    }
    setLoading(true);
    try {
      let response = await axios.post(
        `${url}/auth/api/verify-sms`,
        {
          phone: phoneAreas[selectedArea].value + phone,
          code: sms,
          verification_id: loginVId,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.status === 200) {
        response = await axios.post(
          `${url}/auth/api/login`,
          {
            identifier: phoneAreas[selectedArea].value + phone,
            login_type: "",
            appid: appid,
            password: sms,
            verification_id: loginVId,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        if (response.status === 200 || response.status === 201) {
          localStorage.setItem(
            "maasAuthData",
            JSON.stringify({
              token: response.data.token,
              phone: phoneAreas[selectedArea].value + phone,
            })
          );
          getUserData(phoneAreas[selectedArea].value + phone);
        } else {
          if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
            setInfoLabel("Error");
            setInfoText("Login failed, please try again");
          } else {
            setInfoLabel("登录结果");
            setInfoText("登录失败，请重试");
          }
          setError(true);
          setLoading(false);
        }
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Error");
          setInfoText("Verification code error");
        } else {
          setInfoLabel("登录结果");
          setInfoText("验证码错误");
        }
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Login failed, please try again");
      } else {
        setInfoLabel("登录结果");
        setInfoText("登录失败，请重试");
      }
      setError(true);
      setLoading(false);
    }
  };

  const loginEmail = async () => {
    if (!emailValid) {
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Please enter a valid email");
      } else {
        setInfoLabel("登录结果");
        setInfoText("请填写有效的邮箱");
      }
      setError(true);
      return;
    }
    setLoading(true);
    try {
      let response = await axios.post(
        `${url}/auth/api/verify-sms`,
        {
          email: email,
          code: emailCode,
          verification_id: loginVId,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      if (response.status === 200) {
        response = await axios.post(
          `${url}/auth/api/login`,
          {
            identifier: email,
            login_type: "",
            appid: appid,
            password: emailCode,
            verification_id: loginVId,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        if (response.status === 200 || response.status === 201) {
          localStorage.setItem(
            "maasAuthData",
            JSON.stringify({
              token: response.data.token,
              email: email,
            })
          );
          getUserData(email);
        } else {
          if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
            setInfoLabel("Error");
            setInfoText("Login failed, please try again");
          } else {
            setInfoLabel("登录结果");
            setInfoText("登录失败，请重试");
          }
          setError(true);
          setLoading(false);
        }
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Error");
          setInfoText("Verification code error");
        } else {
          setInfoLabel("登录结果");
          setInfoText("验证码错误");
        }
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Login failed, please try again");
      } else {
        setInfoLabel("登录结果");
        setInfoText("登录失败，请重试");
      }
      setError(true);
      setLoading(false);
    }
  };

  const autoLogin = async () => {
    if (!localStorage.getItem("maasAuthData")) {
      console.log("No token found, please log in");
      return;
    }
    const authData = JSON.parse(localStorage.getItem("maasAuthData"));
    setLoading(true);
    try {
      let response = await axios.get(`${url}/auth/api/auto-login`, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        if (authData.phone) {
          getUserData(authData.phone);
        } else {
          getUserData(authData.email);
        }
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        console.log("自动登录未成功");
        localStorage.clear();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Error during auto-login");
      } else {
        setInfoLabel("登录结果");
        setInfoText("自动登录时出错");
      }
      setError(true);
      localStorage.clear();
      setLoading(false);
    }
  };

  const getUserData = async (userid) => {
    setLoading(true);
    if (!localStorage.getItem("maasAuthData")) {
      navigate("/authentication/sign-in");
      return;
    }
    const authData = JSON.parse(localStorage.getItem("maasAuthData"));
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/user/findByUid?userid=${encodeURIComponent(userid)}`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Login result");
          setInfoText("Login successful");
        } else {
          setInfoLabel("登录结果");
          setInfoText("登录成功");
        }
        setError(true);
        setUser(dispatch, response.data);
        setTimeout(() => {
          setError(false);
          navigate("/dashboard");
        }, 1000);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Error");
          setInfoText("Failed to get user data, please refresh and try again");
        } else {
          setInfoLabel("登录结果");
          setInfoText("请求用户数据失败，请刷新网页重试");
        }
        setError(true);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Failed to get user data, please refresh and try again");
      } else {
        setInfoLabel("登录结果");
        setInfoText("请求用户数据失败，请刷新网页重试");
      }
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const loadRegis = async () => {
    try {
      let url = `${process.env.REACT_APP_PASS_URL}/auth/api/app/${process.env.REACT_APP_APP_ID}`;
      let response = await axios.get(url, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        setRegis(dispatch, response.data.registration_switch);
        setTypeAllow(dispatch, response.data.system_type);
        setType(response.data.system_type[0]);
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Error");
          setInfoText("Failed to load registration switch, please refresh and try again");
        } else {
          setInfoLabel("载入注册开关");
          setInfoText("注册开关载入失败，请刷新页面重试");
        }
        setError(true);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Failed to load registration switch, please refresh and try again");
      } else {
        setInfoLabel("载入注册开关");
        setInfoText("注册开关载入失败，请刷新页面重试");
      }
      setError(true);
    }
  };

  useEffect(() => {
    loadRegis();
    autoLogin();
  }, []);

  return (
    <BasicLayout>
      <Card sx={{ minWidth: 421, minHeight: 519, border: "1px solid #000000", borderRadius: 0 }}>
        <Box mt={2} p={2} mb={1} textAlign="center">
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              {typeAllow.includes("phone") && (
                <Button
                  sx={{
                    fontSize: 18,
                    color: type === "phone" ? "#7f6ce0" : "#000000",
                    flex: 1,
                    borderRadius: 0,
                    borderBottom: type === "phone" ? "2px solid" : "0px",
                    borderColor: type === "phone" ? "#7f6ce0" : "#000000",
                  }}
                  onClick={() => setType("phone")}
                >
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Log in by phone" : "手机号登录"}
                </Button>
              )}
            </Grid>
            <Grid item>
              {typeAllow.includes("email") && (
                <Button
                  sx={{
                    fontSize: 18,
                    color: type === "email" ? "#7f6ce0" : "#000000",
                    flex: 1,
                    borderRadius: 0,
                    borderBottom: type === "email" ? "2px solid" : "0px",
                    borderColor: type === "email" ? "#7f6ce0" : "#000000",
                  }}
                  onClick={() => setType("email")}
                >
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Log in by email" : "邮箱登录"}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box pt={2} pb={2} px={3}>
          <Box component="form" role="form" id="form">
            {type === "phone" ? (
              <Box mb={2} sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                  id="signin_area"
                  select
                  label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Area Code" : "区号"}
                  value={selectedArea}
                  onChange={handleAreaChange}
                  variant="standard"
                  sx={{
                    label: {
                      fontSize: "small",
                    },
                    ".MuiInput-root": {
                      paddingBottom: "0px",
                    },
                  }}
                >
                  {phoneAreas.map((option, index) => (
                    <MenuItem key={index} value={index} sx={{ fontSize: "small" }}>
                      {option.text}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  error={phoneValid === 2}
                  id="signin_phone"
                  label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? labelNameEng[phoneValid] : labelName[phoneValid]}
                  variant="standard"
                  type="tel"
                  size="small"
                  sx={{ flexGrow: 1 }}
                  inputProps={{
                    pattern: "\\d*",
                    inputMode: "numeric",
                    maxLength: 11,
                  }}
                  value={phone}
                  onChange={handlePhoneChange}
                  autoComplete="off"
                />
              </Box>
            ) : (
              <Box mb={2} sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                  required
                  error={emailValid === 2}
                  id="signin_email"
                  label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? emailLabelEng[emailValid] : emailLabel[emailValid]}
                  variant="standard"
                  type="email"
                  size="small"
                  sx={{ flexGrow: 1 }}
                  value={email}
                  onChange={handleEmailChange}
                  autoComplete="off"
                />
              </Box>
            )}
            {type === "phone" ? (
              <Box mb={4} sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                  required
                  id="signin_sms"
                  label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Verification code" : "请输入验证码"}
                  variant="standard"
                  type="tel"
                  sx={{
                    flexGrow: 1,
                    ".MuiInput-root": {
                      paddingBottom: "3px",
                    },
                  }}
                  size="small"
                  inputProps={{
                    pattern: "\\d*",
                    inputMode: "numeric",
                    maxLength: 6,
                  }}
                  value={sms}
                  onChange={handleSmsChange}
                  autoComplete="off"
                />
                <Button
                  variant="text"
                  color="secondary"
                  disabled={phoneValid !== 1 || smsSended}
                  onClick={getSmsCode}
                  sx={{
                    borderRadius: 0,
                    borderBottom: "1px solid",
                    borderColor: "#cccccc",
                  }}
                >
                  {getSmsText}
                </Button>
              </Box>
            ) : (
              <Box mb={4} sx={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                  required
                  id="signin_email_code"
                  label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Verification code" : "请输入验证码"}
                  variant="standard"
                  type="tel"
                  sx={{ flexGrow: 1 }}
                  size="small"
                  inputProps={{
                    pattern: "\\d*",
                    inputMode: "numeric",
                    maxLength: 6,
                  }}
                  value={emailCode}
                  onChange={handleEmailCodeChange}
                  autoComplete="off"
                />
                <Button
                  variant="text"
                  color="secondary"
                  disabled={emailValid !== 1 || emailSended}
                  onClick={getEmailCode}
                  sx={{
                    borderRadius: 0,
                    borderBottom: "1px solid",
                    borderColor: "#cccccc",
                  }}
                >
                  {getEmailText}
                </Button>
              </Box>
            )}
            <Box mb={8} sx={{ display: "flex", alignItems: "flex-end" }}>
              <Typography fontSize={14}>
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "No account yet？" : "还没有账号？"}
              </Typography>
              <Typography
                fontSize={14}
                color="#7f6ce0"
                sx={{ cursor: "pointer" }}
                component={NavLink}
                to="/authentication/sign-up"
              >
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Register" : "注册"}
              </Typography>
            </Box>
            <Box mb={1}>
              <Button
                fullWidth
                onClick={login}
                sx={{
                  fontSize: 14,
                  color: "#ffffff",
                  backgroundColor: "#7f6ce0",
                  "&:hover": {
                    color: "#ffffff",
                    bgcolor: "#8037df",
                  },
                }}
              >
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Login" : "登录"}
              </Button>
            </Box>
            {loading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                }}
              >
                <CircularProgress
                  sx={(theme) => ({
                    color: "#7f6ce0",
                    animationDuration: "550ms",
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                    ...theme.applyStyles("dark", {
                      color: "#7f6ce0",
                    }),
                  })}
                />
              </Box>
            )}
            <Box mt={3} mb={1} textAlign="center">
              <Typography variant="caption" color="text">
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "By logging in, you agree to the " : "登录视为您已同意"}
                <Typography
                  variant="caption"
                  component="span"
                  color="#7F6CE0"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={handleUserAgreementOpen}
                >
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "User Agreement" : "用户协议"}
                </Typography>
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? ", " : "、"}
                <Typography
                  variant="caption"
                  component="span"
                  color="#7F6CE0"
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={handlePrivacyPolicyOpen}
                >
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Privacy Policy" : "隐私协议"}
                </Typography>
              </Typography>
            </Box>
            {/* <Box mt={2} mb={1} textAlign="center">
              <Typography
                variant="caption"
                component="span"
                color="#adadad"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={handleReportOpen}
              >
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Report and Feedback" : "投诉与反馈"}
              </Typography>
            </Box> */}
          </Box>
        </Box>
      </Card>
      <UserAgreement open={userAgreementOpen} onClose={handleUserAgreementClose} />
      <PrivacyPolicy open={privacyPolicyOpen} onClose={handlePrivacyPolicyClose} />
      <ReportIssue open={reportOpen} onClose={handleReportClose} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={error}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={error}>
          <Box
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 480,
              bgcolor: "#fff",
              borderRadius: "12px",
              boxShadow: 24,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "16px", color: "#333" }}
              >
                {infoLabel}
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#ccc",
                  cursor: "pointer",
                }}
              >
                ✕
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "16px",
                flex: 1,
                overflowY: "auto",
                fontSize: "14px",
                color: "#666",
              }}
            >
              {infoText}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "12px 16px",
                borderTop: "1px solid #e0e0e0",
                bgcolor: "#f5f5f5",
              }}
            >
              <Box
                sx={{
                  height: "32px",
                  lineHeight: "32px",
                  padding: "0 16px",
                  backgroundColor: "#f0f0f0",
                  border: "1px solid #d0d0d0",
                  borderRadius: "4px",
                  color: "#666",
                  fontSize: "14px",
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                确认
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </BasicLayout>
  );
}

export default Basic;
