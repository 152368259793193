import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Button,
  Box,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import images from "config/imageConfig";

const LandingDialog = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const closeDialog = () => {
    handleClose();
  };

  return (
    <Dialog open={open} onClose={closeDialog} width="454px">
      <DialogTitle>
        <Typography ml={1}>
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Contact Us" : "联系我们"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          component="img"
          src={images.wechat_pay}
          alt="wechat_pay"
          sx={{
            width: "454px",
            height: "auto",
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

LandingDialog.defaultProps = {
  open: false,
  handleClose: () => {},
};

LandingDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default LandingDialog;
