/**

*/
import { useEffect, useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import images from "config/imageConfig";
import KeyIcon from "@mui/icons-material/Key";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Box, Button } from "@mui/material";
import { Typography } from "@mui/material";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import { useMaterialUIController, setMiniSidenav } from "context";
import { setUser, setSideBarMode } from "context";
import ApiKeyDialog from "examples/APIDialog";
import UserProfileDialog from "examples/UserDialog";
import RechargeDialog from "examples/UserDialog/ChargeDialog";
import axios from "axios";

function Sidenav({ color, brand, brandName, brandInfo, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, user, sideBarMode } = controller;
  const location = useLocation();
  let collapseName = location.pathname.replace("/", "");
  collapseName = collapseName.split("/")[0];

  const backConsole = () => {
    navigate("/dashboard");
  };

  const [menu, setMenu] = useState(null);
  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const navigate = useNavigate();
  const logout = () => {
    setUser(dispatch, undefined);
    localStorage.clear();
    navigate("/authentication/sign-in");
  };

  const [editUser, setEditUser] = useState(false);
  const handleOpenEditUser = async () => {
    // await getUserData(user.userid);
    // setEditUser(true);
    navigate("/my_wallet");
  };
  const handleCloseOpenUser = () => {
    setEditUser(false);
  };
  const handleSubmitUser = (newUser) => {
    setUser(dispatch, newUser);
  };

  const [chargeUser, setChargeUser] = useState(false);
  const handleOpenCharge = () => {
    handleCloseOpenUser();
    setChargeUser(true);
  };
  const handleCloseCharge = () => {
    setChargeUser(false);
  };
  const handleChargeBack = () => {
    handleCloseCharge();
    handleOpenEditUser();
  };

  const getUserData = async (userid) => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/user/findByUid?userid=${encodeURIComponent(userid)}`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        setUser(dispatch, response.data);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        console.log("自动登录未成功");
        localStorage.clear();
        navigate("/authentication/sign-in");
      }
    } catch (error) {
      console.log(error);
      localStorage.clear();
      navigate("/authentication/sign-in");
    }
  };

  const renderMenu = (
    <Menu
      id="auth-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={handleOpenEditUser}>
        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "User Center" : "个人中心"}
      </MenuItem>
      <MenuItem onClick={logout}>
        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Log Out" : "退出登录"}
      </MenuItem>
    </Menu>
  );

  let textColor = "dark";

  const [apiDialogOpen, setApiDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setApiDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setApiDialogOpen(false);
  };

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }
    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  const renderRoutes = routes.map(
    ({ type, name, nameEng, icon, title, titleEng, key, href, route, mustShow, belong }) => {
      let returnValue;
      if (sideBarMode === belong) {
        if (type === "collapse" && mustShow) {
          returnValue = href ? (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse name={process.env.REACT_APP_LANGUAGE.trim() == "en" ? nameEng : name} icon={icon} active={key === collapseName} />
            </Link>
          ) : (
            <NavLink key={key} to={route}>
              <SidenavCollapse name={process.env.REACT_APP_LANGUAGE.trim() == "en" ? nameEng : name} icon={icon} active={key === collapseName} />
            </NavLink>
          );
        } else if (type === "title" && mustShow) {
          returnValue = (
            <Typography
              key={key}
              color={textColor}
              display="block"
              variant="caption"
              fontSize={14}
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? titleEng : title}
            </Typography>
          );
        } else if (type === "divider" && mustShow) {
          returnValue = <Divider key={key} />;
        } else if (type === "button" && mustShow) {
          returnValue = (
            <Button
              key={key}
              variant="outlined"
              sx={{
                ml: 7,
                color: "#7f6ce0",
                fontSize: 14,
                fontWeight: "regular",
                borderColor: "#7f6ce0",
                "&:hover": {
                  color: "#6C83E0",
                  borderColor: "#6C83E0",
                },
              }}
              onClick={backConsole}
            >
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? nameEng : name}
            </Button>
          );
        }
      }
      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <Box pt={3} pb={1} px={4} textAlign="center">
        <Box
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <Typography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </Typography>
        </Box>
        <Box component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <Box component="img" src={brand} alt="Brand" width="100%" />}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            sx={(theme) => ({
              ...sidenavLogoLabel(theme, { miniSidenav }),
              width: !brandInfo && "100%",
              textAlign: "center",
            })}
          >
          </Box>
        </Box>
      </Box>
      <Divider />
      <List>{renderRoutes}</List>
      <Box p={2} mt="auto">
        <Divider />
        <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }} onClick={openMenu}>
          <Box
            component="img"
            sx={{
              height: 25,
              width: 25,
            }}
            src={images.user_logo}
          />
          <Typography
            px={2}
            component="h6"
            variant="h5"
            fontWeight="regular"
            color={textColor}
            fontSize={14}
          >
            {user ? user.name : ""}
          </Typography>
        </Box>
        {renderMenu}
        <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }} pt={1}>
          <KeyIcon fontSize="small">KeyIcon</KeyIcon>
          <Typography
            px={2}
            component="h6"
            variant="body2"
            fontWeight="regular"
            fontSize={12}
            color={textColor}
            onClick={handleOpenDialog}
          >
            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View my API KEYs" : "查看我的API KEY"}
          </Typography>
        </Box>
      </Box>
      <ApiKeyDialog open={apiDialogOpen} onClose={handleCloseDialog} />
      {user && (
        <UserProfileDialog
          open={editUser}
          handleClose={handleCloseOpenUser}
          user={user}
          handleSubmit={handleSubmitUser}
          handleOpenCharge={handleOpenCharge}
        />
      )}
      {user && (
        <RechargeDialog
          open={chargeUser}
          handleClose={handleCloseCharge}
          user={user}
          handleBack={handleChargeBack}
        />
      )}
    </SidenavRoot>
  );
}

Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  brandInfo: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
