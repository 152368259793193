/**

*/
import { useState, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

function AppsView() {
  return (
    <DashboardLayout>
      <Box py={1}>{"暂无内容"}</Box>
    </DashboardLayout>
  );
}

export default AppsView;
