import React from "react";
import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import ErrorModal from "examples/ErrorModal";
import { useNavigate } from "react-router-dom";

const ModelDetail = ({ open, onClose, user, model, onTry, onSample }) => {
  const navigate = useNavigate();
  const handleOpenSample = () => {
    onClose();
    onSample(model);
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [recent30, setRecent30] = useState(0);
  const [recent7, setRecent7] = useState(0);

  // const [tokenInfo, setTokenInfo] = useState([-1, -1]);
  const getTokenData = async () => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/token_used/getAmtByUserAndModel?userid=${encodeURIComponent(
        user.id
      )}&model_id=${encodeURIComponent(model.id)}&time_range=${30}`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        setRecent30(response.data);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenModal();
      }
    } catch (error) {
      console.log(error);
      handleOpenModal();
    }
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/token_used/getAmtByUserAndModel?userid=${encodeURIComponent(
        user.id
      )}&model_id=${encodeURIComponent(model.id)}&time_range=${7}`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        setRecent7(response.data);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        handleOpenModal();
      }
    } catch (error) {
      console.log(error);
      handleOpenModal();
    }
  };

  useEffect(() => {
    if (open && model && user) {
      getTokenData();
    }
  }, [model]);

  if (!open || !model || !user) {
    return null;
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle bgcolor="#f3f3f3">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" ml={2}>
            {model.name}
          </Typography>
          <IconButton edge="end" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box mb={2}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Introduction" : "模型简介"}
          </Typography>
          <Typography variant="body2" mt={1} color="#000000">
            {model.text_summary}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Name of Model" : "模型名称"}
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <Typography variant="body2" mr={2} color="#000000">
              {model.name}
            </Typography>
            <CopyToClipboard text={model.name}>
              <ContentCopyIcon fontSize="small" sx={{ cursor: "pointer" }} />
            </CopyToClipboard>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Pricing Details" : "计费详情"}
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <ShoppingCartIcon sx={{ marginRight: 1 }} />
            <Typography variant="body2" component="span" color="#000000">
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Input" : "模型调用-输入"} &nbsp;
            </Typography>
            <Typography
              variant="body2"
              component="span"
              sx={{ color: "#7f6ce0", fontWeight: "bold" }}
            >
              {Number((model.token_price_input * 1000000).toFixed(3)).toString()}
            </Typography>
            <Typography variant="body2" component="span" color="#000000">
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "/1M token" : "元/M token"}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1}>
            <ShoppingCartIcon sx={{ marginRight: 1 }} />
            <Typography variant="body2" component="span" color="#000000">
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Output" : "模型调用-输出"} &nbsp;
            </Typography>
            <Typography
              variant="body2"
              component="span"
              sx={{ color: "#7f6ce0", fontWeight: "bold" }}
            >
              {Number((model.token_price_output * 1000000).toFixed(3)).toString()}
            </Typography>
            <Typography variant="body2" component="span" color="#000000">
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "/1M token" : "元/M token"}
            </Typography>
          </Box>
        </Box>
        <Box my={2}>
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Usage in 1 month" : "近一个月使用情况"}
          </Typography>
          <Box display="flex" alignItems="center" mt={1}>
            <ShoppingCartIcon sx={{ marginRight: 1 }} />
            <Typography variant="body2" component="span" color="#000000">
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Last 30 days：" : "最近30天："}
            </Typography>
            <Typography
              variant="body2"
              component="span"
              sx={{ color: "#000000", fontWeight: "bold" }}
            >
              {recent30}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={1}>
            <ShoppingCartIcon sx={{ marginRight: 1 }} />
            <Typography variant="body2" component="span" color="#000000">
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Last 7 days：" : "最近7天："}
            </Typography>
            <Typography
              variant="body2"
              component="span"
              sx={{ color: "#000000", fontWeight: "bold" }}
            >
              {recent7}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" p={2} bgcolor="#f3f3f3">
        <Button
          variant="outlined"
          sx={{
            mr: 2,
            color: "#000000",
            fontSize: 14,
            fontWeight: "regular",
            borderColor: "#4d4d4d",
          }}
          onClick={() => onTry(model)}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Try Model" : "体验"}
        </Button>
        <Button
          variant="outlined"
          sx={{
            mr: 2,
            color: "#000000",
            fontSize: 14,
            fontWeight: "regular",
            borderColor: "#4d4d4d",
          }}
          onClick={handleOpenSample}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "API Example" : "API示例"}
        </Button>
      </Box>
      <ErrorModal
        open={modalOpen}
        onClose={handleCloseModal}
        message={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Token usage loading failed, please try again" : "Token使用情况载入失败，请重试"}
        time={1000}
      />
    </Dialog>
  );
};

ModelDetail.defaultProps = {
  open: false,
  onClose: () => {},
  model: {},
  onTry: () => {},
  onSample: () => {},
  user: {},
};

ModelDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  model: PropTypes.object,
  user: PropTypes.object,
  onTry: PropTypes.func.isRequired,
  onSample: PropTypes.func.isRequired,
};

export default ModelDetail;
