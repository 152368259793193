import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Box,
  Typography,
} from "@mui/material";

const EditAppDialog = ({ open, onClose, onSubmit, app }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    if (event.target.value.length <= 100) {
      setDescription(event.target.value);
    }
  };

  const handleSubmit = () => {
    if (name) {
      let descr = description;
      if (!descr) {
        descr = " ";
      }
      app.name = name;
      app.description = descr;
      onSubmit(app);
      setName("");
      setDescription("");
    }
  };

  useEffect(() => {
    if (app) {
      setName(app.name);
      setDescription(app.description);
    }
  }, [app]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Edit App" : "编辑App信息"}</DialogTitle>
      <DialogContent>
        <TextField
          label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "App Name" : "应用名称"}
          fullWidth
          margin="normal"
          value={name}
          onChange={handleNameChange}
          placeholder={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Please enter app name" : "请输入名称"}
          sx={{ flexGrow: 1 }}
          size="small"
          autoComplete="off"
        />
        <TextField
          label={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Description" : "描述"}
          multiline
          fullWidth
          margin="normal"
          value={description}
          onChange={handleDescriptionChange}
          helperText={description ? `${description.length}/100` : "0/100"}
          placeholder={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Please enter description" : "请输入描述"}
          sx={{ flexGrow: 1 }}
          size="small"
          autoComplete="off"
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            backgroundColor: "#ffffff",
            color: "#000000",
            float: "right",
            borderColor: "#6e6e6e",
          }}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Cancel" : "取消"}
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: "#7f6ce0",
            color: "#ffffff",
            float: "right",
          }}
        >
          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Confirm" : "确定"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditAppDialog.defaultProps = {
  open: false,
  onClose: () => {},
  onSubmit: () => {},
  app: {},
};

EditAppDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  app: PropTypes.object.isRequired,
};

export default EditAppDialog;
