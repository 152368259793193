import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import PropTypes from "prop-types";
import TypingEffect from "components/typing";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const MessageBox = ({ message, scrollContainerRef }) => {
  const isUser = message.role === "user";
  const backgroundColor = isUser ? "#c8c8ee" : "#ffffff";

  useEffect(() => {
    if (scrollContainerRef?.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [message, scrollContainerRef]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        {isUser ? (
          <AccountCircleIcon fontSize="large" sx={{ height: "30px", width: "30px" }} />
        ) : (
          <Box component="img" src={message.model.logo} height="30px" width="30px" />
        )}
        <Typography flex="1" color="textSecondary" sx={{ ml: 1  , fontSize: 12 }}>
          {message.model.name}
        </Typography>
        <Typography color="textSecondary" sx={{ ml: 2, fontSize: 12 }}>
          {message.time}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 1,
          backgroundColor: backgroundColor,
          p: 1.5,
          borderRadius: "8px",
          maxWidth: "100%",
          position: "relative",
          fontSize: 14,
          lineHeight: 1.6,
          overflowWrap: "break-word",
          wordBreak: "break-word",
        }}
      >
        <Box
          sx={{
            "& ul, & ol": {
              paddingLeft: "24px",
              listStylePosition: "outside",
            },
            "& li": {
              marginBottom: "4px",
            },
            "& pre": {
              backgroundColor: "#f5f5f5",
              padding: "8px",
              borderRadius: "6px",
              overflowX: "auto",
              fontSize: "13px",
              lineHeight: 1.5,
              fontFamily: "monospace",
              margin: 0,
            },
            "& code": {
              padding: 0,
              backgroundColor: "transparent",
            },
            "& p": {
              margin: "4px 0",
            },
          }}
        >
          <ReactMarkdown remarkPlugins={[remarkGfm]}>{message.content}</ReactMarkdown>
        </Box>
        {!isUser && (
          <Typography
            sx={{
              fontSize: 12,
              color: "gray",
              position: "absolute",
              bottom: 4,
              right: 8,
            }}
          >
            {process.env.REACT_APP_LANGUAGE.trim() === "en" ? "Generated by AI, for reference only" : "由AI生成, 仅供参考"}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

MessageBox.defaultProps = {
  message: {},
  scrollContainerRef: {},
};

MessageBox.propTypes = {
  message: PropTypes.object.isRequired,
  scrollContainerRef: PropTypes.object.isRequired,
};

export default MessageBox;
