/**

*/
import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Box, IconButton, Typography, Button, TextField } from "@mui/material";
import { Grid, CircularProgress } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/Info";
import SendIcon from "@mui/icons-material/Send";
import KeyIcon from "@mui/icons-material/Key";
import MicIcon from "@mui/icons-material/Mic";
import SelectModelDialog from "examples/ModelDialog";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import MessageBox from "./components/messageBox";
import { useMaterialUIController, setUser } from "context";
import ConfirmDialog from "examples/ConfirmDialog";

function ModelsTest() {
  const navigate = useNavigate();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid } = controller;
  const location = useLocation();

  const [leftActive, setLeftActive] = useState(true);
  const [disableClick, setDisableClick] = useState(false);

  const [dialogs, setDialogs] = useState([]);
  const [dialogSelected, setDialogSelected] = useState(0);
  const [loadingDialogs, setLoadingDialogs] = useState(false);
  const handleClickDialog = (index) => {
    setDialogSelected(index);
    let newModels = [dialogs[index].model_left, dialogs[index].model_right];
    console.log(dialogs[index]);
    setModels(newModels);
    let num = 0;
    if (newModels[0]) {
      num += 1;
    }
    if (newModels[1]) {
      num += 1;
    }
    setSelectedModels(num);
    if (num >= 1 && chatText) {
      setSendAllowed(true);
    } else {
      setSendAllowed(false);
    }
    loadMessageFromServer(dialogs[index], false);
  };
  const loadDialogs = async () => {
    if (loadingDialogs) return;
    setLoadingDialogs(true);
    setDisableClick(true);
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/chat/findByUser`;
      let response = await axios.post(
        url,
        {
          userid: user.id,
          page: 1,
          limit: 9999999,
          sort: "updated_time",
          order: "desc",
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
          validateStatus: (status) => status < 500,
        }
      );
      if (response.status === 200) {
        let dialogsGot = response.data.result;
        let data = [];
        dialogsGot.forEach((curDia) => {
          let d = new Date(curDia.updated_time);
          let timeString = d.toLocaleString();
          data.push({
            id: curDia.id,
            title: curDia.name,
            time: timeString,
            model_left: curDia.model_left ? curDia.model_left : null,
            model_right: curDia.model_right ? curDia.model_right : null,
          });
        });
        if (data.length === 0) {
          handleAddDialog();
        }
        setDialogs(data);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Loading Dialogs");
          setInfoText("Failed to load dialogs, please try again");
        } else {
          setInfoLabel("加载对话列表");
          setInfoText("对话列表加载出错，请重试");
        }
        setError(true);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Loading Dialogs");
        setInfoText("Failed to load dialogs, please try again");
      } else {
        setInfoLabel("加载对话列表");
        setInfoText("对话列表加载出错，请重试");
      }
      setError(true);
    } finally {
      setLoadingDialogs(false);
      setDisableClick(false);
    }
  };
  const handleAddDialog = async () => {
    let left = null;
    if (location.state && location.state[0]) {
      left = location.state[0];
      location.state[0] = null;
    }
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.post(
        `${apiUrl}/chat`,
        {
          name: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "New Dialog" : "新对话",
          userid: user.id,
          model_left: left ? left.id : 0,
          model_right: 0,
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
          validateStatus: (status) => status < 500,
        }
      );
      if (response.status === 200) {
        loadDialogs();
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Creating New Dialog");
          setInfoText("Failed to create new dialog, please try again");
        } else {
          setInfoLabel("创建新对话");
          setInfoText("新对话创建出错，请重试");
        }
        setError(true);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Creating New Dialog");
        setInfoText("Failed to create new dialog, please try again");
      } else {
        setInfoLabel("创建新对话");
        setInfoText("新对话创建出错，请重试");
      }
      setError(true);
    }
  };
  const [confirm, setConfirm] = useState(false);
  const [dialogToDelete, setDialogToDelete] = useState(0);
  const handleOpenConfirm = (index) => {
    setDialogToDelete(index);
    setConfirm(true);
  };
  const handleCloseConfirm = () => {
    setConfirm(false);
  };
  const handleDeleteDialog = async () => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.delete(`${apiUrl}/chat/${dialogs[dialogToDelete].id}`, {
        headers: {
          Authorization: `${authData.token}`,
          validateStatus: (status) => status < 500,
        },
      });
      if (response.status === 200) {
        loadDialogs();
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Deleting Dialog");
          setInfoText("Failed to delete dialog, please try again");
        } else {
          setInfoLabel("删除对话记录");
          setInfoText("对话记录删除失败，请重试");
        }
        setError(true);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Deleting Dialog");
        setInfoText("Failed to delete dialog, please try again");
      } else {
        setInfoLabel("删除对话记录");
        setInfoText("对话记录删除失败，请重试");
      }
      setError(true);
    } finally {
      setConfirm(false);
    }
  };

  const [selectedModels, setSelectedModels] = useState(0);
  const [models, setModels] = useState([null, null]);
  const [modelDialogOpen, setModelDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setModelDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setModelDialogOpen(false);
  };
  const handleSelectModel = async (model) => {
    if (models[0] && models[0].id === model.id) {
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Select Model");
        setInfoText("Cannot select the same model");
      } else {
        setInfoLabel("选择模型");
        setInfoText("无法选择已经被选择的模型");
      }
      setError(true);
      return;
    }
    if (models[1] && models[1].id === model.id) {
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Select Model");
        setInfoText("Cannot select the same model");
      } else {
        setInfoLabel("选择模型");
        setInfoText("无法选择已经被选择的模型");
      }
      setError(true);
      return;
    }
    let newModels = [...models];
    if (modelToChange !== -1) {
      newModels[modelToChange] = model;
      setModelToChange(-1);
    } else {
      if (!newModels[0]) {
        newModels[0] = model;
      } else {
        newModels[1] = model;
      }
    }
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.put(
        `${apiUrl}/chat/${dialogs[dialogSelected].id}`,
        {
          name: dialogs[dialogSelected].title,
          userid: user.id,
          model_left: newModels[0] ? newModels[0].id : 0,
          model_right: newModels[1] ? newModels[1].id : 0,
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
          validateStatus: (status) => status < 500,
        }
      );
      if (response.status === 200) {
        loadDialogs();
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Update Dialog Model");
          setInfoText("Failed to update dialog model");
        } else {
          setInfoLabel("更新对话模型");
          setInfoText("对话模型更新失败");
        }
        setError(true);
        setDisableClick(false);
        return;
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Update Dialog Model");
        setInfoText("Failed to update dialog model");
      } else {
        setInfoLabel("更新对话模型");
        setInfoText("对话模型更新失败");
      }
      setError(true);
      setDisableClick(false);
      return;
    }
  };
  const handleDeleteModel = async (id) => {
    let newModels = [...models];
    newModels[id] = null;
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.put(
        `${apiUrl}/chat/${dialogs[dialogSelected].id}`,
        {
          name: dialogs[dialogSelected].title,
          userid: user.id,
          model_left: newModels[0] ? newModels[0].id : 0,
          model_right: newModels[1] ? newModels[1].id : 0,
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
          validateStatus: (status) => status < 500,
        }
      );
      if (response.status === 200) {
        loadDialogs();
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Update Dialog Model");
          setInfoText("Failed to update dialog model");
        } else {
          setInfoLabel("更新对话模型");
          setInfoText("对话模型更新失败");
        }
        setError(true);
        setDisableClick(false);
        return;
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Update Dialog Model");
        setInfoText("Failed to update dialog model");
      } else {
        setInfoLabel("更新对话模型");
        setInfoText("对话模型更新失败");
      }
      setError(true);
      setDisableClick(false);
      return;
    }
  };
  const [modelToChange, setModelToChange] = useState(-1);
  const handleChangeModel = (id) => {
    setModelToChange(id);
    setModelDialogOpen(true);
  };

  const [loading, setLoading] = useState([false, false]);
  const [error, setError] = useState(false);
  const [infoLabel, setInfoLabel] = useState("");
  const [infoText, setInfoText] = useState("");
  const handleClose = () => setError(false);

  const loadMessageFromServer = async (dialog, newReply) => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let response = await axios.post(
        `${apiUrl}/message/findByChat`,
        {
          chat_id: dialog.id,
          page: 1,
          limit: 9999999,
          sort: "updated_time",
          order: "asc",
        },
        {
          headers: {
            Authorization: `${authData.token}`,
          },
          validateStatus: (status) => status < 500,
        }
      );
      if (response.status === 200) {
        let loadedMessages = [];
        response.data.result.forEach((message, index) => {
          let d = new Date(message.updated_time);
          let timeString = d.toLocaleString();
          let r;
          if (!message.model) {
            r = "user";
          } else {
            r = "assistant";
          }
          let newChat = {
            content: message.content,
            role: r,
            time: timeString,
            model: message.model ? message.model : { name: user.name },
            position: message.position,
          };
          loadedMessages.push(newChat);
        });
        let newChatHistory = [[], []];
        loadedMessages.forEach((message, index) => {
          let pos = message.position;
          newChatHistory[pos] = [...newChatHistory[pos], message];
        });
        setChatHistory(newChatHistory);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Loading Chat History");
          setInfoText("Failed to load chat history");
        } else {
          setInfoLabel("加载历史消息");
          setInfoText("历史消息加载失败");
        }
        setError(true);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Loading Chat History");
        setInfoText("Failed to load chat history");
      } else {
        setInfoLabel("加载历史消息");
        setInfoText("历史消息加载失败");
      }
      setError(true);
    }
  };

  const [chatText, setChatText] = useState("");
  const handleChatTextChange = (event) => {
    const value = event.target.value;
    setChatText(value);
    if (value && selectedModels > 0) {
      setSendAllowed(true);
    } else {
      setSendAllowed(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      sendNewMessage();
    }
  };
  const [chatHistory, setChatHistory] = useState([[], []]);
  const [sendAllowed, setSendAllowed] = useState(false);
  const handleError = (msgEn, msgZh) => {
    if (process.env.REACT_APP_LANGUAGE.trim() === "en") {
      setInfoLabel("Model Experience");
      setInfoText(msgEn);
    } else {
      setInfoLabel("模型体验");
      setInfoText(msgZh);
    }
    setError(true);
  };
  const finalizeRequest = async () => {
    if (!chatText) return;
    setChatText("");
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/user/${encodeURIComponent(user.id)}`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
  
      if (response.status === 200) {
        setUser(dispatch, response.data);
      } else {
        localStorage.clear();
        navigate("/authentication/sign-in");
      }
    } catch (error) {
      console.error("更新用户信息失败:", error);
      localStorage.clear();
      navigate("/authentication/sign-in");
    }
  };
  const sendNewMessage = async () => {
    setSendAllowed(false);
    setDisableClick(true);
    let d = new Date();
    let timeString = d.toLocaleString();
    let newChatText = chatText;
    if (newChatText === "") {
      newChatText = " ";
    }
    let newChat = {
      content: newChatText,
      role: "user",
      time: timeString,
      model: { name: user.name },
    };
    let newChatHistory = [[], []];
    let l = [false, false];
    let sms = [];
    for (let i = 0; i < selectedModels; i++) {
      newChatHistory[i] = [...chatHistory[i], newChat];
      l[i] = true;
      sms.push(i);
    }
    setLoading(l);
    setChatHistory(newChatHistory);
    try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const fetchPromises = sms.map((_, i) => 
          fetch(`${apiUrl}/v3/chat/completions`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer sk-nQpY9Cw46m3bbiz2jFY1sFDc5EPbH5RwSMWbsmXSjjyEK1ic",
            },
            body: JSON.stringify({
              messages: newChatHistory[i],
              model: models[i].name,
              stream: true,
              userid: user.id,
              chatid: dialogs[dialogSelected].id,
              position: i,
              logic: "Dialog",
            }),
          })
          .then(async (response) => {
            if (response.status === 200) {
              const reader = response.body.getReader();
              const decoder = new TextDecoder();
              while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const messageStr = decoder.decode(value, { stream: true }).trim();
                const jsonLines = messageStr.split("\n");
                for (let line of jsonLines) {
                  if (!line.trim()) continue;
                  try {
                    const messageObj = JSON.parse(line);
                    const choices = messageObj.choices;
                    if (choices && choices.length > 0) {
                      const lastContent = choices[choices.length - 1].message.content;
                      if (newChatHistory[i][newChatHistory[i].length - 1].newest) {
                        let aiChat = {
                          content: newChatHistory[i][newChatHistory[i].length - 1].content + lastContent,
                          role: "assistant",
                          time: timeString,
                          model: models[i],
                          position: i,
                          newest: true,
                        };
                        newChatHistory[i][newChatHistory[i].length - 1] = aiChat;
                      } else {
                        let aiChat = {
                          content: lastContent,
                          role: "assistant",
                          time: timeString,
                          model: models[i],
                          position: i,
                          newest: true,
                        };
                        newChatHistory[i] = [...newChatHistory[i], aiChat];
                      }
                      setChatHistory([...newChatHistory]);
                    }
                  } catch (e) {
                    console.error("解析流数据失败:", e, "原始数据:", line);
                  }
                }
              }
            } else if (response.status === 403) {
              handleError("Model is not available, please try another model", "模型当前不可用，请尝试其他模型");
            } else if (response.status === 406) {
              handleError("Insufficient balance, please recharge", "余额不足，请充值");
            } else {
              handleError("Model chat failed, please try again", "模型对话出错，请重试");
            }
          })
          .catch((error) => {
            console.error("请求失败:", error);
            handleError("Model chat failed, please try again", "模型对话出错，请重试");
          })
          .finally(() => {
            l[i] = false;
            setLoading([...l]);
            if (!l[0] && !l[1]) {
              finalizeRequest();
            }
          })
        );
        await Promise.allSettled(fetchPromises);
      } catch (error) {
        console.error("发送消息时出错:", error);
        handleError("Model chat failed, please try again", "模型对话出错，请重试");
      }
    setSendAllowed(true);
    setDisableClick(false);
  };
  const sendNewMessageNotStream = async () => {
    setSendAllowed(false);
    setDisableClick(true);
    let d = new Date();
    let timeString = d.toLocaleString();
    let newChatText = chatText;
    if (newChatText === "") {
      newChatText = " ";
    }
    let newChat = {
      content: newChatText,
      role: "user",
      time: timeString,
      model: { name: user.name },
    };
    let newChatHistory = [[], []];
    let l = [false, false];
    for (let i = 0; i < selectedModels; i++) {
      newChatHistory[i] = [...chatHistory[i], newChat];
      l[i] = true;
    }
    setLoading(l);
    setChatHistory(newChatHistory);
    for (let i = 0; i < selectedModels; i++) {
      let succ = false;
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        let response = await axios.post(
          `${apiUrl}/v3/chat/completions`,
          {
            messages: newChatHistory[i],
            model: models[i].name,
            stream: false,
            userid: user.id,
            chatid: dialogs[dialogSelected].id,
            position: i,
            logic: "Dialog",
          },
          {
            headers: {
              Authorization: "Bearer sk-nQpY9Cw46m3bbiz2jFY1sFDc5EPbH5RwSMWbsmXSjjyEK1ic",
            },
            validateStatus: function (status) {
              return status >= 200 && status < 500;
            },
          }
        );
        if (response.status === 200) {
          succ = true;
        } else if (response.status === 403) {
          if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
            setInfoLabel("Model Experience");
            setInfoText("Model is not available, please try another model");
          } else {
            setInfoLabel("模型体验");
            setInfoText("模型当前不可用，请尝试其他模型");
          }
          setError(true);
        } else if (response.status === 406) {
          if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
            setInfoLabel("Model Experience");
            setInfoText("Insufficient balance, please recharge");
          } else {
            setInfoLabel("模型体验");
            setInfoText("余额不足，请充值");
          }
          setError(true);
        } else {
          if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
            setInfoLabel("Model Experience");
            setInfoText("Model chat failed, please try again");
          } else {
            setInfoLabel("模型体验");
            setInfoText("模型对话出错，请重试");
          }
          setError(true);
        }
      } catch (error) {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Model Experience");
          setInfoText("Model chat failed, please try again");
        } else {
          setInfoLabel("模型体验");
          setInfoText("模型对话出错，请重试");
        }
        setError(true);
      } finally {
        l[i] = false;
        if (!l[0] && !l[1] && chatText) {
          setLoading(l);
          setChatText("");
          if (succ) {
            loadMessageFromServer(dialogs[dialogSelected], true);
          }
          try {
            if (!localStorage.getItem("maasAuthData")) {
              navigate("/authentication/sign-in");
              return;
            }
            const authData = JSON.parse(localStorage.getItem("maasAuthData"));
            const apiUrl = process.env.REACT_APP_API_URL;
            let url = `${apiUrl}/user/${encodeURIComponent(user.id)}`;
            let response = await axios.get(url, {
              headers: { Authorization: `${authData.token}` },
              validateStatus: (status) => status < 500,
            });
            if (response.status === 200) {
              setUser(dispatch, response.data);
            } else if (response.status === 401) {
              localStorage.clear();
              navigate("/authentication/sign-in");
              return;
            } else {
              localStorage.clear();
              navigate("/authentication/sign-in");
              return;
            }
          } catch (error) {
            console.log(error);
            localStorage.clear();
            navigate("/authentication/sign-in");
            return;
          }
        }
      }
    }
    setSendAllowed(true);
    setDisableClick(false);
  };

  useEffect(() => {
    if (user) {
      loadDialogs();
    }
  }, [user]);

  useEffect(() => {
    if (dialogs && dialogs.length > 0) {
      handleClickDialog(0);
    }
  }, [dialogs]);

  useEffect(() => {
    if (location.state && location.state[0]) {
      handleAddDialog();
      window.history.replaceState(null, "");
    }
  }, [location.state]);

  const leftSectionRef = useRef(null);
  const rightSectionRef = useRef(null);
  useEffect(() => {
    if (leftSectionRef.current) {
      leftSectionRef.current.scrollTop = leftSectionRef.current.scrollHeight;
    }
  }, [chatHistory[0]]);
  useEffect(() => {
    if (rightSectionRef.current) {
      rightSectionRef.current.scrollTop = rightSectionRef.current.scrollHeight;
    }
  }, [chatHistory[1]]);

  return (
    <DashboardLayout>
      <Box
        sx={{
          pointerEvents: disableClick ? "none" : "auto",
        }}
      >
        <Box display="flex" alignItems="flex-start" height="100vh" gap={2}>
          {!leftActive ? (
            <Box
              width="12.5vw"
              height="100%"
              display="flex"
              flexDirection="column"
              sx={{ overflow: "hidden" }}
            >
              <Button
                component="label"
                variant="contained"
                startIcon={<HistoryIcon />}
                onClick={() => setLeftActive(true)}
                sx={{
                  backgroundColor: "#7f6ce0",
                  color: "#ffffff",
                  float: "right",
                  fontSize: 14,
                  position: "absolute",
                  left: 0,
                  top: "5%",
                  "&:hover": {
                    bgcolor: "#F7F7F7",
                    color: "#000000",
                  },
                }}
              >
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Chat History" : "对话记录"}
              </Button>
            </Box>
          ) : (
            <Box
              width="12.5vw"
              height="100%"
              display="flex"
              flexDirection="column"
              sx={{ bgcolor: "#ffffff", overflow: "hidden" }}
            >
              <Box sx={{ position: "relative", textAlign: "center", height: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 1rem",
                    mt: 2,
                  }}
                >
                  <HistoryIcon />
                  <Typography
                    variant="h6"
                    sx={{
                      flexGrow: 1,
                      textAlign: "center",
                      fontSize: 14,
                    }}
                  >
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Chat History" : "对话记录"}
                  </Typography>
                  <IconButton onClick={() => setLeftActive(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Button
                  onClick={handleAddDialog}
                  variant="contained"
                  sx={{
                    backgroundColor: "#7f6ce0",
                    color: "#ffffff",
                    width: "80%",
                    mt: 2,
                    fontSize: 14,
                    fontWeight: "light",
                    "&:hover": {
                      bgcolor: "#F7F7F7",
                      color: "#000000",
                    },
                  }}
                >
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Create Chat" : "创建对话"}
                </Button>
                <Box sx={{ overflowY: "auto", height: "90%", padding: "1rem" }}>
                  {dialogs.map((dialog, index) => (
                    <Box
                      key={dialog.id}
                      onClick={() => handleClickDialog(index)}
                      sx={{
                        marginBottom: "1rem",
                        padding: "1rem",
                        position: "relative",
                        border: "2px solid #cecece",
                        borderRadius: 2,
                        cursor: "pointer",
                        bgcolor: dialogSelected === index ? "#F7F7F7" : "#ffffff",
                        "&:hover": {
                          bgcolor: "#F7F7F7",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000000",
                          fontSize: 14,
                          textAlign: "left",
                          paddingLeft: "1%",
                        }}
                      >
                        {dialog.title}
                      </Typography>
                      <Typography
                        sx={{
                          color: "#868686",
                          fontSize: 14,
                          textAlign: "left",
                          paddingLeft: "1%",
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {dialog.time}
                      </Typography>
                      <IconButton
                        onClick={() => handleOpenConfirm(index)}
                        sx={{ position: "absolute", top: "17%", right: "1%" }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
          <Box
            width="66vw"
            height="100%"
            display="flex"
            flexDirection="column"
            sx={{ overflow: "hidden" }}
          >
            <Box
              sx={{
                padding: "1rem",
                borderBottom: "1px solid #ddd",
                flexShrink: 0,
              }}
            >
              {!models[0] && !models[1] ? (
                <Box display="flex" alignItems="center">
                  <Typography flex="1" fontSize={14} color="#777777">
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "The model has not been selected yet. Please click the button on the right side of the screen to select a model." : "当前还未选择模型，请点击画面右侧按钮选择模型"}
                  </Typography>
                </Box>
              ) : models[0] && models[1] ? (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        borderRadius: "8px",
                        padding: "0.5rem 1rem",
                        bgcolor: "#ffffff",
                      }}
                    >
                      <Box
                        component="img"
                        src={models[0].logo}
                        alt="模型图片"
                        sx={{ width: "24px", height: "24px", marginRight: "0.5rem" }}
                      />
                      <Typography sx={{ fontWeight: "bold", marginRight: "1rem", fontSize: 16 }}>
                        {models[0].name}
                      </Typography>
                      <Typography
                        flex="1"
                        sx={{ color: "#7f6ce0", cursor: "pointer", marginRight: "0.5rem", fontSize: 14 }}
                        onClick={() => handleChangeModel(0)}
                      >
                        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Change Model" : "更换模型"}
                      </Typography>
                      {/* <IconButton>
                        <KeyIcon />
                      </IconButton> */}
                      <IconButton onClick={() => handleDeleteModel(0)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{
                        borderRadius: "8px",
                        padding: "0.5rem 1rem",
                        bgcolor: "#ffffff",
                      }}
                    >
                      <Box
                        component="img"
                        src={models[1].logo}
                        alt="模型图片"
                        sx={{ width: "24px", height: "24px", marginRight: "0.5rem" }}
                      />
                      <Typography sx={{ fontWeight: "bold", marginRight: "1rem", fontSize: 16 }}>
                        {models[1].name}
                      </Typography>
                      <Typography
                        flex="1"
                        sx={{ color: "#7f6ce0", cursor: "pointer", marginRight: "0.5rem", fontSize: 14 }}
                        onClick={() => handleChangeModel(1)}
                      >
                        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Change Model" : "更换模型"}
                      </Typography>
                      {/* <IconButton>
                        <KeyIcon />
                      </IconButton> */}
                      <IconButton onClick={() => handleDeleteModel(1)}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
              ) : models[0] ? (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    borderRadius: "8px",
                    padding: "0.5rem 1rem",
                    bgcolor: "#ffffff",
                  }}
                >
                  <Box
                    component="img"
                    src={models[0].logo}
                    sx={{ width: "24px", height: "24px", marginRight: "0.5rem" }}
                  />
                  <Typography sx={{ fontWeight: "bold", marginRight: "1rem", fontSize: 16 }}>
                    {models[0].name}
                  </Typography>
                  <Typography
                    flex="1"
                    sx={{ color: "#7f6ce0", cursor: "pointer", marginRight: "0.5rem", fontSize: 14 }}
                    onClick={() => handleChangeModel(0)}
                  >
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Change Model" : "更换模型"}
                  </Typography>
                  {/* <IconButton>
                    <KeyIcon />
                  </IconButton> */}
                  <IconButton onClick={() => handleDeleteModel(0)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              ) : (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{
                    borderRadius: "8px",
                    padding: "0.5rem 1rem",
                    bgcolor: "#ffffff",
                  }}
                >
                  <Box
                    component="img"
                    src={models[1].logo}
                    sx={{ width: "24px", height: "24px", marginRight: "0.5rem" }}
                  />
                  <Typography sx={{ fontWeight: "bold", marginRight: "1rem", fontSize: 16 }}>
                    {models[1].name}
                  </Typography>
                  <Typography
                    flex="1"
                    sx={{ color: "#7f6ce0", cursor: "pointer", marginRight: "0.5rem", fontSize: 14 }}
                    onClick={() => handleChangeModel(1)}
                  >
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Change Model" : "更换模型"}
                  </Typography>
                  {/* <IconButton>
                    <KeyIcon />
                  </IconButton> */}
                  <IconButton onClick={() => handleDeleteModel(1)}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box sx={{ flex: "1 1 auto", display: "flex", gap: 2, overflow: "hidden" }}>
              {models[0] && (
                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  ref={leftSectionRef}
                >
                  {chatHistory[0].map((message, index) => {
                    return (
                      <MessageBox
                        key={index}
                        message={message}
                        scrollContainerRef={leftSectionRef}
                      />
                    );
                  })}
                  {loading[0] && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "1rem",
                      }}
                    >
                      <CircularProgress sx={{ color: "#7F6CE0" }}  />
                    </Box>
                  )}
                </Box>
              )}
              {models[1] && (
                <Box
                  sx={{
                    flex: 1,
                    overflowY: "auto",
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  ref={rightSectionRef}
                >
                  {chatHistory[1].map((message, index) => {
                    return (
                      <MessageBox
                        key={index}
                        message={message}
                        scrollContainerRef={rightSectionRef}
                      />
                    );
                  })}
                  {loading[1] && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "1rem",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            <Box sx={{ padding: "1rem", flexShrink: 0 }}>
              <Box display="flex" alignItems="center" gap={1} sx={{ mb: 1 }}>
                {/* <IconButton
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #cecece",
                    padding: "0.5rem",
                    bgcolor: "#ffffff",
                    "&:hover": {
                      bgcolor: "#b0b0b0",
                    },
                    height: "30px",
                    width: "30px",
                  }}
                >
                  <MicIcon />
                </IconButton>
                <IconButton
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #cecece",
                    padding: "0.5rem",
                    bgcolor: "#ffffff",
                    "&:hover": {
                      bgcolor: "#b0b0b0",
                    },
                    height: "30px",
                    width: "30px",
                  }}
                >
                  <ImageIcon />
                </IconButton>
                <IconButton
                  sx={{
                    borderRadius: 2,
                    border: "2px solid #cecece",
                    padding: "0.5rem",
                    bgcolor: "#ffffff",
                    "&:hover": {
                      bgcolor: "#b0b0b0",
                    },
                    height: "30px",
                    width: "30px",
                  }}
                >
                  <FileOpenIcon />
                </IconButton> */}
                <Box display="flex" alignItems="center" justifyContent="center" gap={1} ml={1}>
                  <InfoIcon sx={{ fontSize: "small" }} />
                  <Typography sx={{ fontSize: 12, color: "#858585", mt: 0.5 }}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Using the model will consume tokens, with costs based on actual usage." : "体验模型将会消耗tokens，费用以实际发生为准"}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" position="relative" alignItems="center" sx={{ mb: 1 }}>
                <TextField
                  variant="outlined"
                  placeholder={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Please enter your question. Maximum 1000 characters." : "请输入您的问题，最长1000字"}
                  multiline
                  fullWidth
                  value={chatText}
                  onChange={handleChatTextChange}
                  onKeyDown={handleKeyPress}
                  inputProps={{ maxLength: 1000 }}
                  autoComplete="off"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      padding: "0.5rem",
                      paddingRight: "50px",
                      borderRadius: "8px",
                      bgcolor: "#ffffff",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "transparent",
                    },
                  }}
                />
                <IconButton
                  disabled={!sendAllowed}
                  sx={{
                    position: "absolute",
                    right: "1rem",
                    top: "50%",
                    transform: "translateY(-50%)",
                    color: "#ffffff",
                    bgcolor: "#696969",
                    borderRadius: 2,
                    padding: "0.5rem",
                    "&:hover": {
                      bgcolor: "#b0b0b0",
                    },
                  }}
                  onClick={sendNewMessage}
                >
                  <SendIcon />
                </IconButton>
              </Box>
              <Typography textAlign="center" sx={{ fontSize: 12, color: "#858585" }}>
                {process.env.REACT_APP_LANGUAGE.trim() == "en"
                  ? "All responses in this conversation are generated by an AI model and do not represent the company’s views or opinions."
                  : "对话中的所有回答内容均由人工智能模型生成，不代表公司态度或观点"}
              </Typography>
            </Box>
          </Box>
          <Box
            width="60px"
            height="60%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            onClick={selectedModels < 2 ? handleOpenDialog : undefined}
            sx={{
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              bgcolor: "#ffffff",
              overflow: "hidden",
              cursor: "pointer",
              position: "absolute",
              top: "20%",
              right: 0,
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
              padding: "1rem",
            }}
          >
            <Typography variant="h6">+</Typography>
            <Typography mt={1} fontSize={12} fontWeight="regular">
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Add Models" : "增加模型"}
            </Typography>
            <Typography mt={1} fontSize={12} fontWeight="regular">
              ({selectedModels}/2)
            </Typography>
          </Box>
        </Box>
      </Box>
      <SelectModelDialog
        open={modelDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleSelectModel}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={error}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={error}>
          <Box
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 480,
              bgcolor: "#fff",
              borderRadius: "12px",
              boxShadow: 24,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "16px", color: "#333" }}
              >
                {infoLabel}
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#ccc",
                  cursor: "pointer",
                }}
              >
                ✕
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "16px",
                flex: 1,
                overflowY: "auto",
                fontSize: "14px",
                color: "#666",
              }}
            >
              {infoText}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "12px 16px",
                borderTop: "1px solid #e0e0e0",
                bgcolor: "#f5f5f5",
              }}
            >
              <Box
                sx={{
                  height: "32px",
                  lineHeight: "32px",
                  padding: "0 16px",
                  backgroundColor: "#f0f0f0",
                  border: "1px solid #d0d0d0",
                  borderRadius: "4px",
                  color: "#666",
                  fontSize: "14px",
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                确认
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <ConfirmDialog
        open={confirm}
        onClose={handleCloseConfirm}
        title={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Delete Dialog" : "删除对话记录"}
        info={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Are you sure you want to delete the currently selected conversation record? The deletion operation cannot be undone." : "是否确认删除当前选择的对话记录？删除操作不可恢复"}
        confirm={handleDeleteDialog}
      />
    </DashboardLayout>
  );
}

export default ModelsTest;
