/**

*/

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Grid, Button, Divider, ButtonBase, Backdrop, Icon } from "@mui/material";
import { List, ListItem, ListItemText, IconButton, Modal, Fade } from "@mui/material";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { Star, Explore, Apps } from "@mui/icons-material";
import images from "config/imageConfig";
import axios from "axios";
import ModelDetail from "examples/ModelDetail";
import CodeSample from "examples/CodeSample";
import { useMaterialUIController } from "context";
import ApiKeyDialog from "examples/APIDialog";
import CampaignIcon from "@mui/icons-material/Campaign";
import RewardDialog from "examples/RewardDialog";

function Dashboard() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid } = controller;
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  const handleClickImage = () => {
    if (selectedTab == 0) {
      navigate("/models_view");
    } else if (selectedTab == 1) {
      navigate("/models_test");
    } else {
      navigate("/apps_own");
    }
  };

  const [selectedModel, setSelectedModel] = useState(null);

  const [codeSampleOpen, setCodeSampleOpen] = useState(false);
  const handleOpenCodeSample = (m) => {
    setSelectedModel(m);
    setCodeSampleOpen(true);
  };
  const handleCloseCodeSample = () => {
    setCodeSampleOpen(false);
  };

  const [modelDetailOpen, setModelDetailOpen] = useState(false);
  const handleOpenModelDetail = (m) => {
    setSelectedModel(m);
    setModelDetailOpen(true);
  };
  const handleCloseModelDetail = () => {
    setModelDetailOpen(false);
  };

  const [apiDialogOpen, setApiDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setApiDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setApiDialogOpen(false);
  };

  const [rewardDialogOpen, setRewardDialogOpen] = useState(false);
  const handleOpenRewardDialog = () => {
    setRewardDialogOpen(true);
  };
  const handleCloseRewardDialog = () => {
    setRewardDialogOpen(false);
  };

  const [error, setError] = useState(false);
  const [infoLabel, setInfoLabel] = useState("");
  const [infoText, setInfoText] = useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => setError(false);

  const handleTry = (m) => {
    if (m.model_type !== "TEXT_TO_TEXT") {
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Model Selection");
        setInfoText("Currently only support text-to-text models, please reselect");
      } else {
        setInfoLabel("模型选择");
        setInfoText("当前只支持选择文字生产文字类型的模型，请重新选择");
      }
      setError(true);
      return;
    }
    if (m.status !== "AVAILABLE") {
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Model Selection");
        setInfoText("The model is currently unavailable, please try other available models");
      } else {
        setInfoLabel("模型选择");
        setInfoText("当前模型处于不可用状态，请尝试其他可用的模型");
      }
      setError(true);
      return;
    }
    navigate("/models_test", { state: [m] });
  };

  const [models, setModels] = useState([]);
  const getModels = async () => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/model/findByScore?top=3`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        setModels(response.data.result);
        console.log(response.data.result);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Error");
          setInfoText("Failed to get the model list");
        } else {
          setInfoLabel("错误");
          setInfoText("获取模型列表出错");
        }
        setError(true);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Failed to get the model list");
      } else {
        setInfoLabel("错误");
        setInfoText("获取模型列表出错");
      }
      setError(true);
    }
  };

  const [recommendModels, setRecommendModels] = useState([]);
  const getRecommendModels = async () => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/model/findByRecommended?recommended=true`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        setRecommendModels(response.data.result);
        console.log(response.data.result);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Error");
          setInfoText("Failed to get the model list");
        } else {
          setInfoLabel("错误");
          setInfoText("获取模型列表出错");
        }
        setError(true);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Failed to get the model list");
      } else {
        setInfoLabel("错误");
        setInfoText("获取模型列表出错");
      }
      setError(true);
    }
  };

  const [onlineModels, setOnlineModels] = useState([]);
  const getOnlineModels = async () => {
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/model/findByOnlineSearch?online_search=true`;
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        setOnlineModels(response.data.result);
        console.log(response.data.result);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Error");
          setInfoText("Failed to get the model list");
        } else {
          setInfoLabel("错误");
          setInfoText("获取模型列表出错");
        }
        setError(true);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Failed to get the model list");
      } else {
        setInfoLabel("错误");
        setInfoText("获取模型列表出错");
      }
      setError(true);
    }
  };

  useEffect(() => {
    getModels();
    getRecommendModels();
    getOnlineModels();
  }, []);

  const ranks = [images.ranking_gold, images.ranking_silver, images.ranking_cu];

  const descriptions = [
    "模型广场提供超过80种模型选择，包括三方开源模型、行业领域模型等。您可以按照业务需求选择合适的模型接入。",
    "您可以直接与模型对话，体验模型效果。我们支持对比两个模型在同一对话中的不同表现。",
    "便捷地将应用和API key绑定，实时监控token使用量。利用图表，轻松查看应用长期/短期趋势变化。",
  ];
  const descriptions_eng = [
    "Model Plaza offers over 80 model options, including third-party open-source models and industry-specific models. You can select the most suitable model based on your business needs.",
    "You can interact directly with the models to experience their performance. We support comparing two models’ responses within the same conversation.",
    "Easily link your application to an API key for real-time token usage monitoring. Visualize long-term and short-term trends with intuitive charts.",
  ];


  return (
    <DashboardLayout>
      <Box
        minHeight="100vh"
        height="auto"
        width="100%"
        sx={{ background: "linear-gradient(to bottom, #EDEAFA, #FFFFFF 5%)" }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="15%"
          textAlign="center"
          pt={6}
          mb={6}
        >
          <Typography fontWeight="bold" color="#4A4A4A" fontSize={24}>
            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Hi! Welcome to the XMaaS Platform" : "你好，欢迎使用星觉大模型服务平台"}
          </Typography>
        </Box>
        <Box display="grid" gridTemplateColumns="1fr 30%">
          <Box width="100%" height="auto" display="flex" flexDirection="column">
            <Box
              mx="auto"
              width="90%"
              height="auto"
              sx={{ background: "linear-gradient(to bottom, #FFFFFF, #EDEAFA 50%)" }}
              borderRadius={5}
              boxShadow={0}
            >
              <Box display="flex" justifyContent="flex-start" px={5} py={2} gap={4}>
                {[
                  { label: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Model Hub" : "模型广场", icon: <Icon>domain</Icon>, id: 0 },
                  { label: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Try Models" : "模型体验", icon: <Icon>forum</Icon>, id: 1 },
                  { label: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "My Apps" : "我的应用", icon: <Icon>assignment</Icon>, id: 2 },
                ].map((tab, index) => (
                  <React.Fragment key={tab.id}>
                    <Button
                      key={tab.id}
                      variant="text"
                      onClick={() => handleTabChange(tab.id)}
                      sx={{
                        color: selectedTab === tab.id ? "#7F6CE0" : "#4A4A4A",
                        fontWeight: selectedTab === tab.id ? "bold" : "normal",
                        textTransform: "none",
                        fontSize: "16px",
                        px: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                      startIcon={tab.icon}
                    >
                      {tab.label}
                    </Button>
                    {index < 2 && <Typography color="#E0E0E0">|</Typography>}
                  </React.Fragment>
                ))}
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" px={3}>
                <Typography color="#4A4A4A" fontSize={14}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? descriptions_eng[selectedTab] : descriptions[selectedTab]}
                </Typography>
                <ButtonBase onClick={handleClickImage}>
                  <Box
                    component="img"
                    src={
                      selectedTab === 0
                        ? images.dashboard_gomodelview
                        : selectedTab === 1
                        ? images.dashboard_gomodeltest
                        : images.dashboard_gomyapps
                    }
                    alt="Tab Image"
                    sx={{
                      height: "60px",
                      width: "auto",
                      ml: 10,
                    }}
                  />
                </ButtonBase>
              </Box>
              <Box px={3} py={2} height="auto" mt={1}>
                {selectedTab === 0 && (
                  <Grid container spacing={4} justifyContent="center" sx={{ px: 2 }}>
                    {recommendModels.slice(0, 4).map((model, index) => (
                      <Grid item xs={12} sm={6} md={3} key={index}>
                        <Box
                          sx={{
                            border: "1px solid #e0e0e0",
                            borderRadius: "12px",
                            textAlign: "center",
                            p: 2,
                            boxShadow: 1,
                            backgroundColor: "#fff",
                            width: "100%",
                            maxWidth: "260px",
                            height: "auto",
                          }}
                        >
                          <Box
                            component="img"
                            src={model.logo}
                            alt={model.name}
                            sx={{
                              width: "75px",
                              height: "auto",
                              margin: "0 auto 8px",
                            }}
                          />
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            fontSize="16px"
                            color="#000000"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {model.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="#737373"
                            fontSize="14px"
                            mb={2}
                            sx={{
                              wordWrap: "break-word",
                              lineHeight: 1.4,
                              height: "40px",
                              overflow: "hidden",
                            }}
                          >
                            {model.text_summary}
                          </Typography>
                          <Box display="flex" justifyContent="space-between" gap={2}>
                            <Box flex={1} textAlign="center">
                              <Typography color="#737373" fontSize="12px">
                                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Input" : "输入"}
                              </Typography>
                            </Box>
                            <Box flex={1} textAlign="center">
                              <Typography color="#737373" fontSize="12px">
                                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Output" : "输出"}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" justifyContent="space-between" gap={2}>
                            <Box flex={1} textAlign="center">
                              <Typography color="#737373" fontSize="12px">
                                {Number((model.token_price_input * 1000000).toFixed(3)).toString()}
                              </Typography>
                            </Box>
                            <Box flex={1} textAlign="center">
                              <Typography color="#737373" fontSize="12px">
                                {Number((model.token_price_output * 1000000).toFixed(3)).toString()}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" justifyContent="space-between" gap={2}>
                            <Box flex={1} textAlign="center">
                              <Typography color="#737373" fontSize="12px">
                                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "$/M token" : "元/M token"}
                              </Typography>
                            </Box>
                            <Box flex={1} textAlign="center">
                              <Typography color="#737373" fontSize="12px">
                                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "$/M token" : "元/M token"}
                              </Typography>
                            </Box>
                          </Box>
                          <Button
                            variant="text"
                            sx={{
                              color: "#7F6CE0",
                              "&:hover": {
                                color: "#4a4a4a",
                                backgroundColor: "transparent",
                              },
                              fontSize: "12px",
                            }}
                            onClick={() => handleOpenModelDetail(model)}
                          >
                            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View Model" : "查看模型"}
                          </Button>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {selectedTab === 1 && (
                  <Grid container spacing={4} justifyContent="center" sx={{ px: 2 }}>
                    {recommendModels.slice(4, 8).map((model, index) => (
                      <Grid item xs={12} sm={6} md={3} key={index}>
                        <Box
                          sx={{
                            border: "1px solid #e0e0e0",
                            borderRadius: "12px",
                            textAlign: "center",
                            p: 2,
                            boxShadow: 1,
                            backgroundColor: "#fff",
                            width: "100%",
                            maxWidth: "260px",
                            height: "auto",
                          }}
                        >
                          <Box
                            component="img"
                            src={model.logo}
                            alt={model.name}
                            sx={{
                              width: "75px",
                              height: "auto",
                              margin: "0 auto 8px",
                            }}
                          />
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            fontSize="16px"
                            color="#000000"
                            sx={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {model.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="#737373"
                            fontSize="14px"
                            mb={2}
                            sx={{
                              wordWrap: "break-word",
                              lineHeight: 1.4,
                              height: "40px",
                              overflow: "hidden",
                            }}
                          >
                            {model.text_summary}
                          </Typography>
                          <Box display="flex" justifyContent="space-between" gap={2}>
                            <Box flex={1} textAlign="center">
                              <Typography color="#737373" fontSize="12px">
                                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Input" : "输入"}
                              </Typography>
                            </Box>
                            <Box flex={1} textAlign="center">
                              <Typography color="#737373" fontSize="12px">
                                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Output" : "输出"}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" justifyContent="space-between" gap={2}>
                            <Box flex={1} textAlign="center">
                              <Typography color="#737373" fontSize="12px">
                                {Number((model.token_price_input * 1000000).toFixed(3)).toString()}
                              </Typography>
                            </Box>
                            <Box flex={1} textAlign="center">
                              <Typography color="#737373" fontSize="12px">
                                {Number((model.token_price_output * 1000000).toFixed(3)).toString()}
                              </Typography>
                            </Box>
                          </Box>
                          <Box display="flex" justifyContent="space-between" gap={2}>
                            <Box flex={1} textAlign="center">
                              <Typography color="#737373" fontSize="12px">
                                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "$/M token" : "元/M token"}
                              </Typography>
                            </Box>
                            <Box flex={1} textAlign="center">
                              <Typography color="#737373" fontSize="12px">
                                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "$/M token" : "元/M token"}
                              </Typography>
                            </Box>
                          </Box>
                          <Button
                            variant="text"
                            sx={{
                              color: "#7F6CE0",
                              "&:hover": {
                                color: "#4a4a4a",
                                backgroundColor: "transparent",
                              },
                              fontSize: "12px",
                            }}
                            onClick={() => handleOpenModelDetail(model)}
                          >
                            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View Model" : "查看模型"}
                          </Button>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                )}
                {selectedTab === 2 && (
                  <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Box
                      component="img"
                      src={images.dashboard_myapps}
                      alt="My Apps"
                      sx={{ maxWidth: "100%", height: "auto" }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              flex="1"
              height="auto"
              width="90%"
              mx="auto"
              mt={3}
              px={5}
              pt={4}
              pb={2}
              sx={{ background: "linear-gradient(to bottom, #FFFFFF, #EDEAFA 90%)" }}
              borderRadius={5}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography fontWeight="regular" color="#999999" fontSize={14}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "LLM Ranking" : "大模型排行榜"}
                </Typography>
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography color="#999999" fontWeight="regular" fontSize={14}>
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Learn more at" : "了解更多AI资讯"}
                  </Typography>
                  <Typography
                    color="#7F6CE0"
                    fontWeight="regular"
                    fontSize={14}
                    sx={{ cursor: "pointer" }}
                    onClick={() => window.open("https://xxstore.cn", "_blank")}
                  >
                    {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "AI Store  ↗" : "AI Store ↗"}
                  </Typography>
                </Box>
              </Box>
              <Divider sx={{ my: 2, borderColor: "#4A4A4A" }} />
              {models.map((model, index) => (
                <Grid container key={index} alignItems="center">
                  <Grid item xs={2} container justifyContent="flex-start">
                    <Box
                      component="img"
                      src={ranks[index]}
                      alt="Ranking"
                      sx={{
                        height: "24px",
                        width: "30px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-start">
                    <Typography color="#999999" fontWeight="regular" fontSize={14}>{model.series}</Typography>
                  </Grid>
                  <Grid item xs={4} container justifyContent="flex-start">
                    <Typography color="#999999" fontWeight="regular" fontSize={14}>{model.name}</Typography>
                  </Grid>
                  <Grid item xs={2} container justifyContent="flex-start">
                    <Button
                      variant="text"
                      size="small"
                      sx={{ color: "#7F6CE0" }}
                      onClick={() => handleOpenModelDetail(model)}
                    >
                      {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View ↗" : "立即查看 ↗"}
                    </Button>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Box>
          <Box width="80%" height="auto" display="flex" flexDirection="column">
            <Box
              display="flex"
              alignItems="center"
              mb={1}
              height="auto"
              sx={{
                cursor: "pointer",
                borderRadius: 2,
                border: "1px solid #ddd",
                fontSize: 22,
                background: "#FFFFFF",
              }}
              p="20px"
              onClick={handleOpenRewardDialog}
            >
              <CampaignIcon sx={{ mr: 1, color: "#7F6CE0" }} />
              <Typography variant="h6" fontSize={14}>
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "：Limited-time deal: Claim a ¥10 reward!" : "：新用户限时激励：10元奖励金可领取"}
              </Typography>
            </Box>
            <Box
              flex="1" 
              height="auto"
              sx={{
                background: "linear-gradient(to bottom, #FFFFFF, #EDEAFA 50%)",
                borderRadius: 5,
                overflowY: "auto",
              }}
              p={2}
            >
              <Box display="flex" alignItems="center" mb={2}>
                <ModeStandbyIcon sx={{ fontSize: 16, color: "#6D6D6D", mr: 1 }} />
                <Typography sx={{ color: "#6D6D6D", fontWeight: 500, fontSize: 16 }}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "What’s hot..." : "大家都在看"}
                </Typography>
              </Box>
              <Typography sx={{ fontSize: 14, mb: 1 }}>
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Which models support internet search?" : "现在，哪些模型支持联网搜索？"}
              </Typography>
              <Divider sx={{ mb: 2 }} />
              <List disablePadding>
                {onlineModels.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingY: 0.5,
                      paddingX: 0,
                    }}
                  >
                    <ListItemText primary={item.name} primaryTypographyProps={{ fontSize: 14 }} />
                    <IconButton
                      sx={{
                        color: "#9C27B0",
                        fontSize: 14,
                      }}
                      onClick={() => handleOpenModelDetail(item)}
                    >
                      ↗
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={error}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={error}>
          <Box
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 480,
              bgcolor: "#fff",
              borderRadius: "12px",
              boxShadow: 24,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "16px", color: "#333" }}
              >
                {infoLabel}
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#ccc",
                  cursor: "pointer",
                }}
              >
                ✕
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "16px",
                flex: 1,
                overflowY: "auto",
                fontSize: "14px",
                color: "#666",
              }}
            >
              {infoText}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "12px 16px",
                borderTop: "1px solid #e0e0e0",
                bgcolor: "#f5f5f5",
              }}
            >
              <Box
                sx={{
                  height: "32px",
                  lineHeight: "32px",
                  padding: "0 16px",
                  backgroundColor: "#f0f0f0",
                  border: "1px solid #d0d0d0",
                  borderRadius: "4px",
                  color: "#666",
                  fontSize: "14px",
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                确认
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <CodeSample
        open={codeSampleOpen}
        onClose={handleCloseCodeSample}
        model={selectedModel}
        onAPI={handleOpenDialog}
        onDetail={handleOpenModelDetail}
      />
      <ModelDetail
        open={modelDetailOpen}
        onClose={handleCloseModelDetail}
        model={selectedModel}
        user={user}
        onTry={handleTry}
        onSample={handleOpenCodeSample}
      />
      <ApiKeyDialog open={apiDialogOpen} onClose={handleCloseDialog} />
      <RewardDialog open={rewardDialogOpen} onClose={handleCloseRewardDialog} />
    </DashboardLayout>
  );
}

export default Dashboard;
