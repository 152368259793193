/**

*/
import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Sidenav from "examples/Sidenav";
import theme from "assets/theme";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav } from "context";
import { Box, GlobalStyles, Typography } from "@mui/material";
import images from "config/imageConfig";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 900);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  if (isMobile) {
    return (
      <>
        <CssBaseline />
        <GlobalStyles
          styles={{
            html: { height: "100%", boxSizing: "border-box" },
            body: { margin: 0, height: "100%" },
            "#root": { height: "100%" },
            "*": { boxSizing: "inherit" },
          }}
        />
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            bgcolor: "#f0f0f0",
          }}
        >
          <Box
            sx={{
              flex: "1 1 14%",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box component="img" src={images.logo_mobile} maxWidth="100%" maxHeight="168px" />
          </Box>
          <Box
            sx={{
              flex: "1 1 86%",
              backgroundColor: "#f0f0f0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box component="img" src={images.mobile_disabled} maxWidth="80%" maxHeight="80%" />
            <Typography variant="h6" sx={{ marginTop: "20px" }}>
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Welcome to the XMaaS Platform!" : "欢迎访问星觉XMaaS平台"}
            </Typography>
            <Typography variant="h6">
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Currently, login is only supported via Chrome on PC." : "目前仅支持通过PC端chrome浏览器登入"}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{ position: "absolute", width: "0", height: "0", overflow: "hidden", zIndex: -1 }}
      >
        <h1>星觉大模型服务平台</h1>
        <h2>模型中心</h2>
        <h3>模型广场</h3>
        <h3>模型体验</h3>
        <h2>应用中心</h2>
        <h3>我的应用</h3>
      </div>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={images.logo_mobile}
            brandName={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "XMaaS" : "星觉"}
            brandInfo={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Model-as-a-Service Platform" : "大模型服务平台"}
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
        </>
      )}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/landing" />} />
      </Routes>
    </ThemeProvider>
  );
}
