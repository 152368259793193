/**

*/
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import {
  Button,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Modal,
  Fade,
  Backdrop,
  IconButton,
  Popover,
} from "@mui/material";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/authentication/components/Footer";
import images from "config/imageConfig";
import axios from "axios";

function BasicLayout({ image, children }) {
  const [models, setModels] = useState([]);
  const getModels = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      let url = `${apiUrl}/model/findByRecommended?recommended=true`;
      let response = await axios.get(url, {});
      if (response.status === 200) {
        setModels(response.data.result);
        console.log(response.data.result);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Error");
          setInfoText("Failed to get the model list");
        } else {
          setInfoLabel("错误");
          setInfoText("获取模型列表出错");
        }
        setError(true);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Error");
        setInfoText("Failed to get the model list");
      } else {
        setInfoLabel("错误");
        setInfoText("获取模型列表出错");
      }
      setError(true);
    }
  };

  const [error, setError] = useState(false);
  const [infoLabel, setInfoLabel] = useState("");
  const [infoText, setInfoText] = useState("");
  const handleClose = () => setError(false);

  useEffect(() => {
    getModels();
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const isPopoverOpen = Boolean(anchorEl);

  return (
    <PageLayout>
      <Box
        display="flex"
        flexDirection="column"
        minHeight="100vh"
        sx={{
          background: "linear-gradient(to bottom, #EDEAFA, #FFFFFF)",
        }}
      >
        <Box height="8%" bgcolor="#ffffff" borderBottom={1} borderColor="#7F6CE0">
          <Box display="flex" alignItems="center" height="100%" px={3}>
            <Box
              component="img"
              src={images.authpage_logo}
              alt="authpage_logo"
              sx={{
                width: "auto",
                height: "70px",
              }}
            />
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          flex="1"
          justifyContent="center"
          alignItems="center"
          px={2}
          py={4}
        >
          <Box
            flex="1"
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{
              textAlign: "center",
              mb: { xs: 4, md: 0 },
              ml: 10,
            }}
          >
            <Box
              component="img"
              src={images.authpage_logoset}
              alt="Brand Logo"
              sx={{
                maxWidth: "706px",
                width: "100%",
                height: "auto",
                mb: 5,
              }}
            />
            <Box
              sx={{
                width: "100%",
                maxWidth: "706px",
                height: "auto",
                margin: "0 auto",
                mb: 5,
              }}
            >
              <Box textAlign="center">
                <Typography variant="caption" sx={{ fontSize: 30, fontWeight: 550, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#737373" : "#737373" }}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Comprehensive Large Model APIs, All in " : "超全大模型API，尽在 "}
                </Typography>
                <Typography variant="caption" component="span" sx={{ fontSize: 30, fontWeight: 550, color: process.env.REACT_APP_LANGUAGE.trim() == "en" ? "#7F6CE0" : "#737373" }}>
                  {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "XMaaS Model Hub" : "星觉模型广场。"}
                </Typography>
              </Box>
            </Box>
            <Box px={2}>
              <Typography
                mb={1}
                color="#999999"
                fontSize={16}
                fontWeight="regular"
                sx={{ textAlign: "left" }}
              >
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Recommended for You" : "为您推荐"}
              </Typography>
              <Grid container spacing={5} justifyContent="center">
                {models.slice(0, 3).map((model, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box
                      sx={{
                        border: "1px solid #e0e0e0",
                        borderRadius: "12px",
                        textAlign: "center",
                        p: 2,
                        boxShadow: 1,
                        backgroundColor: "#fff",
                        width: "100%",
                        maxWidth: "260px",
                        height: "300px",
                      }}
                    >
                      <Box
                        component="img"
                        src={model.logo}
                        alt={model.name}
                        sx={{
                          width: "75px",
                          height: "auto",
                          margin: "0 auto 8px",
                        }}
                      />
                      <Typography
                        fontWeight="bold"
                        fontSize="14px"
                        color="#000000"
                        sx={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {model.name}
                      </Typography>
                      <Typography
                        color="#737373"
                        fontSize="12px"
                        mb={1}
                        sx={{
                          wordWrap: "break-word",
                          lineHeight: 1.4,
                          height: "40px",
                          overflow: "hidden",
                        }}
                      >
                        {model.text_summary}
                      </Typography>
                      <Box display="flex" justifyContent="space-between" gap={2}>
                        <Box flex={1} textAlign="center">
                          <Typography color="#737373" fontSize="12px">
                            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Input" : "输入"}
                          </Typography>
                        </Box>
                        <Box flex={1} textAlign="center">
                          <Typography color="#737373" fontSize="12px">
                            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Output" : "输出"}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between" gap={2}>
                        <Box flex={1} textAlign="center">
                          <Typography color="#737373" fontSize="12px">
                            {Number((model.token_price_input * 1000000).toFixed(3)).toString()}
                          </Typography>
                        </Box>
                        <Box flex={1} textAlign="center">
                          <Typography color="#737373" fontSize="12px">
                            {Number((model.token_price_output * 1000000).toFixed(3)).toString()}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" justifyContent="space-between" gap={2}>
                        <Box flex={1} textAlign="center">
                          <Typography color="#737373" fontSize="12px">
                            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "$/M token" : "元/M token"}
                          </Typography>
                        </Box>
                        <Box flex={1} textAlign="center">
                          <Typography color="#737373" fontSize="12px">
                            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "$/M token" : "元/M token"}
                          </Typography>
                        </Box>
                      </Box>
                      <Button
                        variant="text"
                        sx={{
                          color: "#7F6CE0",
                          "&:hover": {
                            color: "#4a4a4a",
                            backgroundColor: "transparent",
                          },
                          fontSize: "12px",
                        }}
                        onClick={handleButtonClick}
                      >
                        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View Model" : "查看模型"}
                      </Button>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
          <Box
            flex="1"
            display="flex"
            justifyContent="center"
          >
            <Box
              sx={{
                minWidth: "200px",
                maxWidth: "300px",
                height: "auto",
                borderColor: "#EDEAFA",
                backgroundColor: "white",
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPopover-paper": {
            background: "linear-gradient(135deg, #E3F2FD, #FFF)",
            borderRadius: "12px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            padding: "16px",
            animation: "fadeIn 0.3s ease",
          },
        }}
      >
        <Box textAlign="center">
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              color: "#333",
            }}
          >
            {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Please Login" : "请先注册登录"}
          </Typography>
        </Box>
      </Popover>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={error}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={error}>
          <Box
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 480,
              bgcolor: "#fff",
              borderRadius: "12px",
              boxShadow: 24,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "16px", color: "#333" }}
              >
                {infoLabel}
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#ccc",
                  cursor: "pointer",
                }}
              >
                ✕
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "16px",
                flex: 1,
                overflowY: "auto",
                fontSize: "14px",
                color: "#666",
              }}
            >
              {infoText}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "12px 16px",
                borderTop: "1px solid #e0e0e0",
                bgcolor: "#f5f5f5",
              }}
            >
              <Box
                sx={{
                  height: "32px",
                  lineHeight: "32px",
                  padding: "0 16px",
                  backgroundColor: "#f0f0f0",
                  border: "1px solid #d0d0d0",
                  borderRadius: "4px",
                  color: "#666",
                  fontSize: "14px",
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                确认
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </PageLayout>
  );
}

BasicLayout.propTypes = {
  image: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
