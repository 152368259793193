/**

*/

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Box,
  IconButton,
  Typography,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import RefreshIcon from "@mui/icons-material/Refresh";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/Info";
import SendIcon from "@mui/icons-material/Send";
import MicIcon from "@mui/icons-material/Mic";

function NewApi() {
  const navigate = useNavigate();
  const [app, setApp] = useState({});
  const [api, setApi] = useState({});
  const location = useLocation();
  useEffect(() => {
    if (location.state && location.state[0]) {
      setApp(location.state[0]);
    } else {
      navigate("/apps_own");
    }
  }, [location.state]);

  const handleBackAppDetail = () => {
    navigate("/apps_own/app_detail", { state: [app] });
  };

  const [chatText, setChatText] = useState("");
  const [sendAllowed, setSendAllowed] = useState(false);
  const handleChatTextChange = (event) => {
    const value = event.target.value;
    setChatText(value);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      sendChat();
    }
  };
  const sendChat = async () => {
    console.log("Called");
  };

  const [promptText, setPromptText] = useState("");
  const handlePromptTextChange = (event) => {
    const value = event.target.value;
    setPromptText(value);
  };

  const [apiName, setApiName] = useState("新建大模型API");
  const [apiID, setApiID] = useState("201rj091jf120f12f12");
  const [apiModel, setApiModel] = useState("尚未选择模型");
  const [apiStatus, setApiStatus] = useState("草稿");
  const handleStatusChange = (event) => {
    setApiStatus(event.target.value);
  };

  return (
    <DashboardLayout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
          height: "96vh",
        }}
      >
        <Box
          sx={{
            flex: 1,
            borderRadius: "20px 0 0 20px",
            backgroundColor: "#e7ebf0",
            padding: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
            <IconButton onClick={handleBackAppDetail}>
              <ArrowBackIcon sx={{ color: "#7f6ce0" }} />
            </IconButton>
            <Typography sx={{ ml: 1, fontSize: 14 }}>{apiName}</Typography>
            <IconButton sx={{ ml: 1 }}>
              <EditIcon />
            </IconButton>
            <FormControl sx={{ ml: 1 }}>
              <Select
                labelId="status-select-label"
                value={apiStatus}
                onChange={handleStatusChange}
                sx={{ fontSize: 14, padding: 1, bgcolor: "#f3e073" }}
              >
                <MenuItem value={"草稿"}>草稿</MenuItem>
                <MenuItem value={"正常"}>正常</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              sx={{
                ml: 2,
                backgroundColor: "#ffffff",
                color: "#000000",
                float: "right",
                fontSize: 14,
                fontWeight: "regular",
                border: "1px solid",
                borderColor: "#7f6ce0",
              }}
            >
              {apiModel}
            </Button>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <Typography sx={{ ml: 1 }} fontWeight="regular">
              Prompt
            </Typography>
            <IconButton sx={{ ml: 2, color: "#7f6ce0" }}>
              <RefreshIcon />
              <Typography sx={{ ml: 1, fontSize: 13, color: "#7f6ce0" }} fontWeight="regular">
                更新设置
              </Typography>
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              width: "100%",
              overflowY: "auto",
              pr: 2,
            }}
          >
            <TextField
              multiline
              fullWidth
              rows={45}
              placeholder="请输入内容"
              bgcolor="#ffffff"
              value={promptText}
              onChange={handlePromptTextChange}
              autoComplete="off"
              sx={{
                padding: 1,
                "& .MuiOutlinedInput-root": {
                  padding: "0.5rem",
                  borderRadius: "20px",
                  bgcolor: "#ffffff",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "transparent",
                },
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "1px",
            backgroundColor: "#ccc",
            position: "absolute",
            left: "50%",
            top: 0,
            bottom: 0,
          }}
        />
        <Box
          sx={{
            flex: 1,
            borderRadius: "0 20px 20px 0",
            backgroundColor: "#e7ebf0",
            padding: 2,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              padding: "1rem",
              borderRadius: "20px",
              bgcolor: "#ffffff",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 2,
            }}
          >
            <Typography>测试版本</Typography>
            <Box>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#ffffff",
                  color: "#7f6ce0",
                  borderColor: "#7f6ce0",
                  fontWeight: "regular",
                }}
              >
                <RefreshIcon sx={{ mr: 1 }} />
                更新设置
              </Button>
              <Button
                variant="contained"
                sx={{
                  ml: 2,
                  backgroundColor: "#7f6ce0",
                  color: "#ffffff",
                  float: "right",
                  fontWeight: "regular",
                }}
              >
                <SendIcon sx={{ mr: 1 }} />
                发布
              </Button>
            </Box>
          </Box>
          <Box sx={{ flex: "1 1 auto", display: "flex", gap: 2, overflow: "hidden" }}></Box>
          <Box sx={{ padding: "1rem", flexShrink: 0 }}>
            <Box display="flex" alignItems="center" gap={1} sx={{ mb: 1 }}>
              <IconButton
                sx={{
                  borderRadius: 2,
                  border: "2px solid #cecece",
                  padding: "0.5rem",
                  bgcolor: "#ffffff",
                  "&:hover": {
                    bgcolor: "#b0b0b0",
                  },
                }}
              >
                <MicIcon />
              </IconButton>
              <IconButton
                sx={{
                  borderRadius: 2,
                  border: "2px solid #cecece",
                  padding: "0.5rem",
                  bgcolor: "#ffffff",
                  "&:hover": {
                    bgcolor: "#b0b0b0",
                  },
                }}
              >
                <ImageIcon />
              </IconButton>
              <IconButton
                sx={{
                  borderRadius: 2,
                  border: "2px solid #cecece",
                  padding: "0.5rem",
                  bgcolor: "#ffffff",
                  "&:hover": {
                    bgcolor: "#b0b0b0",
                  },
                }}
              >
                <FileOpenIcon />
              </IconButton>
              <Box display="flex" alignItems="center" justifyContent="center" gap={1} ml={1}>
                <InfoIcon sx={{ fontSize: "small" }} />
                <Typography sx={{ fontSize: 14, color: "#858585", mt: 0.5 }}>
                  体验模型将会消耗tokens，费用以实际发生为准
                </Typography>
              </Box>
            </Box>
            <Box display="flex" position="relative" alignItems="center" sx={{ mb: 1 }}>
              <TextField
                variant="outlined"
                placeholder="请输入您的问题"
                multiline
                fullWidth
                value={chatText}
                onChange={handleChatTextChange}
                onKeyDown={handleKeyPress}
                autoComplete="off"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    padding: "0.5rem",
                    paddingRight: "50px",
                    borderRadius: "20px",
                    bgcolor: "#ffffff",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "transparent",
                  },
                }}
              />
              <IconButton
                disabled={!sendAllowed}
                sx={{
                  position: "absolute",
                  right: "1rem",
                  top: "50%",
                  transform: "translateY(-50%)",
                  color: "#ffffff",
                  bgcolor: "#696969",
                  borderRadius: 2,
                  padding: "0.5rem",
                  "&:hover": {
                    bgcolor: "#b0b0b0",
                  },
                }}
                onClick={sendChat}
              >
                <SendIcon />
              </IconButton>
            </Box>
            <Typography textAlign="center" sx={{ fontSize: 14, color: "#858585" }}>
              对话中的所有回答内容均由人工智能模型生成，不代表公司态度或观点
            </Typography>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default NewApi;
