/**

*/
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ApiKeyDialog from "examples/APIDialog";
import CodeSample from "examples/CodeSample";
import { CircularProgress, Button, Box, InputAdornment } from "@mui/material";
import { TextField, List, ListItem, Typography, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import SearchIcon from "@mui/icons-material/Search";
import KeyIcon from "@mui/icons-material/Key";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ModelDetail from "examples/ModelDetail";
import { useMaterialUIController } from "context";

function ModelsView() {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, user, appid } = controller;

  const [apiDialogOpen, setApiDialogOpen] = useState(false);
  const handleOpenDialog = () => {
    setApiDialogOpen(true);
  };
  const handleCloseDialog = () => {
    setApiDialogOpen(false);
  };

  const [selectedModel, setSelectedModel] = useState(null);

  const [codeSampleOpen, setCodeSampleOpen] = useState(false);
  const handleOpenCodeSample = (m) => {
    setSelectedModel(m);
    setCodeSampleOpen(true);
  };
  const handleCloseCodeSample = () => {
    setCodeSampleOpen(false);
  };

  const [modelDetailOpen, setModelDetailOpen] = useState(false);
  const handleOpenModelDetail = (m) => {
    setSelectedModel(m);
    setModelDetailOpen(true);
  };
  const handleCloseModelDetail = () => {
    setModelDetailOpen(false);
  };

  const [activeTab, setActiveTab] = useState("modelFunctions");
  const [searchQuery, setSearchQuery] = useState("");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const handleSearch = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      let result = [];
      models.forEach((model) => {
        let name = model.name;
        if (name.includes(searchQuery)) {
          result.push(model);
        }
      });
      setShowModels(result);
    }
  };
  const modelSet = [];

  let modelFunction = [
    {
      value: "ALL",
      text: "全部模型",
      textEng: "All Models",
    },
    {
      value: "TEXT_TO_TEXT",
      text: "文字生成文字",
      textEng: "Text to Text",
    },
    {
      value: "TEXT_TO_SPEECH",
      text: "文字生成语音",
      textEng: "Text to Speech",
    },
    {
      value: "SPEECH_TO_TEXT",
      text: "语音生成文字",
      textEng: "Speech to Text",
    },
    {
      value: "TEXT_TO_IMAGE",
      text: "文字生成图片",
      textEng: "Text to Image",
    },
    {
      value: "IMAGE_TO_IMAGE",
      text: "图片生成图片",
      textEng: "Image to Image",
    },
  ];

  const [models, setModels] = useState([]);
  const [showModels, setShowModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [infoLabel, setInfoLabel] = useState("");
  const [infoText, setInfoText] = useState("");
  const handleClose = () => setError(false);

  const [filter, setFilter] = useState("ALL");

  if (parseInt(process.env.REACT_APP_DEVELOP) === 0) {
    modelFunction = [
      {
        value: "TEMP_USED",
        text: "文字生成文字",
      },
    ];
  }

  const handleFilterChange = (f) => {
    setFilter(f);
    getModels(f);
  };

  const navigate = useNavigate();
  const handleTry = (m) => {
    if (m.model_type !== "TEXT_TO_TEXT") {
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Model Selection");
        setInfoText("Currently only models that produce text can be selected to produce text. Please select again");
      } else {
        setInfoLabel("模型选择");
        setInfoText("当前只支持选择文字生产文字类型的模型，请重新选择");
      }
      setError(true);
      return;
    }
    if (m.status !== "AVAILABLE") {
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Model Selection");
        setInfoText("The current model is in an unavailable state. Please try another available model");
      } else {
        setInfoLabel("模型选择");
        setInfoText("当前模型处于不可用状态，请尝试其他可用的模型");
      }
      setError(true);
      return;
    }
    navigate("/models_test", { state: [m] });
  };

  const getModels = async (f) => {
    setLoading(true);
    try {
      if (!localStorage.getItem("maasAuthData")) {
        navigate("/authentication/sign-in");
        return;
      }
      const authData = JSON.parse(localStorage.getItem("maasAuthData"));
      let url;
      const apiUrl = process.env.REACT_APP_API_URL;
      if (!f || f === "ALL") {
        url = `${apiUrl}/model/findByShowStatus?show_status=LAUNCH&page=1&limit=9999999&sort=score&order=desc`;
      } else if (f === "TEMP_USED") {
        url = `${apiUrl}/model/findConformanceByType?model_type=TEXT_TO_TEXT&page=1&limit=9999999&sort=score&order=desc`;
      } else {
        url = `${apiUrl}/model/findByTypeAndShowStatus?model_type=${f}&show_status=LAUNCH&page=1&limit=9999999&sort=score&order=desc`;
      }
      let response = await axios.get(url, {
        headers: { Authorization: `${authData.token}` },
        validateStatus: (status) => status < 500,
      });
      if (response.status === 200) {
        setModels(response.data.result);
        setShowModels(response.data.result);
      } else if (response.status === 401) {
        localStorage.clear();
        navigate("/authentication/sign-in");
        return;
      } else {
        if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
          setInfoLabel("Model Loading");
          setInfoText("Error getting model list");
        } else {
          setInfoLabel("模型加载");
          setInfoText("获取模型列表出错");
        }
        setError(true);
      }
    } catch (error) {
      console.log(error);
      if (process.env.REACT_APP_LANGUAGE.trim() == "en") {
        setInfoLabel("Model Loading");
        setInfoText("Error getting model list");
      } else {
        setInfoLabel("模型加载");
        setInfoText("获取模型列表出错");
      }
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      if (parseInt(process.env.REACT_APP_DEVELOP) === 1) {
        getModels(filter);
      } else {
        getModels("TEMP_USED");
      }
    }
  }, [user]);

  return (
    <DashboardLayout>
      <Box height="96vh" p={1}>
        <Box
          sx={{
            bgcolor: "#ffffff",
            borderRadius: "8px",
            overflow: "hidden",
            height: "130px",
          }}
        >
          <Box display="flex" alignItems="center" pt={4}>
            <Typography px={4} component="h6" variant="h4" fontWeight="regular" color={"#7f6ce0"}>
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Model Hub" : "模型广场"}
            </Typography>
            <KeyIcon fontSize="small" pt={4}>
              KeyIcon
            </KeyIcon>
            <Typography
              px={1}
              mt={0.5}
              fontSize={14}
              fontWeight="regular"
              color={"#7f6ce0"}
              sx={{ cursor: "pointer" }}
              onClick={handleOpenDialog}
            >
              {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View my API Keys" : "查看我的API KEY"}
            </Typography>
          </Box>
          <Typography
            pt={1}
            px={4}
            pb={4}
            fontSize={14}
            fontWeight="regular"
            color={"#a0a0a0"}
          >
            {process.env.REACT_APP_LANGUAGE.trim() == "en"
              ? "Model Hub offers over 80 model options, including third-party open-source models and industry-specific models. You can select the appropriate model based on your business needs."
              : "模型广场提供超过80种模型选择，包括第三方开源模型、行业领域模型等，您可以按照业务需求选择合适的模型接入"}
          </Typography>
        </Box>
        <Box mt={2} display="flex" alignItems="flex-start" height="92%">
          <Box
            width="15vw"
            height="100%"
            display="flex"
            flexDirection="column"
            sx={{
              borderRadius: "15px",
              bgcolor: "#ffffff",
              overflow: "hidden",
            }}
          >
            <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Search from all models" : "从全部模型里搜索"}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={handleSearch}
                autoComplete="off"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              <Button
                onClick={() => handleTabChange("modelSeries")}
                sx={{
                  color: activeTab === "modelSeries" ? "#000000" : "#636363",
                  flex: 1,
                  borderRadius: 0,
                  borderBottom: activeTab === "modelSeries" ? "2px solid #000" : "none",
                  fontSize: 14,
                  fontWeight: "regular",
                }}
              >
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Model series" : "模型系列"}
              </Button>
              <Button
                onClick={() => handleTabChange("modelFunctions")}
                sx={{
                  color: activeTab === "modelFunctions" ? "#000000" : "#636363",
                  flex: 1,
                  borderRadius: 0,
                  borderBottom: activeTab === "modelFunctions" ? "2px solid #000" : "none",
                  fontSize: 14,
                  fontWeight: "regular",
                }}
              >
                {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Model feature" : "模型功能"}
              </Button>
            </Box>
            <Box sx={{ overflowY: "auto", flexGrow: 1, p: 2 }}>
              <List>
                {activeTab === "modelSeries"
                  ? modelSet.map((item, index) => (
                      <ListItem key={index}>
                        <Typography>{item.text}</Typography>
                      </ListItem>
                    ))
                  : modelFunction.map((item, index) => (
                      <ListItem key={index}>
                        <Button
                          onClick={() => handleFilterChange(item.value)}
                          sx={{
                            color: filter === item.value ? "#0126ca" : "#000000",
                            fontSize: 12,
                            fontWeight: "regular",
                          }}
                        >
                          {process.env.REACT_APP_LANGUAGE.trim() == "en" ? item.textEng : item.text}
                        </Button>
                      </ListItem>
                    ))}
              </List>
            </Box>
          </Box>
          <Box width="2vw"></Box>
          <Box
            flex={1}
            height="100%"
            overflow="auto"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <CircularProgress sx={{ color: "#7F6CE0" }} />
            ) : (
              <Box
                display="grid"
                gridTemplateColumns="repeat(auto-fill, minmax(45%, 1fr))"
                gap={2}
                alignContent="start"
                width="100%"
                height="100%"
                overflow="auto"
              >
                {showModels.map((object, index) => (
                  <Box
                    key={index}
                    maxWidth="100%"
                    height="auto"
                    position="relative"
                    sx={{
                      borderRadius: "15px",
                      bgcolor: "#ffffff",
                      padding: 2,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      "&:hover": {
                        bgcolor: "#ececec",
                      },
                    }}
                  >
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Box display="flex" alignItems="center">
                        <Box
                          component="img"
                          sx={{
                            height: 40,
                            width: 40,
                            mr: 2,
                          }}
                          src={object.logo}
                        />
                        <Typography
                          component="h6"
                          variant="h5"
                          fontWeight="normal"
                          color={"#000000"}
                          fontSize={16}
                        >
                          {object.name}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Box
                          sx={{
                            width: 15,
                            height: 15,
                            borderRadius: "50%",
                            bgcolor: object.status === "AVAILABLE" ? "#5aff4b" : "#f70000",
                            mr: 1,
                          }}
                        />
                        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? (
                          <Typography component="span" fontSize={12} fontWeight="normal" color="#838383">
                            {object.status === "AVAILABLE" ? "Available" : "Unavailable"}
                          </Typography>
                        ) : (
                          <Typography component="span" fontSize={12} fontWeight="normal" color="#838383">
                            {object.status === "AVAILABLE" ? "可用" : "不可用"}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                    <Box mt={1} mb={2} ml={7}>
                      <Typography
                        component="h6"
                        variant="subtitle2"
                        fontWeight="regular"
                        color={"#838383"}
                        fontSize={12}
                        sx={{
                          wordWrap: "break-word",
                          overflowWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {object.text_summary}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                      <Typography
                        component="h6"
                        variant="subtitle2"
                        fontWeight="regular"
                        color={"#808080"}
                        fontSize={12}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            color: "#7f6ce0",
                          },
                          mr: 4,
                        }}
                        onClick={() => handleOpenModelDetail(object)}
                      >
                        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "View Details" : "查看详情"}
                      </Typography>
                      <Typography
                        component="h6"
                        variant="subtitle2"
                        fontWeight="regular"
                        color={"#808080"}
                        fontSize={12}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            color: "#7f6ce0",
                          },
                          mr: 4,
                        }}
                        onClick={() => handleOpenCodeSample(object)}
                      >
                        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "API Call Example" : "API调用示例"}
                      </Typography>
                      <Typography
                        component="h6"
                        variant="subtitle2"
                        fontWeight="regular"
                        color={"#808080"}
                        fontSize={12}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            color: "#7f6ce0",
                          },
                          mr: 1,
                        }}
                        onClick={() => handleTry(object)}
                      >
                        {process.env.REACT_APP_LANGUAGE.trim() == "en" ? "Try" : "体验"}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={error}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={error}>
          <Box
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 480,
              bgcolor: "#fff",
              borderRadius: "12px",
              boxShadow: 24,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "16px",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", fontSize: "16px", color: "#333" }}
              >
                {infoLabel}
              </Typography>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#ccc",
                  cursor: "pointer",
                }}
              >
                ✕
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "16px",
                flex: 1,
                overflowY: "auto",
                fontSize: "14px",
                color: "#666",
              }}
            >
              {infoText}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "12px 16px",
                borderTop: "1px solid #e0e0e0",
                bgcolor: "#f5f5f5",
              }}
            >
              <Box
                sx={{
                  height: "32px",
                  lineHeight: "32px",
                  padding: "0 16px",
                  backgroundColor: "#f0f0f0",
                  border: "1px solid #d0d0d0",
                  borderRadius: "4px",
                  color: "#666",
                  fontSize: "14px",
                  cursor: "pointer",
                  userSelect: "none",
                }}
              >
                确认
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <ApiKeyDialog open={apiDialogOpen} onClose={handleCloseDialog} />
      <CodeSample
        open={codeSampleOpen}
        onClose={handleCloseCodeSample}
        model={selectedModel}
        onAPI={handleOpenDialog}
        onDetail={handleOpenModelDetail}
      />
      <ModelDetail
        open={modelDetailOpen}
        onClose={handleCloseModelDetail}
        model={selectedModel}
        user={user}
        onTry={handleTry}
        onSample={handleOpenCodeSample}
      />
    </DashboardLayout>
  );
}

export default ModelsView;
